<template>
  <v-dialog
      v-model="dialog"
      max-width="800px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-normal text-capitalize btn-primary bg-gradient-primary py-3 px-6 ms-3"
      >Aggiungi Cassa
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        Aggiungi Cassa
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-card class="mt-3" outlined style="background-color: #ebebeb">
              <v-card-title>Chiusura ore 15:00</v-card-title>
              <v-card-text>
                <v-text-field v-model="cassa.responsabile_mattina" label="Cassiere" color="indigo"></v-text-field>
              </v-card-text>
            </v-card>
            <v-card class="mt-3" outlined style="background-color: #ebebeb">
              <v-card-text>
                <v-card-text>
                  <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="cassa.data"
                          label="Date"
                          hint="DD-MM-YYYY format"
                          persistent-hint
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="cassa.data" no-title @input="menu2 = false"></v-date-picker>
                  </v-menu>
                </v-card-text>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card class="pl-5 pr-5 mt-5 ml-5 mr-5"
                    outlined
                    style="background-color: #ebebeb">
              <v-card-title>Chiusura ore 17:00</v-card-title>
              <v-card-text>
                <v-text-field v-model="cassa.responsabile_sera" dense label="Cassiere" color="indigo"></v-text-field>
                <v-text-field v-model="cassa.cash17" dense label="Cash ore 17" color="indigo"></v-text-field>
                <v-text-field v-model="cassa.carte17" dense label="Carte ore 17" color="indigo"></v-text-field>
                <v-text-field v-model="cassa.spese17" dense label="Spese ore 17" color="indigo"></v-text-field>
                <v-text-field v-model="cassa.chiusura17" dense label="Chiusura ore 17" color="indigo"></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class=""
                    outlined
                    style="background-color: #ebebeb">
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field v-model="cassa.chiusura10" dense label="Chiusura 10%" color="indigo"></v-text-field>
                    <v-text-field v-model="cassa.cash" dense label="Totale Cash" color="indigo"></v-text-field>

                  </v-col>
                  <v-col>
                    <v-text-field v-model="cassa.chiusura22" dense label="Chiusura 22%" color="indigo"></v-text-field>
                    <v-text-field v-model="cassa.chiusurapos" dense label="Totale Carte/Bancomat"
                                  color="indigo"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class=""
                    outlined
                    style="background-color: #ebebeb">
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field v-model="cassa.spesea" dense label="Spese" color="indigo"></v-text-field>
                    <v-text-field v-model="cassa.speseb" dense label="Spese in Attesa di Fatturazione"
                                  color="indigo"></v-text-field>

                  </v-col>
                  <v-col>
                    <v-text-field v-model="cassa.non_riscossi" dense label="Corrispettivi non riscossi"
                                  color="indigo"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class=""
                    outlined
                    style="background-color: #ebebeb">
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field v-model="cassa.percentuale_cibo" dense label="% Cibo" color="indigo"></v-text-field>
                    <v-text-field v-model="cassa.percentuale_bibite" dense label="% Bibite"
                                  color="indigo"></v-text-field>

                  </v-col>
                  <v-col>
                    <v-text-field v-model="cassa.percentuale_vino" dense label="% Vino"
                                  color="indigo"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            dark
            @click="dialog = false"
        >
          Chiudi
        </v-btn>
        <v-btn
            color="pink"
            dark
            @click="aggiungi"
        >
          Aggiungi
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "AddCassa",
  data() {
    return {
      dialog: false,
      cassa: {
        store: this.$store.state.auth.user.store_id,
        responsabile_mattina: 'John',
        responsabile_sera: 'Jane',
        carte17: 0.00,
        cash: 0.00,
        cash17: 0.00,
        chiusura10: 0.00,
        chiusura15: 0.00,
        chiusura17: 0.00,
        chiusura22: 0.00,
        chiusurapos: 0.00,
        data: new Date().toISOString().substr(0, 10),
        percentuale_bibite: 0,
        percentuale_cibo: 0,
        percentuale_vino: 0,
        spese17: 0.00,
        spesea: 0.00,
        speseb: 0.00,
        non_riscossi: 0.00,
      },
      date: new Date().toISOString().substr(0, 10),
      dateFormatted:"",
      menu1: false,
      menu2: false,
    }
  },
   computed: {
    computedDateFormatted () {
      return this.formatDate(this.cassa.data)
    },
  },

  watch: {
    date (val) {
      console.log(val)
      this.dateFormatted = this.formatDate(this.cassa.data)
    },
  },
  methods: {
    aggiungi() {
      this.cassa.data = this.formatDate(this.cassa.data)
      this.$emit('addCassa', this.cassa)
      this.dialog = false
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${year}-${month}-${day}`
    }
  }
}
</script>


<style scoped>

</style>