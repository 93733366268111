import axios from "axios";
import authHeader from "@/services/auth.header";
const API_URL = process.env.VUE_APP_API_URL;


class winemakerService {
    getProduttori(params) {
        return(axios.get(API_URL + `winemaker/`, {
            headers: authHeader(),
            params: params
        }));
    }
    getProduttore(id) {
        return(axios.get(API_URL + `winemaker/` + id + '/', {headers: authHeader()}));
    }
    newProduttore(payload) {
        return axios.post(API_URL + "winemaker/", payload, {headers: authHeader()});
    }
    editProduttore(edited) {
        return(axios.put(API_URL + `winemaker/${edited.id}/`, edited, {headers: authHeader()}));
    }
    deleteProduttore(itemId) {
        return axios.delete(API_URL + `winemaker/${itemId}/`, {headers: authHeader()});
    }
    getWinemakerInfo(value) {
        return axios.get(API_URL + `winemaker/${value}/`, {headers: authHeader()})
    }
    getWinemakersLight() {
        return axios.get(API_URL + 'winemaker/?select=', { headers: authHeader()})
    }
    // ----------------------------------------------------------------------------------------------------------------------------- //
    getDistributori(params) {
        return(axios.get(API_URL + `distributor/`, {
            headers: authHeader(),
            params: params
        }));
    }
    getDistributore(id) {
        return(axios.get(API_URL + `distributor/` + id + '/', {headers: authHeader()}));
    }
    retrieveWinemaker(id) {
        return(axios.get(API_URL + `distributor/${id}/retrieve_winemakers/`, {headers: authHeader()}));
    }
    newDistributore(payload) {
        return axios.post(API_URL + "distributor/", payload, {headers: authHeader()});
    }
    editDistributore(itemId, edited) {
        return(axios.put(API_URL + `distributor/${itemId}/`, edited, {headers: authHeader()}));
    }
    deleteDistributore(itemId) {
        return axios.delete(API_URL + `distributor/${itemId}/`, {headers: authHeader()});
    }
    getDistroInfo(value) {
        return axios.get(API_URL + `distributor/${value}/`, {headers: authHeader()})
    }
    editTipo(id, value) {
        return axios.put(API_URL + `type/${id}/`,value, {headers:authHeader()} )
    }
    async removeWinemakerFromDistributor(params) {
        return await axios.put(API_URL + 'distributor/', params,{
            headers:authHeader()
        } )
    }


}
export default new winemakerService();
