import axios from "axios";
import authHeader from "@/services/auth.header";
const API_URL = process.env.VUE_APP_API_URL;

class ordineService {
  getOrdini(params) {
    return (
      axios
        .get(API_URL + `order/`, {
          headers: authHeader(), params: params
        })
    );
  }
  getOrdiniById(val) {
    return axios.get(API_URL + `order/${val}/`, {
      headers: authHeader(),
    })
  }
  newOrdine(payload) {
    return axios.post(API_URL + "order/", payload, {
      headers: authHeader(),
    });
  }
  editOrdine(itemId, edited) {
    return (
      axios
        .put(API_URL + `order/${itemId}/`, edited, {
          headers: authHeader(),
        })
    );
  }
  editOrderFromOrder(payload) {
    return (
      axios
        .put(API_URL + `order/${payload.id}/`, payload, {
          headers: authHeader(),
        })
    );
  }
  deleteOrdine(itemId) {
    return axios.delete(API_URL + `order/${itemId}/`, {
      headers: authHeader(),
    });
  }
// ----------------------------------------------------------------------------------------------------------------------------- //
  getOrdiniItems(params) {
    return (
      axios
        .get(API_URL + `order_item/`, {
          headers: authHeader(), params: params
        })
    );
  }
  newOrdineItem(payload) {
    return axios.post(API_URL + "order_item/", payload, {
      headers: authHeader(),
    });
  }
  editOrdineItem(itemId, edited) {
    return (
      axios
        .patch(API_URL + `order_item/${itemId}/`, edited, {
          headers: authHeader(),
        })
    );
  }
  deleteOrdineItem(id) {
    return axios.delete(API_URL + `order_item/${id}/`, {
      headers: authHeader(),
    });
  }
// RICERCA---------------------------------------------------------------------------------------------------------------- //
  searchWithParams(params) {
    return axios.get(API_URL + 'pricelist_items/', { headers: authHeader(), params:params})
  }
  by_winemaker_name_or_distributor_name(params) {
    return axios.get(API_URL + 'search_distributor_or_winemaker/', { headers: authHeader(), params:params})
  }
  by_winemaker_distributor_name(params) {
    return axios.get(API_URL + 'search_distributor/', { headers: authHeader(), params:params})
  }
}

export default new ordineService
