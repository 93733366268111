<template>
  <div>
    <v-overlay :value="progress">
      <v-progress-circular indeterminate :size="70" :width="7" color="purple">
      </v-progress-circular>
    </v-overlay>
    <v-card class="card-shadow border-radius-xl" elevation="5">
      <div class="card-header-padding">
        <div class="font-weight-bold text-h4 text-typo mb-0">
          Elenco Vini
          <v-chip class="ma-2" color="green" outlined>
            Totale Vini: {{ conteggioVini }}
          </v-chip>
          <!-- #################### SCHEDA FILTRI #################### -->
          <v-chip
              link
              class="ma-2"
              color="indigo lighten-3"
              outlined
              @click.stop="mostraFiltri"
          >
            Filtra Vini
          </v-chip>
          <v-row v-show="openFiltri">
            <v-col cols="12">
              <v-card color="#E8EAF6" shaped elevation="3">
                <v-card-text>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="">
                        <v-select
                            label="Tipo"
                            v-model="filter.type"
                            :items="tipi"
                            :menu-props="{ bottom: true, offsetY: true }"
                            item-text="name"
                            item-value="name"
                            required
                            small-chips
                            clearable
                            @change="getVini"
                            no-data-text="non ci sono valori"
                            suffix="Seleziona"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="">
                        <v-autocomplete
                            label="Uva"
                            v-model="filter.type_grapes"
                            :items="tipiUva"
                            :menu-props="{ bottom: true, offsetY: true }"
                            item-text="name"
                            item-value="id"
                            flat
                            small-chips
                            clearable
                            suffix="Ricerca"
                            @change="getVini"
                        >
                          <template slot="selection" slot-scope="{ item }">
                            {{ item.name }}
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="">
                        <v-autocomplete
                            v-model="filter.winemaker_name"
                            :items="produttori"
                            :menu-props="{ bottom: true, offsetY: true }"
                            item-text="name"
                            item-value="name"
                            label="Produttori"
                            deletable-chips
                            cache-items
                            small-chips
                            clearable
                            flat
                            suffix="Ricerca"
                        ></v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-autocomplete
                            v-model="filter.country"
                            label="Nazione"
                            :items="country"
                            :menu-props="{ bottom: true, offsetY: true }"
                            item-value="name"
                            item-text="name"
                            deletable-chips
                            color="indigo"
                            small-chips
                            clearable
                            cache-items
                            flat
                            suffix="Ricerca"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-autocomplete
                            v-model="filter.region"
                            label="Regione"
                            :items="region"
                            item-value="name"
                            item-text="name"
                            color="indigo"
                            small-chips
                            clearable
                            cache-items
                            flat
                            suffix="Ricerca"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col coles="6" class="d-flex" style="flex-direction: column">
                        <v-card
                            style="border: 1px solid #fff"
                            outlined
                            shaped
                            flat
                            color="#E8EAF6"
                            class="mb-1 flex-grow-1"
                        >
                          <v-card-title>
                            <span style="color: #6c757d">Stai Filtrando per:</span>
                          </v-card-title>
                          <v-card-text>
                            <span class="mr-3" v-if="filter.type"
                            >tipo:
                              <v-chip dark small color="#B388FF">{{
                                  filter.type
                                }}</v-chip></span
                            >
                            <span class="mr-3" v-if="filter.winemaker_name"
                            >produttore:
                              <v-chip dark small color="#B388FF">{{
                                  filter.winemaker_name
                                }}</v-chip></span
                            >
                            <span class="mr-3" v-if="filter.type_grapes"
                            >Uva:
                              <v-chip dark small color="#B388FF">{{
                                  getNameUva(filter.type_grapes)
                                }}</v-chip></span
                            >
                            <span class="mr-3" v-if="filter.country"
                            >Nazione:
                              <v-chip dark small color="#B388FF">{{
                                  filter.country
                                }}</v-chip></span
                            >
                            <span class="mr-3" v-if="filter.region"
                            >Regione:
                              <v-chip dark small color="#B388FF">{{ filter.region }}</v-chip></span
                            >
                            <span class="mr-3" v-if="filter.esaurito"
                            >vini
                              <v-chip dark small color="#B388FF">{{
                                  filter.esaurito ? "esauriti" : ""
                                }}</v-chip></span
                            >
                            <span class="mr-3" v-if="filter.magnum"
                            >dimensioni
                              <v-chip dark small color="#B388FF">{{
                                  filter.magnum ? "magnum" : ""
                                }}</v-chip></span
                            >
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row></v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <!-- #################### FINE SCHEDA FILTRI #################### -->
        </div>
      </div>
      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="vini"
            :search="search"
            fixed-header
            dense
            loading
            loading-text="Caricamento pagina in corso..."
            :page.sync="page"
            hide-default-footer
            @page-count="pageCount = $event"
            :items-per-page="itemsPerPage"
            mobile-breakpoint="0"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="12" md="5">
                  <v-text-field
                      hide-details
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      flat
                      filled
                      outlined
                      color="pink lighten-3"
                      solo
                      v-model="filter.ricerca"
                      placeholder="Ricerca Vini"
                  >
                    <template slot="prepend-inner">
                      <v-icon color="#adb5bd" size="16" class="material-icons-round mt-n2"
                      >search
                      </v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="5">
                  <v-text-field
                      hide-details
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      flat
                      filled
                      outlined
                      color="pink lighten-3"
                      solo
                      v-model="search"
                      placeholder="Ricerca nella pagina"
                  >
                    <template slot="prepend-inner">
                      <v-icon color="#adb5bd" size="16" class="material-icons-round mt-n2"
                      >search
                      </v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-spacer></v-spacer>
              <v-dialog
                  v-model="dialog"
                  max-width="1020">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="font-weight-normal text-capitalize btn-primary bg-gradient-primary py-3 px-6 ms-3"
                  >Aggiungi Vino
                  </v-btn>
                </template>
                <add-vino
                    @close="dialog = false"
                    @refresh="getVini"
                ></add-vino>
              </v-dialog>

              <!-- ******************* DIALOG WINEMAKER ******************** -->
              <quick-add-winemaker-dialog
                  :visible="showProduttoreDetail"
                  :object-data="produttore"
                  @close="closeDetailsProduttore"
                  @closeDialog="closeDetailsProduttore"
              ></quick-add-winemaker-dialog>
              <!-- ******************* DIALOG DELETE WINE ******************** -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card class="card-shadow card-padding border-radius-xl">
                  <v-card-title class="pt-0 text-h5 text-typo justify-center"
                  >Vuoi eliminare questo Vino?
                  </v-card-title
                  >
                  <v-card-actions class="pb-0">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="closeDelete"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        class="font-weight-normal text-capitalize btn-ls bg-transparent btn-outline-secondary py-3 px-6"
                    >Cancel
                    </v-btn
                    >
                    <v-btn
                        @click="deleteItemConfirm"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        class="font-weight-normal text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
                    >OK
                    </v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.images`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                <v-img
                    v-if="item.images.length > 0"
                    :src="item.images[0].image"
                    class="thumbnail image-hover"
                    v-on="on"
                    v-bind="attrs"
                />
                </template>
          <!-- Immagine ingrandita visualizzata nel tooltip -->
          <v-img v-if="item.images.length > 0" :src="item.images[0].image" max-width="300" max-height="300"></v-img>
        </v-tooltip>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <v-chip
                link
                class="ma-2 font-weight-bold"
                label
                outlined
                text-color="blue-grey darken-1"
                @click="editItem(item)"
            >
              {{ item.name }}
            </v-chip>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            <span class="text-sm font-weight-normal text-body">{{ item.type_name }}</span>
          </template>
          <template v-slot:[`item.vintage`]="{ item }">
            <span class="text-sm font-weight-normal text-body">{{ item.vintage }}</span>
          </template>
          <template v-slot:[`item.type_grapes_name`]="{ item }">
            <v-chip
                v-for="(grape, index) in item.type_grapes_name" :key="index"
                @click:close="removeTypeGrapesName(item, grape.id)"
                x-small
                class="ma-1"
                color="indigo lighten-1"
                label
                text-color="white"
            >
              {{ grape.name }}
            </v-chip>
          </template>
          <template v-slot:[`item.winemaker_name`]="{item}">
            <v-chip
                link
                class="ma-2 font-weight-bold"
                label
                outlined
                text-color="blue-grey darken-1"
                @click="getDetailsProduttore(item)"
            > {{ item.winemaker_name }}
            </v-chip>
          </template>
          <template v-slot:[`item.note`]="{ item }">
            <v-tooltip bottom color="white" max-width="350px">
              <template v-slot:activator="{ on, attrs }">
                <span v-if="item.note.length < 10"></span>
                <span v-else v-bind="attrs" v-on="on" class="text-sm font-weight-normal text-body">
                  {{ item.note.substring(0, 10) + ".." }}
                </span>
              </template>
              <v-alert color="pink lighten-5">{{ item.note }}</v-alert>
            </v-tooltip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title>
                    <v-btn
                        @click="editItem(item)"
                        small
                        elevation="0"
                        :ripple="false"
                        tile
                        color="white"
                    >
                      <v-icon size="16" class="mr-2 material-icons-round">edit</v-icon>
                      Modifica
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
                <!--                <v-list-item>-->
                <!--                  <v-list-item-title>-->
                <!--                    <print-barcode-->
                <!--                        :value="item.id"-->
                <!--                        :name="item.name"-->
                <!--                    ></print-barcode>-->
                <!--                  </v-list-item-title>-->
                <!--                </v-list-item>-->
                <!--                <v-list-item>-->
                <!--                  <v-list-item-title @click="addWineToPriceListItem(item)">-->
                <!--                    <v-btn-->
                <!--                        @click="addWineToPriceListItem(item)"-->
                <!--                        icon-->
                <!--                        elevation="0"-->
                <!--                        :ripple="false"-->
                <!--                        height="28"-->
                <!--                        min-width="36"-->
                <!--                        width="36"-->
                <!--                        color="orange"-->
                <!--                    >-->
                <!--                      <v-icon size="14" class="material-icons-round">library_books</v-icon>-->
                <!--                    </v-btn>-->
                <!--                    Aggiungi a Listino-->
                <!--                  </v-list-item-title>-->
                <!--                </v-list-item>-->
                <v-list-item>
                  <v-list-item-title>
                    <v-btn
                        @click="duplicate(item)"
                        small
                        elevation="0"
                        :ripple="false"
                        tile
                        color="white"
                    >
                      <v-icon size="16" class="text-warning mr-2 material-icons-round">mdi-content-duplicate</v-icon>
                      Duplica
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title @click="deleteItem(item)">
                    <v-btn
                        @click="deleteItem(item)"
                        small
                        elevation="0"
                        :ripple="false"
                        tile
                        color="white"
                    >
                      <v-icon size="16" class="text-danger mr-2 material-icons-round">close</v-icon>
                      Elimina
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
                @input="loadPagina()"
                prev-icon="fa fa-angle-left"
                next-icon="fa fa-angle-right"
                class="pagination"
                color="#D81B60"
                v-model="page"
                :length="pageCount"
                circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-dialog
        v-model="dialogEdit"
        open-delay="2"
        max-width="1020">
      <edit-vino
          v-if="dialogEditItem"
          :item="dialogEditItem"
          @close="dialogEdit = false"
          @refresh="getVini"
      ></edit-vino>
    </v-dialog>
    <quick-add-wine-to-price-list-item
        :open-dialog.sync="openDialogQuickAdd"
        :wine-object="this.wine_object"
    >
    </quick-add-wine-to-price-list-item>
  </div>
</template>
<script>
import wineServices from "@/services/wine.services";
import QuickAddWineToPriceListItem from "@/views/Pages/Vini/components/QuickAddWineToPriceListItem.vue";
import QuickAddWinemakerDialog from "@/components/Dialog/QuickAddWinemakerDialog.vue";

import AddVino from "@/views/Pages/Vini/AddVino.vue";
import EditVino from "@/views/Pages/Vini/EditVino.vue";
import typeAndtypegrapesService from "@/services/typeAndtypegrapes.service";
import winemakerService from "@/services/winemaker.service";

const API_URl = process.env.VUE_APP_API_URL;
export default {
  name: "paginated-tables",
  components: {
    QuickAddWineToPriceListItem,
    QuickAddWinemakerDialog,
    AddVino,
    EditVino
  },
  data() {
    return {
      formTitleWinemaker: "Aggiungi Produttore",
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      dialog: false,
      dialogEdit: false,
      dialogEditItem: {},
      addProduttore: false,
      openDialog: false,
      dialogDelete: false,
      componentKey: 0,
      vini: [],
      tipi: [],
      tipiUva: [],
      produttori: [],
      province: [],
      region: [],
      itemId: "",
      conteggioVini: "",
      loading: false,
      items: [],
      country: [],
      cercaTipo: null,
      cercaProduttore: null,
      cercaDistro: null,
      tempTypeGrapesName: [],
      newTypeGrapesName: [],
      checkbox1: {
        a: true,
        b: false,
      },
      checkbox2: true,
      search: "",
      editedIndex: -1,
      editedItem: {
        id: null,
        name: "",
        version: "",
        type: "",
        gradation: "",
        vinification: "",
        vintage: "",
        soldoutCheck: "",
        magnumCheck: "",
        typegrapes: [],
        type_grapes_name: [],
        winemaker: "",
        note: "",
        barcode: "",
        images: [],
      },
      defaultItem: {
        name: "",
        version: "",
        type: "",
        gradation: "",
        vinification: "",
        vintage: "",
        soldoutCheck: "",
        magnumCheck: "",
        typegrapes: [],
        type_grapes_name: [],
        winemaker: "",
        winemaker_name: "",
        note: "",
        barcode: "",
        images: [],
      },
      headers: [
        {
          text: "Etichetta",
          sortable: false,
          value: "images",
        },
        {
          text: "Nome",
          sortable: false,
          value: "name",
          class: "font-weight-bold"
        },
        {
          text: "Annata",
          sortable: false,
          value: "vintage",
        },
        {
          text: "Tipo",
          value: "type",
        },
        {
          text: "Uva",
          value: "type_grapes_name",
        },
        {
          text: "Produttori",
          value: "winemaker_name",
        },
        {
          text: "Distributore",
          value: "distributor_name",
        },
        {
          text: "Note",
          value: "note",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      openFiltri: false,
      filter: {
        type: "",
        winemaker: "",
        winemaker_name: "",
        country: "",
        province: "",
        region: "",
        magnum: "",
        ricerca: "",
        type_grapes: "",
        type_grapes_dialog: "",
      },
      label: null,
      progress: 0,
      valueUpload: 0,
      messaggio: "",
      api: API_URl,
      showAlertSuccess: false,
      selezionaProduttore: false,
      openDialogQuickAdd: false,
      wine_object: {},
      produttore: {},
      showProduttore: false,
      showProduttoreDetail: false
    };
  },
  mounted() {
    this.getTipi()
    this.getTipiUva()
    this.getVini()
    this.getProduttori()
    this.getCountry()
    this.getRegion()
    this.loadPagina()
  },
  methods: {
    addWineToPriceListItem(item) {
      this.wine_object = item
      this.openDialogQuickAdd = true
    },
    addButton() {
      this.showProduttore = true
    },
    removeTypeGrapesName(item, grapeId) {
      const payload = {
        id: item.id,
        tipe_grapes: grapeId,
      };
      console.log(payload)
    },
    mostraFiltri() {
      return (this.openFiltri = !this.openFiltri);
    },
    forceRerender() {
      this.componentKey += 1;
    },
    loadPagina() {
      this.page + 1;
      this.getVini();
    },
    // GET
    getTipi() {
      typeAndtypegrapesService.getTipi().then((resp) => {
        this.tipi = resp.data.results;
      });
    },
    getTipiUva() {
      typeAndtypegrapesService.getTipiUva().then((resp) => {
        this.tipiUva = resp.data.results;
      });
    },
    getProduttori() {
      const params = {
        select: "",
        page_size: 2000,
      };
      winemakerService.getProduttori(params).then((resp) => {
        this.produttori = resp.data.results;
      });
    },
    getNameUva(id) {
      const filter_uva = this.tipiUva.filter((item) => item.id === id);
      return filter_uva[0].name;
    },
    getVini() {
      this.dialogEditItem = {}
      this.progress = true;
      const params = {
        page: this.page,
        type: this.filter.type,
        winemaker: this.filter.winemaker,
        by_winemaker_name: this.filter.winemaker_name,
        by_country: this.filter.country,
        by_province: this.filter.province,
        by_region: this.filter.region,
        magnum: this.filter.magnum,
        search: this.filter.ricerca,
        by_typegrapes_id: this.filter.type_grapes,
      };
      wineServices.getVini(params).then((resp) => {
        this.vini = resp.data.results;
        this.vini.sort();
        this.conteggioVini = resp.data.count;
        this.pageCount = Math.ceil(resp.data.count / this.itemsPerPage);
        this.progress = false;
      });
    },
    getDetailsProduttore(item) {
      this.produttore = Object.assign(this.produttori.find(items => items.name === item.winemaker_name))
      this.showProduttoreDetail = true
    },
    closeDetailsProduttore(value) {
      this.showProduttoreDetail = value
    },
    getCountry() {
      wineServices.getCountry().then((resp) => {
        this.country = resp.data.results;
      });
    },
    getProvince() {
      wineServices.getProvince().then((resp) => {
        this.province = resp.data;
      });
    },
    getRegion() {
      wineServices.getRegion().then((resp) => {
        this.region = resp.data.results;
      });
    },
    editWinemaker() {
      this.addProduttore = true;
      this.openDialog = true;
    },
    newVino() {
      const payload = {
        name: this.editedItem.name,
        version: this.editedItem.version,
        type: this.editedItem.type,
        gradation: this.editedItem.gradation,
        vinification: this.editedItem.vinification,
        vintage: this.editedItem.vintage,
        magnum: this.editedItem.magnum,
        type_grapes: this.editedItem.type_grapes,
        winemaker: this.editedItem.winemaker,
        distributor: this.editedItem.distributor,
        note: this.editedItem.note,
      };
      wineServices.newVino(payload).then(() => {
        this.$dialog.notify.success('Vino Aggiunto con successo!', {color: 'green'})
        this.dialog = false
        this.getVini()
      }).catch(error => {
        this.$dialog.notify.error('Errore nella creazione del vino', {color: 'red'})
        console.log('creazione vino: ', error)
      }).finally()
    },
    editItem(item) {
      // this.editedIndex = this.vini.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      // ------------------
      // this.itemId = item.id;
      // ------------------
      this.dialogEditItem = item
      this.dialogEdit = true;
    },
    duplicate(item) {
      wineServices.duplicateItem(item.id).then((resp) => {
        console.log(resp.data.results);
        this.getVini()
      })
    },
    deleteItem(item) {
      this.editedIndex = this.vini.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // ------------------
      this.itemId = item.id;
      // -------------------
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      const itemId = this.itemId;
      this.vini.splice(this.editedIndex, 1);
      wineServices.deleteVino(itemId);
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeProduttore() {
      this.openDialog = false;
      this.addProduttore = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeWinemaker() {
      this.addProduttore = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    modify() {
      const edited = {
        id: this.itemId,
        name: this.editedItem.name,
        version: this.editedItem.version,
        type: this.editedItem.type,
        gradation: this.editedItem.gradation,
        vinification: this.editedItem.vinification,
        vintage: this.editedItem.vintage,
        magnum: this.editedItem.magnum,
        type_grapes: this.editedItem.type_grapes,
        winemaker: this.editedItem.winemaker,
        distributor: this.editedItem.distributor,
        note: this.editedItem.note,
      };
      wineServices.editVino(edited).then(() => {
        this.$dialog.message.success("Vino Salvato correttamente", {
          rounded: true,
          position: "top-right",
          color: "green accent-5",
          outlined: false,
          timeout: 1000,
        });
        this.getVini()
        this.dialog = false;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.vini[this.editedIndex], this.editedItem);
        this.modify();
        this.getVini();
      } else {
        this.vini.push(this.editedItem);
        this.newVino();
        this.loadPagina();
        this.dialog = false;
      }
    },
    // IMAGE
    selectImage() {
      if (this.$refs.image.files !== undefined) {
        this.label = this.$refs.image.files.item(0);
      } else {
        console.log('no files')
      }
    },
    uploadImage() {
      this.valueUpload = 0;
      this.performUpload(this.label, (event) => {
        this.valueUpload = Math.round((100 * event.loaded) / event.total);
      })
          .then(() => {
            this.label = null;
            this.$dialog.notify.success('immagine salvata!')
          })
          .catch((error) => {
            this.uploadImage = 0;
            this.label = null;
            console.log(error);
          });
    },
    performUpload(image, onUploadProgress) {
      let formData = new FormData();
      formData.append("image", image);
      formData.append("wine_id", this.itemId);
      return wineServices.uploadImage(formData, onUploadProgress).then(() => {
        this.label = null;
        this.messaggio = "caricata";
        this.showAlertSuccess = true;
        this.$dialog.notify.success('immagine salvata!')
        this.getVini();
      });
    },
    deleteImage(id) {
      wineServices.deleteImage(id).then(() => {
        this.label = null;
        this.editedItem.label = null;
        this.messaggio = "eliminato";
        this.showAlertSuccess = true;
      });
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    // "filter.type_grapes"(value) {
    //   if (value === null) {
    //     return this.getVini();
    //   }
    //   if (value.length > 2) {
    //     return this.getVini();
    //   }
    // },
    // "filter.type_grapes_dialog"(value) {
    //   if (value === null) {
    //     return this.getVini();
    //   }
    //   if (value.length > 2) {
    //     return this.getVini();
    //   }
    // },
    "filter.ricerca"(value) {
      if (value === null) {
        return this.getVini();
      }
      if (value.length > 2) {
        return this.getVini();
      }
    },
    "filter.winemaker_name"(value) {
      console.log(value)
      if (value === null) {
        return this.getVini();
      }
      if (value.length > 2) {
        return this.getVini();
      }
    },
    "filter.country"(value) {
      if (value === null) {
        return this.getVini();
      } else {
        return this.getVini();
      }
    },
    "filter.province"(value) {
      if (value === null) {
        return this.getVini();
      }
      if (value.length > 2) {
        return this.getVini();
      }
    },
    "filter.region"(value) {
      if (value === null) {
        return this.getVini();
      } else {
        return this.getVini();
      }
    },
  },
  // --------------------- PAGINAZIONE
  computed: {
    getNameRegionById() {
      let reg = this.region.filter(item => item.id === this.filter.region)
      return reg[0].name
    },
    formTitle() {
      return this.editedIndex === -1 ? "Aggiungi Vino" : "Modifica Vino";
    },
    pages() {
      return this.pagination.rowsPerPage
          ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
          : 0;
    },

  },
};
</script>
<style scoped>
.dotted-underline {
  text-decoration: underline dotted #cb0c9f; /* Applica la linea sottile a puntini */
}

.thumbnail {
  width: 100px;
  height: 50px;
  object-fit: cover;
}
.image-hover-container {
  overflow: hidden; /* Impedisce all'immagine di "sforare" dal suo contenitore */
}

.image-hover {
  transition: transform 0.5s ease; /* Smussa la transizione */
}

.image-hover-container:hover .image-hover {
  transform: scale(1.9); /* Aumenta la dimensione dell'immagine all'hover */
}
</style>
