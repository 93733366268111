<template>
<v-dialog
v-model="dialog">
  <template v-slot:activator="{ on, attrs }">
    <v-btn
        icon
        elevation="0"
        :ripple="false"
        height="28"
        min-width="36"
        width="36"
        color="#67748e"
        v-bind="attrs"
        v-on="on"
    >
      <v-icon size="14" class="material-icons-round"
      >edit
      </v-icon
      >
    </v-btn>
  </template>
    <v-card>
      <v-container class="px-6 py-6">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <h3>Modifica Rack nel Magazzino:
                  <span style="color: indigo"></span>
                </h3>
                <v-row>
                  <v-col
                      cols="12"
                      md="3"
                  >
                    <v-text-field
                        v-model="nameRack"
                        label="Nome"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      md="3"
                  >
                    <v-text-field
                        v-model.lazy.number="columns"
                        label="Colonne"
                        disabled
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      md="3"
                  >
                    <v-text-field
                        v-model.lazy.number="rows"
                        label="Righe"
                        disabled
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      md="4"
                  >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-left" v-for="(col, k) in columns" :key="k">
                        <span>
                          {{ letters[k] }}
                        </span>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row, i) in rows" :key="i" :id="`r${i}`">
                      <td v-for="(col, k) in columns" :key="k" :id="`d${k}`">
                        <span class="subtitle text-xxs text--lighten-1">{{ letters[k] }}{{ i + 1 }}</span>
<!--                        <v-text-field :label="row[i]" @change="handleInput($event, getCellCode(k, i))"></v-text-field>-->
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn class="float-right" @click="saveTable" color="indigo" dark>Salva</v-btn>
            <v-btn class="float-right mr-2" @click="closeTable" color="pink" dark>Chiudi</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
</v-dialog>
</template>

<script>
import warehouseService from "@/services/warehouse.service";
import { getCellCode } from "@/views/Pages/WareHouse/utils";
export default {
  name: "EditRack",
  props: {
    rackId: Number,
  },
  data() {
    return {
      dialog: false,
      showTable: false,
      nameRack: "",
      dtable: {
        cols: 0,
        rows: 0,
      },
      wareHouse:"",
      columns: 0,
      rows: 0,
      rack: null,
      dati: [],
    }
  },
  watch: {
    rackId(value) {
      this.showTable = true
      warehouseService.getRack(value).then(resp => {
            this.dtable = resp.data
            this.nameRack = resp.data.name,
            this.columns = resp.data.columns,
            this.rows = resp.data.rows,
            this.wareHouse = resp.data.warehouse
      }).catch()

    }
  },
computed: {
  letters() {
    let letters = []
    for(let i = "A".charCodeAt(0); i <= "Z".charCodeAt(0); i++) {letters.push(String.fromCharCode([i]))}
    return letters
  },
  structurePosition() {
    return { warehouse: this.wareHouse.name, rackName: this.nameRack }
  },
  buildStruct() {
    let struct = []
    for(let row =0; row < this.rows; row++) {
      for (let col = 0; col < this.columns; col++) {
        struct.push({
          boxPosition: this.structurePosition,
          cell:this.letters[col] + (row + 1),
          items:[]
        },)
      }
    }
    return struct
  }
},
  methods: {
   handleInput() {},
    saveTable() {
      const params = {
        id: this.rackId,
        name: this.nameRack,
        warehouse: this.wareHouse,
        structure:[]
      }
      warehouseService.editRack(params).then(() => {
        this.$dialog.notify.error('Rack Inserito', {
          rounded: true,
          position: 'top-right',
          color: "green accent-5",
          outlined: false,
        })
        this.$emit('reloadRack')
        this.showTable = false
      }).catch(error => {
        this.$dialog.notify.error(`Errore: ${error.data}`, {
          type: 'danger',
          rounded: true,
          position: 'top-right',
          color: "red accent-3",
          outlined: false,
        })
      })
      this.dialog = false
    },
    closeTable() {
      this.dialog = false
      this.showTable = false
      this.$emit('resetDialogRack')
    },
      getCellCode(row, col) {
        return getCellCode(row, col)
      }
      
    },
}
</script>

<style scoped>
td {
  border: 2px dashed #3bafcd;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

th {
  border: 1px solid #ff9900;
}
</style>

