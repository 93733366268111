<template>
  <v-dialog
      v-model="dialog"
      width="500"
      scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
          class="mr-3"
          outlined
          color="pink"
          v-bind="attrs"
          v-on="on"
      >
        mdi-qrcode-plus
      </v-icon>
    </template>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Print QrCode
      </v-card-title>

      <v-card-text class="mt-4">
        <div id="printSectionRack">
          <div>Rack: {{ rackName }}</div>
          <div class="container">
            <div class="main" v-for="(cell, index) in cells" :key="index">
              <qr-code error-level="L" :size="size" :text="cell.id"></qr-code>
              <div class="right-column" style="color: #1a252f">
                <b>{{ cell.id }}/{{ rackName }}/{{ cell.name }}</b>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            text
            @click=printQrCode
        >
          Print
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import warehouseService from "@/services/warehouse.service";

export default {
  name: "PrintCellBarcode",
  components: {
    // QrcodeVueEsm,
    // VueQrPrint
  },
  props: {
    idRack: Number,
    rackName: String,
    warehouse: String
  },
  data() {
    return {
      dialog: false,
      size: 90,
      cells: [],
      entireRack: [],
      isStartPrint: false,
      options: {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=no',
          'scrollbars=yes'
        ],
        iframe: false,
        styles: ['./htmlToPrintRack.css']
      },
    }
  },
  watch: {
    idRack(value) {
      const params = {
        rack: value
      }
      warehouseService.getCellRack(params).then(
          resp => {
            this.entireRack = resp.data.results
            this.cells = resp.data.results.map(item => ({
              id: item.id.toString(),
              name: item.name
            }))
          }
      ).catch()
    }
  },
  computed: {
    getValueId() {
      return this.idRack.toString()
    }
  },
  methods: {
    printQrCode() {
      this.$htmlToPaper("printSectionRack", this.options)
      this.dialog = false
    },
    onEndPrint() {
      this.isStartPrint = false;
    },
  }
}
</script>

<style scoped>

</style>