<template>
  <div>
    <v-container fluid class="px-6 py-0">
      <v-row class="mt-0">
        <v-col cols="10">
          <price-list @variable="passVariable" @var_a="passVarA" @var_b="passVarB" @priceListId="passId" @passNameListino="passName"></price-list>
        </v-col>
        <v-col cols="2" fluid class="px-2 py-0">
          <statistiche-listini></statistiche-listini>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="12">
          <price-list-items
              :variablePriceList="variable_value"
              :var_a="varA"
              :var_b="varB"
              :idPriceListItem="idPriceList"
              :namePriceList="listinoName"
          ></price-list-items>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import PriceList from "../../Applications/Widgets/DatatablePricelist.vue";
import PriceListItems from "./DatatablePricelistItems.vue";
import StatisticheListini from "./StatsListini.vue";

export default {
  components: {
    PriceList,
    PriceListItems,
    StatisticheListini,
  },
  data() {
    return {
      idPriceList: null,
      variable_value: null,
      listinoName: "",
      varA:null,
      varB:null,
    };
  },
  methods: {
    passName(value) {
      this.listinoName = value
    },
    passId(id) {
      this.idPriceList = id;
    },
    passVariable(value) {
      this.variable_value = value;
    },
    passVarA (value) {
      this.varA = value
    },
    passVarB (value) {
      this.varB = value
    }
  },
};
</script>
