<template>
  <v-dialog
      v-model="dialog"
      max-width="800"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-bolder text-capitalize btn-primary bg-gradient-primary py-3 px-6 ms-3"
          small
      >Aggiungi Magazzino
      </v-btn>
    </template>
      <v-card>
        <v-card-title>
          Aggiungi Magazzino
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    v-model="magazzino.name"
                    label="Nome Magazzino"
                    :rules="rules"
                    color="pink"
                    required
                ></v-text-field>
                <v-checkbox
                    v-model="magazzino.principal"
                    label="Principale"
                    color="pink"
                ></v-checkbox>
              </v-col>

              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    v-model="magazzino.address"
                    label="Indirizzo Magazzino"
                    required
                    color="pink"
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    v-model="magazzino.email"
                    label="Email Magazzino"
                    required
                    color="pink"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mr-2" @click="closeDialog" color="pink" dark>Chiudi</v-btn>
          <v-btn @click="addMagazzino" color="indigo" dark>Salva</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>
<script>
import warehouseService from "@/services/warehouse.service";


export default {
  name: "WareHouse",
  props: {
    showAddWarehouse: Boolean,
    wareHouse: Object
  },
  data() {
    return {
      dati: [],
      dialog: false,
      warehouse: null,
      magazzino: {
        name:'',
        address:'',
        email:'',
        principal:false,
      },
      rules: [
        value => !!value || 'Obbligatorio.',
        value => (value && value.length >= 3) || 'Minimo 3 caratteri',
      ],

    }
  },
  methods: {
    addMagazzino() {
      const payload = {
        ...this.magazzino,
        store: this.$store.state.auth.account.store[0]
      }
      warehouseService.addWarehouse(payload)
          .then(() => {
            this.$dialog.message.success('Magazzino Inserito', {
              rounded: true,
              position: 'top-right',
              color: "green",
              outlined: false,
            })
            this.closeDialog()
          }).catch(() => {})
    },
    closeDialog() {
      this.magazzino = {}
      this.dialog = false
      this.$emit('resetDialog')
    }
  },
};
</script>
<style scoped>
td {
  border: 2px dashed #3bafcd;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
th {
  border: 1px solid #ff9900;
}
</style>
