<template>
<v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
            class="mr-3"
            outlined
            color="indigo"
            v-bind="attrs"
            v-on="on"
        >
          mdi-qrcode
        </v-icon>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Print QrCode del rack
        </v-card-title>

        <v-card-text class="mt-4">
          <div id="printSection">
            <qr-code error-level="L" :size="size" :text="value.id.toString()"></qr-code>
            <span>
              WH:<b>{{ value.warehouse_name }}</b>
            </span><br>
            <span class="text-black">
              RCK:<b>{{ value.name }}</b>
            </span>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click=printQrCode
          >
            Print
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import {toString} from "vue-qr-print"
import warehouseService from "@/services/warehouse.service";
export default {
  name: "PrintBarcode",
  components: {},
  props:['value', 'warehouse', 'rackName'],
  data () {
    return {
      dialog: false,
      size: 90,
      wineId: this.value.id,
      isStartPrint: false,
      rack:{},
      type: null
    }
  },
  mounted() {
    this.getInfoRack()
  },
  computed: {
    getValueId() {
      return toString(this.value)
    }
  },
  watch: {},
  methods: {
    getInfoRack() {
      if (typeof(this.value) === 'object'){
       warehouseService.getRack(this.value.id)
            .then(resp => {
              this.rack = resp.data
            })
      } else {
        warehouseService.getRack(this.value)
            .then(resp => {
              this.rack = resp.data
            })
      }
    },
    printQrCode() {
      this.$htmlToPaper("printSection")
      this.dialog = false
    },
     onEndPrint() {
        this.isStartPrint = false;
      },
  }
}
</script>

<style scoped>

</style>