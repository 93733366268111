<template>
  <v-dialog
      v-model="dialog"
      max-width="800px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-normal text-capitalize btn-primary bg-gradient-primary py-3 px-6 ms-3"
      >Aggiungi Ordine
      </v-btn>
    </template>
    <v-card class="card-shadow border-radius-xl">
      <div class="card-header-padding card-border-bottom">
        <span class="font-weight-bold text-h5 text-typo mb-0">Aggiungi Nuovo Ordine</span>
      </div>
      <v-card
          class="pl-5 pr-5 mt-5 ml-5 mr-5"
          outlined
          style="background-color: #ebebeb"
      >
        <v-row>
          <v-col>
            <v-autocomplete
                v-model="ricerca"
                :search-input.sync="search"
                :items="risultatiRicerca"
                item-value="id"
                :item-text="getName"
                return-object
                label="Ricerca per Produttore e/o Distributore"
                clearable
                no-data-text="Not data found"
                :filter="() => true"
            >
              <template v-slot:append>
                <v-progress-circular
                    v-if="loading"
                    indeterminate
                    size="20"
                    color="primary"
                ></v-progress-circular>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card>
      <v-card-text class="card-padding">
        <v-card
            v-if="ricerca"
            class="pl-5 pr-5 mt-5 ml-5 mr-5"
            outlined
            style="background-color: #ebebeb"
        >
          <v-card-text>
            nome Vino: <b>generale</b>
            <br>
            nome Produttore:
            <b v-if="ricerca.is_direct">
              <span>{{ ricerca.winemaker_name }}</span>
            </b>
            <b v-else>{{ ricerca.distributor.name }}</b><br>
            <span v-if="!ricerca.is_direct">nome Distributore:</span>
            <b v-if="!ricerca.is_direct">{{ ricerca.distributor.name }}</b><br>
            è diretto: {{ ricerca.is_direct }}
          </v-card-text>
        </v-card>
        <v-container class="px-0">
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="composeNewOrder.customId" label="Titolo Ordine Univoco*"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="card-padding d-flex justify-end">
        <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-normal text-capitalize btn-ls btn-outline-secondary bg-transparent py-3 px-6"
            @click="dialog = false"
        >Cancella
        </v-btn>
        <v-btn
            @click="save"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-normal text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
        >Salva
        </v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import OrdineService from "@/services/ordine.service";

export default {
  data() {
    return {
      dialog: false,
      ricerca: null,
      search: null,
      ricercaVino: '',
      loading: false,
      ricercaDistributore: '',
      ricercaProduttore: null,
      risultatiRicerca: [],
      risultatiDistributori: [],
      risultatiProduttori: [],
      composeNewOrder: {
        wineId: null,
        distributorId: null,
        winemakerId: null,
        customId: null,
        order_to: ''
      }
    }
  },
  methods: {
    save() {
      if (this.composeNewOrder.customId === null) {
        this.$dialog.notify.error('Custom Id Obbligatorio', { color: "red" })
      }
      const payload = {
        custom_id: this.composeNewOrder.customId,
        order_to: this.ricerca.name,
        winemaker: this.ricerca.winemaker || null,
        distributor: this.ricerca.distributor ? this.ricerca.distributor.id : null,
        store: this.$store.state.auth.user.store_id,
        status: 'In Progress'
      }
      OrdineService.newOrdine(payload)
          .then(() => {
            this.ricerca = ""
            this.composeNewOrder = {
              wineId: '',
              distributorId: '',
              winemakerId: '',
              customId: ""
            }
            this.$emit('refreshOrder')
            this.$dialog.message.success("Ordine aggiunto correttamente", {
              rounded: true,
              position: "top-right",
              color: "green",
              outlined: false,
              timeout: 1000,
            })
            this.dialog = false
          }).catch(() => {
        this.dialog.message.error('Non sono riuscito a salvare il tuo ordine', {
          rounded: true,
          position: "top-right",
          color: "red",
          outlined: false,
          timeout: 1000,
        })
      });
    },
    getName(item) {
      if (item !== null ){
        let name = item.winemaker_name;
        if (!item.is_direct) {
          name = name + ` (${item.distributor?.name})`
        }
        return name;
      } else {
        return ""; // Restituisci una stringa vuota se l'oggetto è nullo
      }
    }
  },
  watch: {
    search(value) {
      if (this.search !== null && this.search.length >= 3) {
        this.loading = true
        if (value !== null) {
          const params = {
            q: value,
          }
          OrdineService.by_winemaker_name_or_distributor_name(params)
              .then(resp => {
                this.risultatiRicerca = resp.data.results
                this.loading = false
                //this.risultatiRicerca = Array.from(new Set(resp.data.results.map(item => item['winemaker_name'])));

              })
              .catch()
        } else {
          return 'no data'
        }
      }
    },
  }
}
</script>

<style scoped></style>
