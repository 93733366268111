<template>
  <div class="d-inline-flex mb-3" v-if="statistiche.length > 0">
    <v-card class="card-shadow border-radius-xl py-4 mr-2 border-color-custom"
    >
      <v-row no-gutters class="px-4">
        <v-col sm="12">
          <p class="text-sm mb-1 text-capitalize text-body font-weight-bold">
            Bott. Tot. Mag.
          </p>

          <h5 class="text-h5 text-typo font-weight-bolder mb-0">
            {{ statistiche[0].total_bottle_per_warehouse }} btl
          </h5>
        </v-col>

        <v-progress-linear
            color="pink lighten-1"
            :value="statistiche[0].bottiglie"
        >
        </v-progress-linear>
      </v-row>
    </v-card>
    <v-card class="card-shadow border-radius-xl py-4 mr-2 border-color-custom">
      <v-row no-gutters class="px-4">
        <v-col sm="12">
          <p class="text-sm mb-1 text-capitalize text-body font-weight-bold">
            Costo Totale
          </p>

          <h5 class="text-h5 text-typo font-weight-bolder mb-0">
            {{
              statistiche[0].calculate_total_cost_in_warehouse.toLocaleString('it-IT', {
                style: 'currency',
                currency: 'EUR'
              })
            }}
          </h5>
        </v-col>

        <v-progress-linear
            color="cyan lighten-1"
            :value="statistiche[0].valore"
        >
        </v-progress-linear>
      </v-row>
    </v-card>
    <v-card class="card-shadow border-radius-xl py-4 mr-2 border-color-custom"
    >
      <v-row no-gutters class="px-4">
        <v-col sm="12">
          <p class="text-sm mb-1 text-capitalize text-body font-weight-bold">
            Costo Medio
          </p>

          <h5 class="text-h5 text-typo font-weight-bolder mb-0">
            {{ statistiche[0].average_price_cost }} €
          </h5>
        </v-col>

        <v-progress-linear
            color="green lighten-1"
            :value="statistiche[0].valore / statistiche[0].bottiglie"
        >
        </v-progress-linear>
      </v-row>
    </v-card>
    <v-card class="card-shadow border-radius-xl py-4 mr-2 border-color-custom"
    >
      <v-row no-gutters class="px-4">
        <v-col sm="12">
          <p class="text-sm mb-1 text-capitalize text-body font-weight-bold">
            Valore Totale
          </p>

          <h5 class="text-h5 text-typo font-weight-bolder mb-0">
            {{
              statistiche[0].calculate_total_value_in_warehouse.toLocaleString('it-IT', {
                style: 'currency',
                currency: 'EUR'
              })
            }}
          </h5>
        </v-col>

        <v-progress-linear
            color="green lighten-1"
            :value="statistiche[0].valore / statistiche[0].bottiglie"
        >
        </v-progress-linear>
      </v-row>
    </v-card>
    <v-card class="card-shadow border-radius-xl py-4 mr-2 border-color-custom"
    >
      <v-row no-gutters class="px-4">
        <v-col sm="12">
          <p class="text-sm mb-1 text-capitalize text-body font-weight-bold">
            Valore Medio
          </p>

          <h5 class="text-h5 text-typo font-weight-bolder mb-0">
            {{ statistiche[0].average_price_value }} €
          </h5>
        </v-col>

        <v-progress-linear
            color="green lighten-1"
            :value="statistiche[0].valore / statistiche[0].bottiglie"
        >
        </v-progress-linear>
      </v-row>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "StatsCard",
  props: ['stats',],
  data() {
    return {
      statistiche: []
    }
  },
  watch: {
    stats(value) {
      console.log('props: ', value)
      this.statistiche = value
    }
  }
}
</script>

<style scoped>
.border-color-custom {
  border: 2px solid #1d7d76; /* Sostituisci 'blue' con il colore desiderato */
}
</style>