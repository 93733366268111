<template>
  <div>
    <v-overlay :value="progress">
      <v-progress-circular indeterminate :size="70" :width="7" color="purple">
      </v-progress-circular>
    </v-overlay>
    <v-card class="card-shadow border-radius-xl" elevation="5">
      <div class="card-header-padding">
        <div class="font-weight-bold text-h4 text-typo mb-0">
          Elenco Ordini
          <v-chip class="ma-2" color="green" outlined>
            Totale Ordini: {{ conteggioOrdini }}
          </v-chip>
          <v-chip class="ma-2" color="indigo" @click.stop="mostraFiltri" outlined>
            Filtra Ordini
          </v-chip>
          <div class="inline-group" v-if="showFiltri">
            <v-card class="card-shadow border-radius-xl" elevation="5">
              <div class="card-header-padding">
                <div class="font-weight-bold text-h4 text-typo mb-0">
                  <v-switch
                      dense
                      v-model="filtraPer"
                      label="In Progress"
                      color="indigo"
                      value="In Progress"
                      hide-details
                  >
                  </v-switch>
                  <v-switch
                      dense
                      v-model="filtraPer"
                      label="Done"
                      color="green"
                      value="Done"
                      hide-details
                  ></v-switch>
                </div>
              </div>
            </v-card>
          </div>
        </div>
      </div>
      <v-card-text>
        <v-row>
          <v-col cols="9">
            <v-card class="card-shadow border-radius-xl" elevation="0">
              <v-card-text>
                <v-data-table
                    @click:row="rowClick"
                    item-key="id"
                    v-model="selectedItem"
                    :headers="headers"
                    :items="ordini"
                    single-select
                    :search="search"
                    fixed-header
                    dense
                    loading
                    loading-text="Caricamento pagina in corso..."
                    :page.sync="page"
                    hide-default-footer
                    @page-count="pageCount = $event"
                    :items-per-page="itemsPerPage"
                    mobile-breakpoint="0"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-row>
                        <v-col cols="12" md="5">
                          <v-text-field
                              hide-details
                              class="input-style font-size-input text-light-input placeholder-light input-icon"
                              flat
                              filled
                              outlined
                              color="pink lighten-3"
                              solo
                              v-model="filter.ricerca"
                              placeholder="Ricerca Ordini"
                          >
                            <template slot="prepend-inner">
                              <v-icon color="#adb5bd" size="16" class="material-icons-round mt-n2"
                              >search
                              </v-icon
                              >
                            </template>
                          </v-text-field>
                        </v-col>

                        <v-col cols="12" md="5">
                          <v-text-field
                              hide-details
                              class="input-style font-size-input text-light-input placeholder-light input-icon"
                              flat
                              filled
                              outlined
                              color="pink lighten-3"
                              solo
                              v-model="search"
                              placeholder="Ricerca nella pagina"
                          >
                            <template slot="prepend-inner">
                              <v-icon color="#adb5bd" size="16" class="material-icons-round mt-n2"
                              >search
                              </v-icon
                              >
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-spacer></v-spacer>
                      <add-order @refreshOrder="getOrdini"></add-order>
                      <!-- ******************* DIALOG *******************-->

                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card class="card-shadow card-padding border-radius-xl">
                          <v-card-title class="pt-0 text-h5 text-typo justify-center"
                          >Vuoi eliminare questo Ordine?
                          </v-card-title
                          >
                          <v-card-actions class="pb-0">
                            <v-spacer></v-spacer>
                            <v-btn
                                @click="closeDelete"
                                elevation="0"
                                :ripple="false"
                                height="43"
                                class="font-weight-normal text-capitalize btn-ls bg-transparent btn-outline-secondary py-3 px-6"
                            >Cancel
                            </v-btn
                            >

                            <v-btn
                                @click="deleteItemConfirm"
                                elevation="0"
                                :ripple="false"
                                height="43"
                                class="font-weight-normal text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
                            >OK
                            </v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>

                      <!-- ********************* DIALOG ORDINE ACQUISTO ********************* -->
                      <v-dialog v-model="dialog_list" max-width="900px">
                        <v-card class="card-shadow border-radius-xl">
                          <div class="card-header-padding card-border-bottom">
                          <span class="font-weight-bold text-h5 text-typo mb-0">
                            Ordine d'acquisto
                          </span>
                          </div>

                          <div id="printMe" ref="document">
                            <!-- <div> -->

                            <!-- <vue-html2pdf
                                :show-layout="false"
                                :float-layout="true"
                                :enable-download="false"
                                :preview-modal="true"
                                :paginate-elements-by-height="1400"
                                filename="Ordine"
                                :pdf-quality="2"
                                :manual-pagination="false"
                                pdf-format="a4"
                                pdf-orientation="portrait"
                                pdf-content-width="800px"

                                @progress="onProgress($event)"
                                @hasStartedGeneration="hasStartedGeneration()"
                                @hasGenerated="hasGenerated($event)"
                                ref="html2Pdf"
                            >
                                <section slot="pdf-content"> -->

                            <v-card-text class="card-padding">
                              <v-container class="px-0">
                                <v-row justify="space-around">
                                  <v-col cols="6" grey d-flex lighten-5>
                                    <div>
                                      <h3 class="ml-4">Vino Vero</h3>
                                      <p class="body-2 ml-4">
                                        Fondamenta della Misericordia, 2497<br/>
                                        30121 Venezia<br/>
                                        Italy<br/>
                                        VAT:<br/>
                                      </p>
                                    </div>
                                  </v-col>

                                  <v-col cols="6">
                                    <div class="d-flex justify-end mr-4">
                                      <h2 class="font-weight-bold text-h4">PURCHASE ORDER ID:</h2>
                                    </div>

                                    <div class="d-flex justify-end mr-4">
                                    <span class="text-h6">
                                      {{ editedItem.custom_id }}
                                      {{
                                        new Date(editedItem.created_at).toLocaleString(
                                            "en-EN",
                                            {year: "2-digit", month: "2-digit", day: "2-digit",})
                                      }}
                                    </span>
                                    </div>
                                  </v-col>
                                </v-row>

                                <v-row>
                                  <v-col cols="6" grey d-flex lighten-5>
                                    <v-card class="fill-height" outlined>
                                      <div class="card-padding justify-start" id="intestazione">
                                        <h4 class="subtitle-1">VENDOR</h4>
                                      </div>
                                      <v-card-title>{{ to.name }}</v-card-title>
                                      <v-card-text class="body-2 ml-3">
                                        Address: {{ to.address }}<br/>
                                        PostalCode: {{ to.postal_code }} {{ to.city }}<br/>
                                        Country: {{ to.country }}<br/>
                                        Email: {{ to.email }}<br/>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>

                                  <v-col cols="6" grey d-flex lighten-5>
                                    <v-card class="fill-height" outlined>
                                      <div class="card-padding justify-start" id="intestazione">
                                        <h4 class="subtitle-1">SHIP TO</h4>
                                      </div>

                                      <v-card-title>{{ store.store_name }}</v-card-title>
                                      <v-card-text class="body-2 ml-3">
                                        Store: {{ store.address }}<br/>
                                        City: <b>{{ store.city }}</b><br/>
                                        Country: {{ store.country }}<br/>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12">
                                    <v-data-table
                                        :headers="tableHeaders"
                                        :items="ordiniItems"
                                        hide-default-footer
                                    >
                                      <template v-slot:[`item.quantity_in_order`]="{ item }">
                                        <div class="d-flex align-center ms-2">
                                          {{ item.quantity_in_order }}
                                        </div>
                                      </template>
                                      <template v-slot:[`item.total_cost_order`]="{ item }">
                                        <div class="d-flex align-center ms-2">
                                          {{ item.total_cost_order.toFixed(2) }}
                                        </div>
                                      </template>

                                      <template slot="body.append">
                                        <tr class="black--text">
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <th class="title">Totals</th>
                                          <th class="title">
                                            {{ sommaTabella("total_cost_order") }}
                                          </th>
                                        </tr>
                                      </template>
                                    </v-data-table>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <!-- </section>
                            </vue-html2pdf> -->
                          </div>

                          <v-card-actions class="card-padding d-flex justify-end">
                            <v-btn
                                @click="close"
                                elevation="0"
                                :ripple="false"
                                height="43"
                                class="font-weight-normal text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
                            >Esci
                            </v-btn
                            >

                            <v-btn
                                @click="print"
                                elevation="0"
                                :ripple="false"
                                height="43"
                                class="font-weight-normal text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
                            >print
                            </v-btn
                            >

                            <v-btn
                                @click="exportToPDF"
                                elevation="0"
                                :ripple="false"
                                height="43"
                                class="font-weight-normal text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
                            >PDF
                            </v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.custom_id`]="{ item }">
                    <div class="d-flex align-center ms-2">
                    <span class="text-md-body-2 font-weight-bold text-body">
                      {{ item.custom_id }}
                    </span>
                    </div>
                  </template>
                  <template v-slot:[`item.destinatario`]="{ item }">
                    <div class="d-flex align-center ms-2">
                        <span
                            v-if="item.distributor !== null"
                            class="text-md-body-2 font-weight-normal text-body span-limit"
                        >
                          <v-icon color="green" size="18" class="material-icon mt-n1">
                            local_shipping
                          </v-icon>
                          {{ item.distributor_name }}
                        </span>
                      <span v-else class="text-md-body-2 font-weight-normal text-body span-limit">
                          <v-icon color="red" size="18" class="material-icon mt-n1">
                            pedal_bike
                          </v-icon>
                          {{ item.winemaker_name }}
                        </span>
                    </div>
                  </template>
                  <template v-slot:[`item.total_bottle_order`]="{item}">
                    <div class="d-flex align-center ms-2">
                    <span class="text-sm font-weight-normal text-body">
                      {{ item.total_bottle_order }}
                    </span>
                    </div>
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <div class="d-flex align-center ms-2">
                    <span v-if="item.status" class="text-sm font-weight-normal text-body">
                      <v-chip
                          small
                          :color="item.status === 'In Progress' ? 'indigo':
                                  item.status === 'Delivered' ? 'orange':
                                  item.status === 'Done' ? 'green':
                                  item.status === 'Sent' ? 'red-1':'pink'"
                          class="d-flex justify-content-center white--text">
                        {{ item.status }}
                      </v-chip>
                    </span>
                    </div>
                  </template>
                  <template v-slot:[`item.winemaker_name`]="{ item }">
                    <div class="d-flex align-center ms-2">
                    <span class="text-sm font-weight-normal text-body">
                      {{ item.winemaker_name }}
                    </span>
                    </div>
                  </template>
                  <template v-slot:[`item.distributor_name`]="{ item }">
                    <div class="d-flex align-center ms-2">
                    <span class="text-sm font-weight-normal text-body">
                      {{ item.distributor_name }}
                    </span>
                    </div>
                  </template>
                  <template v-slot:[`item.closed`]="{ item }">
                  <span v-if="item.closed">
                    <v-icon size="25" class="material-icons-round mt-n2" style="color: #e22f6e"
                    >check</v-icon
                    >
                  </span>
                  </template>
                  <template v-slot:[`item.scheduled`]="{ item }">
                  <span v-if="item.scheduled">
                    <v-icon size="25" class="material-icons-round mt-n2" style="color: #00bcd4"
                    >check</v-icon
                    >
                  </span>
                  </template>
                  <template v-slot:[`item.issue`]="{ item }">
                  <span v-if="item.issue">
                    <v-icon size="25" class="material-icons-round mt-n2" style="color: #ff9800"
                    >check</v-icon
                    >
                  </span>
                  </template>
                  <template v-slot:[`item.created_at`]="{ item }">
                    <div class="d-flex align-center ms-2">
                    <span class="text-sm font-weight-normal text-body">
                      <span>{{ item.created_at | formatDate }}</span>
                    </span>
                    </div>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-menu style="background-color: white">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>more_vert</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item>
                          <v-btn
                              @click="previewOrder(item)"
                              text
                              :ripple="false"
                          >
                            <v-icon size="14" color="indigo" class="material-icons-round mr-2">list</v-icon>
                            Anteprima Ordine
                          </v-btn>
                        </v-list-item>
                        <v-list-item>
                          <span
                              @click="editItem(item)"
                          >
                            <edit-order :item-order="item"></edit-order>

                          </span>
                        </v-list-item>
                        <v-list-item>
                          <v-btn
                              @click="deleteItem(item)"
                              text
                              :ripple="false"
                          >
                            <v-icon size="14" color="red" class="material-icons-round mr-2">close</v-icon>
                            Elimina
                          </v-btn>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions class="card-padding">
                <v-row>
                  <v-col cols="6" class="ml-auto d-flex justify-end">
                    <v-pagination
                        @input="getPageNumber"
                        prev-icon="fa fa-angle-left"
                        next-icon="fa fa-angle-right"
                        class="pagination"
                        color="#D81B60"
                        v-model="page"
                        :length="pageCount"
                        circle
                    ></v-pagination>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="3">
            <stats-ordini
                :keyOrdine="keyOrdine"
                :visibile="visibile"
                :datiStats="datiStats"
            ></stats-ordini>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import wineServices from "@/services/wine.services";
import {eventBus} from "@/event-bus.js";
import html2pdf from "html2pdf.js";
import listinoService from "@/services/listino.service";
import ordineService from "@/services/ordine.service";
import winemakerService from "@/services/winemaker.service";
import AddOrder from "@/views/Pages/Ordini/AddOrder.vue";
import EditOrder from "@/views/Pages/Ordini/EditOrder.vue";
import StatsOrdini from "@/views/Applications/Widgets/StatsOrdini.vue";

export default {
  name: "paginated-tables",
  components: {
    AddOrder,
    EditOrder,
    StatsOrdini
    // VueHtml2pdf,
  },
  data() {
    return {
      payload: [],
      blob: [],
      page: 1,
      pageCount: 0,
      output: null,
      // -------------------
      items: [],
      selectedItem: [],
      itemsPerPage: 10,
      dialog: false,
      dialog_list: false,
      dialogDelete: false,
      loading: false,
      // -------------------
      store: "",
      ordiniItems: [],
      ordini: [],
      listiniItems: [],
      produttori: [],
      distributori: [],
      conteggioOrdini: "",
      selectedId: "",
      ordineKey: "",
      itemId: "",
      to: {
        id: "",
        name: "",
        address: "",
        postal_code: "",
        city: "",
        country: "",
        email: "",
      },
      // -------------------
      search: "",
      editedIndex: -1,
      editedItem: {
        wine: "",
        address: "",
        bottle_produced: "",
        city: "",
        closed: false,
        commercial_agent: "",
        country: "",
        custom_id: "",
        description: "",
        distribution: "",
        distributor: "",
        distributor_name: "",
        email: "",
        email_to: "",
        facebook: "",
        iban: "",
        issue: false,
        items: [],
        lat: "",
        long: "",
        mobile: "",
        name: "",
        note: "",
        postal_code: "",
        province: "",
        region: "",
        scheduled: false,
        scheduled_date: "",
        store: "",
        telephone: "",
        town: "",
        vat: "",
        vcountry: null,
        vprovince: null,
        vtown: null,
        vzone: null,
        website: "",
        winemaker: "",
        winemaker_name: "",
      },
      defaultItem: {
        wine: "",
        address: "",
        bottle_produced: "",
        city: "",
        closed: false,
        commercial_agent: "",
        country: "",
        custom_id: "",
        description: "",
        distribution: "",
        distributor: "",
        distributor_name: "",
        email: "",
        email_to: "",
        facebook: "",
        iban: "",
        issue: false,
        items: [],
        lat: "",
        long: "",
        mobile: "",
        name: "",
        note: "",
        postal_code: "",
        province: "",
        region: "",
        scheduled: false,
        scheduled_date: "",
        store: "",
        telephone: "",
        town: "",
        vat: "",
        vcountry: null,
        vprovince: null,
        vtown: null,
        vzone: null,
        website: "",
        winemaker: "",
        winemaker_name: "",
      },
      headers: [
        {
          text: "Ordine Id",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "custom_id",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },

        {
          text: "Destinatario",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "destinatario",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },

        {
          text: "Stato",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "status",
          class:
              "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Tot. Bottles",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "total_bottle_order",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        //
        // {
        //   text: "Programmato",
        //   align: "start",
        //   cellClass: "border-bottom",
        //   sortable: false,
        //   value: "scheduled",
        //   class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        // },
        //
        // {
        //   text: "Problema",
        //   align: "start",
        //   cellClass: "border-bottom",
        //   sortable: false,
        //   value: "issue",
        //   class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        // },

        {
          text: "Creato",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "created_at",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },

        {
          text: "Actions",
          value: "actions",
          align: "end",
          cellClass: "border-bottom",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
      ],
      tableHeaders: [
        {
          text: "Wine",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "wine_name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Vintage",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "wine_vintage",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "WineMaker",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "winemaker_name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Bottle Quantity",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "quantity_in_order",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Price €",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "total_cost_order",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
      ],
      progress: false,
      openFiltri: false,
      filter: {
        ricerca: "",
        ricercaOrdineItems: "",
        ricercaWinemaker: "",
        ricercaDistro: "",
        wine: "",
      },
      showFiltri: false,
      filtraPer: null,
      switchMe: false,
      // NewFromMarco
      showItems: false,
      getOrderItemsFromOrderId: '',
      getViniByOrder: [],
      getDistributoreOrProduttore: {},
      // datiStats
      visibile: false,
      keyOrdine: null,
      datiStats: {
        items_total: 0,
        total_bottles_item: 0,
        total_bottles_item_stocked: 0,
        order_total_cost: 0,
      }
    };
  },
  mounted() {
    this.initAll()
  },
  methods: {
    async initAll() {
      await this.getStore();
      await this.getOrdini();
      await this.getListiniItems();
      if (this.selectedItem.length > 0 && this.selectedItem[0]) {
        this.selectedItem[0] = this.ordini.find(item => item.id === this.selectedItem[0].id)
        this.rowClick(this.selectedItem[0])
      }

    },
    mostraFiltri() {
      return this.showFiltri = !this.showFiltri
    },
    clearForm() {
      this.editedItem.distributor_name = [];
      this.editedItem.winemaker = "";
    },
    // generateReport () {
    //         this.$refs.html2Pdf.generatePdf()
    //     },
    exportToPDF() {
      let formData = new FormData();
      const pdf = html2pdf(this.$refs.document, {
        margin: 1,
        filename: "document.pdf",
        image: {type: "jpeg", quality: 0.98},
        html2canvas: {dpi: 192, letterRendering: true},
        jsPDF: {
          unit: "cm",
          format: "A4",
          orientation: "portrait",
          vMargin: 0,
          hMargin: 0,
        },
      })
          .get("pdf")
          .then(() => {
            formData.append("name", pdf.name);
            formData.append("file", pdf.blob);
            // this.payload.attach = formData
            this.payload.attach.push(formData);
            wineServices
                .sendFileViaEmail(this.payload)
                .then(() => {
                })
                .catch((error) => {
                  console.log(error);
                });
          });
    },
    async print() {
      // Pass the element id here
      await this.$htmlToPaper("printMe");
    },
    getPageNumber(val) {
      // eventBus.$emit("pageNumber", val);
      this.page = val;
      this.getOrdini();
    },
    rowClick(item, row) {
      if (row) {
        row.select(true)
        this.keyOrdine = row.index;
        this.ordineKey = row.index;
      }
      this.datiStats = {
        items_total: item.items_total,
        total_bottles_item: item.total_bottles_item,
        total_bottles_item_stocked: item.total_bottles_item_stocked,
        order_total_cost: item.total_cost_order,
        status: item.status
      }
      this.visibile = true
      this.selectedId = item.id;

      if (item.distributor) {
        this.getDistributoreOrProduttore = {
          distributor: item.distributor
        }
        this.$emit('showDataOrderItems')
        this.$emit('getDistributoreOrProduttore', this.getDistributoreOrProduttore)
        this.$emit('passOrderName', item.custom_id)
      } else {
        this.getDistributoreOrProduttore = {
          winemaker: item.winemaker
        }
        this.$emit('showDataOrderItems')
        this.$emit('getDistributoreOrProduttore', this.getDistributoreOrProduttore)
        this.$emit('passOrderName', item.custom_id)
      }
    },
    sommaTabella(key) {
      return parseFloat(this.ordiniItems.reduce((a, b) => a + (b[key] || 0), 0)).toFixed(
          2
      );
    },
    getStore() {
      const store = this.$store.state.auth.account.store[0];
      return wineServices.getStore(store).then((resp) => {
        this.store = resp.data;
      });
    },
    getOrdiniItems(id) {
      this.progress = true;
      const params = {
        // page: this.page,
        order: id,
        search: this.filter.ricerca,
      };
      ordineService.getOrdiniItems(params).then((resp) => {
        this.ordiniItems = resp.data.results
        this.ordiniItems.sort();
        this.conteggioOrdiniItems = resp.data.count;
        this.pageCount = Math.ceil(resp.data.count / this.itemsPerPage);
        this.progress = false;
      });
    },
    // ORDINI__________________________________________
    getOrdini() {
      this.progress = true
      const params = {
        page: this.page,
        status: this.filtraPer,
        search: this.filter.ricerca,
      };
      return ordineService.getOrdini(params).then((resp) => {
        this.ordini = resp.data.results;
        this.ordini.sort();
        this.conteggioOrdini = resp.data.count;
        this.pageCount = Math.ceil(resp.data.count / this.itemsPerPage);
        this.progress = false;
      });
    },
    searchOrdini(page) {
      this.progress = true;
      const params = {
        page: page,
        // type: this.filter.type,
        search: this.filter.ricerca,
      };
      ordineService.getOrdini(params).then((resp) => {
        this.ordini = resp.data.results;
        this.ordini.sort();
        this.conteggioOrdini = resp.data.count;
        this.pageCount = Math.ceil(resp.data.count / this.itemsPerPage);
        this.progress = false;
      });
    },
    // LISTINI_________________________________________
    getListiniItems(val) {
      const params = {
        distributor: val,
        distributor_name: this.filter.ricercaDistro,
        winemaker_name: this.filter.ricercaWinemaker,
        by_wine_id: this.editedItem.wine,
      };
      return listinoService.getListiniItems(params).then((resp) => {
        this.listiniItems = resp.data.results;
        this.listiniItems.sort();
      });
    },
    getListiniItemsById(id) {
      listinoService.getListiniItemsById(id).then((resp) => {
        if (resp.data.distributor_name != null) {
          this.editedItem.distributor_name = resp.data.distributor_name;
          this.$refs.idordine.focus();
          // this.$refs.vino.reset();
        } else {
          this.editedItem.winemaker = resp.data.winemaker;
          this.$refs.idordine.focus();
        }
      });
    },
    // DISTRIBUTORI______________________________________
    getDistributori(value) {
      if (value != null) {
        winemakerService.getDistroInfo(value).then((resp) => {
          // INTESTAZIONE DISTRIBUTORE
          this.to.id = resp.data?.id;
          this.to.name = resp.data?.name;
          this.to.address = resp.data?.address;
          this.to.postal_code = resp.data?.postal_code;
          this.to.city = resp.data?.city;
          this.to.country = resp.data?.country;
          this.to.email = resp.data?.email;
        });
      }
    },
    // PRODUTTORI_______________________________________
    getProduttori(value) {
      if (value != null) {
        winemakerService.getWinemakerInfo(value).then((resp) => {
          // INTESTAZIONE PRODUTTORI
          this.to.id = resp.data?.id;
          this.to.name = resp.data?.name;
          this.to.address = resp.data?.address;
          this.to.postal_code = resp.data?.postal_code;
          this.to.city = resp.data?.city;
          this.to.country = resp.data?.country;
          this.to.email = resp.data?.email;
        });
      }
    },
    newOrdine() {
      const payload = {
        custom_id: this.editedItem.custom_id,
        store: this.$store.state.auth.account.store[0],
        closed: this.editedItem.closed,
        scheduled: this.editedItem.scheduled,
        issue: this.editedItem.issue,
        winemaker: this.editedItem.winemaker,
        distributor: this.editedItem.distributor,
        email_to: "test@test.com", //this.to.email
      };
      ordineService.newOrdine(payload).then(() => {
        this.$dialog.message.success("Ordine aggiunto correttamente", {
          type: "success",
          rounded: true,
          position: "top-right",
          color: "green accent-5",
          outlined: true,
          timeout: 1000,
        });
        this.getOrdini();
      });
      this.dialog = false;
    },
    previewOrder(item) {
      this.editedIndex = this.ordini.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // this.itemId = item.id;
      this.getDistributori(item.distributor);
      this.getProduttori(item.winemaker);
      this.getOrdiniItems(item.id);
      this.dialog_list = true;
      this.payload = {
        id_order: item.id,
        email_to: "info@email.com", //item.email_to,
        attach: [],
      };
    },
    editItem(item) {
      this.editedIndex = this.ordini.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.itemId = item.id;
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.ordini.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.itemId = item.id;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      const itemId = this.itemId;
      this.ordini.splice(this.editedIndex, 1);
      ordineService.deleteOrdine(itemId).then(() => {
        this.$dialog.message.success("Ordine cancellato", {
          type: "success",
          rounded: true,
          position: "top-right",
          color: "green accent-5",
          outlined: true,
          timeout: 1000,
        });
        this.ordineKey = 0;
        this.conteggioOrdini = null;
        eventBus.$emit("delOrdine");
        this.closeDelete();
      });
    },
    close() {
      this.dialog = false;
      this.dialog_list = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    modify() {
      const itemId = this.itemId;
      const edited = {
        custom_id: this.editedItem.custom_id,
        store: this.$store.state.auth.user.store[0],
        closed: this.editedItem.closed,
        scheduled: this.editedItem.scheduled,
        issue: this.editedItem.issue,
        winemaker: this.editedItem.winemaker,
        distributor: this.editedItem.distributor_name,
      };
      ordineService.editOrdine(itemId, edited).then(() => {
        this.$dialog.message.success("Modifica effettuata", {
          type: "success",
          rounded: true,
          position: "top-right",
          color: "green accent-5",
          outlined: true,
          timeout: 1000,
        });
        this.getOrdini();
      });
      this.dialog = false;
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.ordini[this.editedIndex], this.editedItem);
        this.modify();
        this.getOrdini();
      } else {
        this.ordini.push(this.editedItem);
        this.newOrdine();
        this.getPageNumber();
        this.dialog = false;
      }
    },
    getVini() {
      if (this.getOrderItemsFromOrderId.distributor) {
        const params = {
          distributor: this.getOrderItemsFromOrderId.distributor
        }
        listinoService.getListiniItemsLight(params).then(resp => {
          this.getViniByOrder = resp.data.results
        }).catch().finally()

      } else {
        const params = {
          winemaker: this.getOrderItemsFromOrderId.winemaker
        }
        listinoService.getListiniItemsLight(params).then(resp => {
          this.getViniByOrder = resp.data.results
        }).catch().finally()
      }
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    "filter.ricerca"(value) {
      if (value.length > 1) {
        return this.searchOrdini();
      }
      if (value.length === 0) {
        const page = 1;
        return this.searchOrdini(page);
      }
    },
    "filter.ricercaOrdineItems"(value) {
      if (value.length > 2) {
        return this.getOrdini();
      }
      if (value.length === 0) {
        return this.getOrdini();
      }
    },
    "filter.ricercaWinemaker"(value) {
      if (value?.length > 2) {
        return this.getListiniItems();
      }
      if (value?.length === 0) {
        return this.getListiniItems();
      }
    },
    "filter.ricercaDistro"(value) {
      if (value?.length > 2) {
        return this.getListiniItems();
      }
      if (value?.length === 0) {
        return this.getListiniItems();
      }
    },
    "filter.wine"(value) {
      if (value?.length > 2) {
        return this.getListiniItems();
      }
      if (value?.length === 0) {
        return this.getListiniItems();
      }
    },
    "filtraPer"(value) {
      const params = {
        status: value
      }
      ordineService.getOrdini(params)
          .then(resp => {
            this.ordini = resp.data.results;
            this.ordini.sort();
            this.conteggioOrdini = resp.data.count;
            this.pageCount = Math.ceil(resp.data.count / this.itemsPerPage);
          })
    },
    // TRIGGER ORDINE ID -> ORDINE ITEMS
    selectedItem(val) {
      this.$emit('getOrderItemsFromOrderId', val[0].id)
      this.getOrderItemsFromOrderId = val[0].id
    },
    // conteggioOrdini(newValue, oldValue) {
    //   if (newValue != oldValue) {
    //     this.getOrdini();
    //   }
    // },
    "editedItem.wine"(newValue) {
      if (newValue) {
        this.getListiniItemsById(newValue);
      }
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Aggiungi Ordine" : "Modifica Ordine";
    },
    pages() {
      return this.pagination.rowsPerPage
          ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
          : 0;
    },
  },
};
</script>

<style scoped>
#intestazione {
  background-color: rgb(187, 187, 187);
}

.span-limit {
  max-width: 150px; /* Imposta la larghezza massima del testo */
  overflow: hidden; /* Nasconde il testo che supera la larghezza massima */
  text-overflow: ellipsis; /* Aggiunge i puntini di sospensione per indicare che il testo è troncato */
  white-space: nowrap; /* Impedisce al testo di andare a capo */
}
</style>