<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate :size="70" :width="7" color="purple">
      </v-progress-circular>
    </v-overlay>
    <v-card class="">
      <v-card-text>
        {{ externalSearchParam }}
        <v-data-table
            :items="warehouseItems"
            :headers="headersWarehouseItem"
            :loading="loading"
            hide-default-footer
            loading-text="Retrieving data from server..."
            :items-per-page="itemsPerPage"
        >
          <template v-slot:top>
            <v-toolbar
                flat
            >
              <v-toolbar-title class="font-weight-bold text-h4 text-typo mb-0">
                <div v-if="$store.state.auth.account">
                  Cell Rack: <span class="text-decoration-underline text-pink">{{ rackName }}</span>
                </div>
              </v-toolbar-title>
              <!--            <card-stats-->
              <!--                v-if="rackItem.valore_totale.total_sell['total_sell']"-->
              <!--                class="ml-2"-->
              <!--                :message="`Valore ${nameRack}`"-->
              <!--                :value-numeric="rackItem.valore_totale.total_sell['total_sell'].toFixed(2)"-->
              <!--                extension="€"-->
              <!--                :progress="600"-->
              <!--            ></card-stats>-->
              <!--            <card-stats-->
              <!--                class="ml-2"-->
              <!--                :message="`Totale Bottiglie Rack`"-->
              <!--                :value-numeric="rackItem.valore_totale.total_bottle['total_bottle']"-->
              <!--                extension=" Bottiglie"-->
              <!--                :progress="120"-->
              <!--            ></card-stats>-->
              <!--            <card-stats-->
              <!--                class="ml-2"-->
              <!--                :message="`Bianchi`"-->
              <!--                :value-numeric="0"-->
              <!--                extension=" Bottiglie"-->
              <!--                :progress="345"-->
              <!--            ></card-stats>-->
              <!--            <card-stats-->
              <!--                class="ml-2"-->
              <!--                :message="`Bollicine`"-->
              <!--                :value-numeric="0"-->
              <!--                extension=" Bottiglie"-->
              <!--                :progress="120"-->
              <!--            ></card-stats>-->
              <v-divider
                  class="mx-4"
                  inset
                  vertical
              ></v-divider>
              <h1 class="text-pink mr-2">{{ info.total_type }}</h1>
              <h1 class="font-weight-bold text-typo">Tipi di Vini</h1>
              <v-divider
                  class="mx-4"
                  inset
                  vertical
              ></v-divider>
              <h1 v-if="warehouseItems[0]" class="text-pink mr-2">{{ info.total_bottles }}</h1>
              <h1 v-if="warehouseItems[0]" class="font-weight-bold text-typo">Bottiglie</h1>
              <v-spacer></v-spacer>
              <add-wine-to-cell></add-wine-to-cell>
            </v-toolbar>
          </template>
          <template v-slot:[`header.wine_name`]="{}">
            <v-text-field
                color="pink"
                v-model="localWineSearch"
                label="Wine"
                hide-details="auto"
                append-icon="mdi-magnify"
            ></v-text-field>
          </template>
          <template v-slot:[`item.winemaker`]="{ item }">
            <div @click="copyToClipboard(item.winemaker)" class="dotted-underline">{{ item.winemaker }}</div>
          </template>
          <template v-slot:[`header.cell_name`]="{}">
            <v-text-field
                color="pink"
                v-model="search_by_cell"
                label="Cell"
                hide-details="auto"
                append-icon="mdi-magnify"
                style="max-width: 80px"
            ></v-text-field>
          </template>
          <template v-slot:[`item.quantity`]="{ item }">
            <v-text-field
                color="pink"
                v-model="editedWarehouseItem.quantity"
                :hide-details="true"
                single-line
                dense
                :autofocus="true"
                v-if="item.id === editedWarehouseItem.id"
            ></v-text-field>
            <span v-else>{{ item.quantity }} su</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div v-if="item.id === editedWarehouseItem.id">
              <v-icon color="red" @click="closeEditQuantityTableWarehouseItem">
                close_fullscreen
              </v-icon>
              <v-icon color="green" @click="saveQuantityTableWarehouseItem(item)">
                done
              </v-icon>
            </div>
            <div v-else>
              <v-icon class="mr-2" @click="editQuantityTableWarehouseItem(item)">
                edit
              </v-icon>
              <move-wine :warehouse-item="item" @refresh="openShowRack"></move-wine>
              <print-barcode-wine-cell-rack
                  :value="item"
              ></print-barcode-wine-cell-rack>
              <delete-button :item="item" @confirm-delete="deleteItem"></delete-button>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
                @input="getPageNumber"
                prev-icon="fa fa-angle-left"
                next-icon="fa fa-angle-right"
                class="pagination"
                color="#D81B60"
                v-model="page"
                :length="pageCount"
                circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import warehouseService from "@/services/warehouse.service";
import AddWineToCell from "@/views/Pages/WareHouse/components/AddWineToCell.vue";
import MoveWine from "@/views/Pages/WareHouse/components/MoveWine.vue";
import PrintBarcodeWineCellRack from "@/views/Pages/WareHouse/components/PrintBarcodeWineCellRack.vue";
import deleteButton from "@/views/Components/button/deleteButton.vue";
import WarehouseService from "@/services/warehouse.service";

export default {
  name: "CellRackTable",
  components: {
    AddWineToCell,
    MoveWine,
    PrintBarcodeWineCellRack,
    deleteButton
  },
  props: {
    rackId: {
      type: [String, Number],
      required: true
    },
    rackName: {
      type: String,
      required: true
    },
    externalSearchParam: String,
    wineSearch: String,
    dataRack: Array,
    info: Object
  },
  data() {
    return {
      fab: '',
      editedIndex: -1,
      editedWarehouseItem: {
        id: 0,
        quantity: ''
      },
      defaultItem: {
        id: 0,
        quantity: ""
      },
      refreshRack: false,
      warehouseItems: [],
      warehouseItemCount: 0,
      headersWarehouseItem: [
        {text: 'Wine', value: 'wine_name', sortable: true},
        {text: 'Vintage', value: 'wine_vintage', sortable: true},
        {text: 'WineMaker', value: 'winemaker', sortable: true},
        {text: 'Warehouse', value: 'warehouse', sortable: false},
        {text: 'Rack', value: 'rack', sortable: false},
        {text: 'Cells', value: 'cell_name', sortable: true},
        {text: 'Quantity', value: 'quantity', sortable: true},
        {text: 'Tot. Bottle', value: 'total_bottle_in_warehouse', sortable: false},
        {text: "Actions", value: "actions"},
      ],
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      loading: false,
      params: {},
      localWineSearch: '',
      cells: [],
      search_by_cell: "",
      showEdit: false,
      type_wine: 0,
      total_bottle: 0
    }
  },
  mounted() {
    this.loading = true
  },
  watch: {
    rackId: {
      handler(value) {
        if (value !== null) {
          this.openShowRack()
          // errore richiama più volte il rack, controllalo e sistema
        } else {
          return ''
        }
      },
      immediate: true,
    },
    info: {
      handler(value) {
        console.log(value)
        this.type_wine = value.type_of_wine
        this.total_bottle = value.total_bottle
      },
      immediate: true
    },
    search_by_cell(value) {
      if (value.length >= 2) {
        this.loading = true
        const cell_name = this.warehouseItems.find(item => item.cell_name === value.toUpperCase())
        if (cell_name) {
          const params = {
            by_cell: cell_name.cell_id,
            store: this.$store.state.auth.user.store_id,
            page: this.page,
          }
          WarehouseService.getWarehouseItems(params).then(resp => {
            this.warehouseItems = resp.data.results
            this.pageCount = Math.ceil(resp.data.count / this.itemsPerPage)
          }).catch(error => {
            console.log(error)
          }).finally(() => {
            this.loading = false
          })
        }
      } else if (value.length < 2) {
        this.getWarehouseItem()
      }
    },
    wineSearch(value) {
      this.localWineSearch = value
      this.fetchWarehouseItem();
    },
    async localWineSearch(value) {
      this.loading = true
      const params = {
        store: this.$store.state.auth.user.store_id,
        search: value,
        page: 1,
      }
      if (this.rackId !== null && this.rackName) {
        params.rack = this.rackId
      }
      try {
        const resp = await warehouseService.getWarehouseItems(params)
        this.warehouseItems = resp.data.results
        this.pageCount = Math.ceil(resp.data.count / this.itemsPerPage)
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
  },
  methods: {
    getWarehouseItem() {
      this.fetchWarehouseItem()
    },
    deleteItem(item) {
      warehouseService.removeBottleFromRackCell(item)
          .then(resp => {
            console.log(resp.data)
          }).catch().finally()
    },
    async retrieveWarehouseItem() {
      const params = {
        rack: this.rackId
      }
      const resp = await warehouseService.getCellRack(params)
      this.warehouseItems = resp.data.results
      this.warehouseItemCount = resp.data.count
      await this.$dialog.notify.success('Wine removed from the warehouse.')
    },
    openShowRack() {
      this.fetchWarehouseItem().then(() => {
        // this.$emit('refresh')
      })
    },
    async fetchWarehouseItem() {
      this.loading = true
      const params = {
        store: this.$store.state.auth.user.store_id,
        search: this.wineSearch,
        page: this.page,
      }
      if (this.rackId !== null && this.rackName) {
        params.rack = this.rackId
      }
      try {
        const resp = await warehouseService.getWarehouseItems(params)
        this.warehouseItems = resp.data.results
        this.pageCount = Math.ceil(resp.data.count / this.itemsPerPage)
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    // Pagination
    getPageNumber(val) {
      this.page = val;
      this.openShowRack()
    },
    // WarehouseItem
    saveQuantityTableWarehouseItem() {
      if (this.editedIndex > -1) {
        let params = {
          id: this.editedWarehouseItem.id,
          quantity: this.editedWarehouseItem.quantity
        }
        warehouseService.quickEditWarehouseItemQuantity(params)
            .then(() => {
              this.$dialog.message.success("Vino modificato correttamente", {
                rounded: true,
                position: "top-right",
                color: "green",
                outlined: false,
                timeout: 1000,
              })
              this.fetchWarehouseItem()
            })
            .catch()
      }
      this.closeEditQuantityTableWarehouseItem()
      this.getWarehouseItem()
    },
    editQuantityTableWarehouseItem(item) {
      this.editedIndex = this.warehouseItems.indexOf(item);
      this.editedWarehouseItem = Object.assign({}, item);
    },
    closeEditQuantityTableWarehouseItem() {
      this.editedWarehouseItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
    },
    async copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);
        await this.$dialog.message.success("Testo copiato negli appunti", {
              type: "success",
              rounded: true,
              position: "top-right",
              color: "green",
              outlined: false,
              timeout: 1000,
            }
        );
      } catch (err) {
        await this.$dialog.message.error("Impossibile copiare il testo negli appunti", {
          type: "success",
          rounded: true,
          position: "top-right",
          color: "green",
          outlined: false,
          timeout: 1000,
        })
      }
    }
  }

}
</script>

<style scoped>
.dotted-underline {
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-color: gray;
}
</style>