<template>
    <div>
      <v-overlay :value="progress">
        <v-progress-circular
          indeterminate
          :size="70"
          :width="7"
          color="purple"
        >
        </v-progress-circular>
      </v-overlay>

      <v-card class="card-shadow border-radius-xl" elevation="5" v-if="tipoName">
        <div class="card-header-padding">
          <div class="font-weight-bold text-h4 text-typo mb-0">
            {{ tipoName }}
            <v-chip
              class="ma-2"
              color="green"
              outlined
            >
              Totale Vini: {{ conteggioVini }}
            </v-chip>

            <!-- #################### SCHEDA FILTRI #################### -->


            <!-- #################### FINE SCHEDA FILTRI #################### -->
          </div>
        </div>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="vini"
            :search="search"
            fixed-header
            dense
            loading
            loading-text="Caricamento pagina in corso..."
            :page.sync="page"
            hide-default-footer
            @page-count="pageCount = $event"
            :items-per-page="itemsPerPage"
            mobile-breakpoint="0"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>

                  <v-col cols="12" md="5">
                    <v-text-field

                      hide-details
                      class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                          input-icon
                        "
                      flat
                      filled
                      outlined
                      color="pink lighten-3"
                      solo

                      v-model="filter.ricerca"
                      placeholder="Ricerca Vini"
                    >
                      <template slot="prepend-inner">
                        <v-icon
                          color="#adb5bd"
                          size="16"
                          class="material-icons-round mt-n2"
                        >search</v-icon
                        >
                      </template>
                    </v-text-field>
                  </v-col>


                  <v-col cols="12" md="5">
                    <v-text-field
                      hide-details
                      class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                          input-icon
                        "
                      flat
                      filled
                      outlined
                      color="pink lighten-3"
                      solo
                      v-model="search"
                      placeholder="Ricerca nella pagina"
                    >
                      <template slot="prepend-inner">
                        <v-icon
                          color="#adb5bd"
                          size="16"
                          class="material-icons-round mt-n2"
                        >search</v-icon
                        >
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-spacer></v-spacer>
                <!-- ******************* DIALOG *******************-->
                <v-dialog v-model="dialog" max-width="800" >

                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      elevation="0"
                      :ripple="false"

                      height="43"
                      class="
                          font-weight-normal
                          text-capitalize
                          btn-primary
                          bg-gradient-primary
                          py-3
                          px-6
                          ms-3
                        "
                    >Aggiungi Vino </v-btn
                    >
                  </template>
                  <v-card class="card-shadow">
                    <v-card-title class=" card-border-bottom" style="background-color: #ECEFF1">
                        <span class="font-weight-bold text-h5 text-typo mb-0">
                          {{ formTitle }}
                        </span>
                    </v-card-title>
                    <v-card-text class="card-padding">
                      <v-container class="px-0">
                        <v-row>

                          <!-- *********** NOME VINO *********** -->
                          <v-col cols="12">

                            <v-text-field
                              label="Nome"
                              v-model="editedItem.name"
                              hide-details
                              class="
                                  input-style
                                  font-size-input
                                  text-light-input
                                  placeholder-light
                                  input-icon
                                "
                              dense
                              flat
                              height="43"
                            ></v-text-field>
                          </v-col>

                          <!-- *********** VERSIONE *********** -->
                          <v-col cols="6">
                            <v-text-field
                              label="Versione"
                              v-model="editedItem.version"
                              hide-details
                              class="
                                  input-style
                                  font-size-input
                                  text-light-input
                                  placeholder-light
                                  input-icon
                                "
                              dense
                              flat
                              height="43"

                            ></v-text-field>
                          </v-col>

                          <!-- *********** GRADAZIONE *********** -->
                          <v-col cols="6">
                            <v-text-field
                              label="Gradazione"
                              v-model="editedItem.gradation"
                              hide-details
                              class="
                                  input-style
                                  font-size-input
                                  text-light-input
                                  placeholder-light
                                  input-icon
                                "
                              dense
                              flat
                              height="43"
                            ></v-text-field>
                          </v-col>

                          <!-- *********** VINIFICATION *********** -->
                          <v-col cols="6">
                            <v-text-field
                              label="Vinificazione"
                              v-model="editedItem.vinification"
                              hide-details
                              class="
                                  input-style
                                  font-size-input
                                  text-light-input
                                  placeholder-light
                                  input-icon
                                "
                              dense
                              flat
                              height="43"
                            ></v-text-field>
                          </v-col>

                          <!-- *********** VINTAGE *********** -->
                          <v-col cols="6">
                            <v-text-field
                              label="Annata"
                              v-model="editedItem.vintage"
                              hide-details
                              class="
                                  input-style
                                  font-size-input
                                  text-light-input
                                  placeholder-light
                                  input-icon
                                "
                              dense
                              flat
                              height="43"
                            ></v-text-field>
                          </v-col>

                          <!-- *********** SOLDOUT *********** -->

                          <v-col cols="6">
                            <v-checkbox
                              label="Esaurito"
                              v-model="editedItem.sold_out"
                              :value="editedItem.soldoutCheck"
                              color="pink"
                              hide-details
                            ></v-checkbox>
                            <!-- {{ editedItem.sold_out }} -->
                          </v-col>

                          <!-- *********** MAGNUM *********** -->
                          <v-col cols="6">

                            <v-checkbox
                              label="Magnum"
                              v-model="editedItem.magnum"
                              :value="editedItem.magnumCheck"
                              color="pink"
                              hide-details
                            ></v-checkbox>
                            <!-- {{ editedItem.magnum }} -->

                          </v-col>

                          <!-- *********** TYPE *********** -->
                          <v-col cols="6">

                            <!-- <v-autocomplete
                               v-model="editedItem.type"
                               :items="tipi"
                               :menu-props="{ top: true, offsetY: true }"
                               item-text="name"
                               item-value="id"
                               :search-input.sync="cercaTipo"
                               label="Tipo"
                               cache-items
                               class="mx-4"
                               flat
                               hide-no-data
                               hide-details
                             ></v-autocomplete> -->

                            <v-select
                              label="Tipo"
                              v-model="editedItem.type"
                              :items="tipi"
                              :menu-props="{ top: true, offsetY: true }"
                              item-text="name"
                              item-value="id"
                              :search-input.sync="search"
                              required
                            ></v-select>
                            <!-- {{ editedItem.type }} -->

                          </v-col>

                          <!-- *********** TYPE GRAPES *********** -->
                          <v-col cols="6">

                            <v-select
                              v-model="editedItem.type_grapes"
                              :items="tipiUva"
                              :menu-props="{ top: true, offsetY: true }"
                              item-text="name"
                              item-value="id"
                              label="Uva"
                              data-vv-name="select"
                              multiple
                              required
                            ></v-select>

                            <!-- {{ editedItem.type_grapes }}   -->
                          </v-col>

                          <!-- *********** WINEMAKER *********** v-on:change="payloadTypeGrapes"-->
                          <v-col cols="6">

                            <h4>Produttore</h4>
                                <div class="">
                                <v-subheader>{{ tipoName }}</v-subheader>
                                </div>
                          </v-col>

                            <!-- <v-autocomplete
                              v-model="defaultItem.winemakerId"
                              :items="produttori"
                              :menu-props="{ top: true, offsetY: true }"
                              item-text="name"
                              item-value="id"
                              :search-input.sync="cercaProduttore"
                              label="Produttore"
                              disabled
                              cache-items
                              class="mx-4"
                              flat
                              hide-no-data
                              hide-details
                            ></v-autocomplete> -->



                          <!-- *********** BARCODE *********** -->
                          <v-col cols="6">
                            <v-text-field
                              disabled
                              v-model="editedItem.barcode"
                              label="Barcode"
                              hide-details
                              class="
                                  input-style
                                  font-size-input
                                  text-light-input
                                  placeholder-light
                                  input-icon
                                "
                              dense
                              flat
                              filled
                              solo
                              height="43"

                            ></v-text-field>
                          </v-col>


                          <!-- *********** LATITUDINE *********** -->
                          <!-- <v-col cols="6">
                            <v-text-field @change="forceRerender"
                                          label="Latitudine"
                                          v-model="editedItem.latitude"
                                          type="number"
                                          hide-details
                                          class="
                                  input-style
                                  font-size-input
                                  text-light-input
                                  placeholder-light
                                  input-icon
                                "
                                          dense
                                          flat
                                          height="43"

                            ></v-text-field>
                          </v-col> -->

                          <!-- *********** LONGITUDINE *********** -->
                          <!-- <v-col cols="6">
                            <v-text-field @change="forceRerender"
                                          label="Longitudine"
                                          v-model="editedItem.longitude"
                                          type="number"
                                          hide-details
                                          class="
                                  input-style
                                  font-size-input
                                  text-light-input
                                  placeholder-light
                                  input-icon
                                "
                                          dense
                                          flat
                                          height="43"

                            ></v-text-field>
                          </v-col> -->

                          <!-- *********** NOTE *********** -->
                          <v-col cols="12">
                            <v-textarea v-model="editedItem.note"
                                        :items="vini"
                                        item-text="note"
                                        label="Note"
                            >
                            </v-textarea>
                          </v-col>
                          <!-- *********** MAPPA *********** -->
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions class="card-padding d-flex justify-end" style="background-color: #ECEFF1">
                      <v-btn
                        @click="close"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        class="
                            font-weight-normal
                            text-capitalize
                            btn-ls btn-outline-secondary
                            bg-transparent
                            py-3
                            px-6
                          "
                      >Cancella</v-btn
                      >

                      <v-btn
                        @click="save"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        class="
                            font-weight-normal
                            text-capitalize
                            btn-ls btn-primary
                            bg-gradient-primary
                            py-3
                            px-6
                          "
                      >Salva</v-btn
                      >

                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card class="card-shadow card-padding border-radius-xl">
                    <v-card-title class="pt-0 text-h5 text-typo justify-center"
                    >Vuoi eliminare questo Vino?</v-card-title
                    >
                    <v-card-actions class="pb-0">
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="closeDelete"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        class="
                            font-weight-normal
                            text-capitalize
                            btn-ls
                            bg-transparent
                            btn-outline-secondary
                            py-3
                            px-6
                          "
                      >Cancel</v-btn
                      >

                      <v-btn
                        @click="deleteItemConfirm"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        class="
                            font-weight-normal
                            text-capitalize
                            btn-ls btn-primary
                            bg-gradient-primary
                            py-3
                            px-6
                          "
                      >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

  <!--          <template v-slot:item.name="{ item }">--> # todo capire se si riesce a fare l'edit in line
  <!--            <v-text-field v-model="editedItem.name" :hide-details="true" dense single-line :autofocus="true" v-if="item.id === editedItem.id"></v-text-field>-->
  <!--            <span v-else>{{item.name}}</span>-->
  <!--          </template>-->

            <template v-slot:[`item.name`]="{ item }">
              <span class="text-body font-weight-bold">
                <v-chip
                  link
                  class="ma-2"
                  :color="item.type_color"
                  label
                  outlined
                  text-color="blue-grey darken-1"
                  @click="editItem(item)"
                >
                  {{ item.name }}
                </v-chip>
              </span>
            </template>

            <template v-slot:[`item.type`]="{ item }">
                {{ item.type_name }}
            </template>

            <template v-slot:[`item.sold_out`]="{ item }">
              <span v-if="item.sold_out">
                  <v-icon size="20" class="material-icons-round mt-n2" style="color:#D32F2F">check</v-icon>
              </span>
            </template>

            <template v-slot:[`item.magnum`]="{ item }">
              <!-- <span > -->
              <span v-if="item.magnum">
                  <v-icon size="20" class="material-icons-round mt-n2" color="#345543">check</v-icon>
              </span>
              <span v-else></span>
            </template>

            <template v-slot:[`item.type_grapes_name`]="{ item }">
              <span v-for="(grape, index) in item.type_grapes_name" :key="index" >
                  <span>/ {{ grape.name }} </span>
              </span>
            </template>

            <template v-slot:[`item.note`]="{ item }">
              <v-tooltip bottom color="white" max-width="350px">
                <template v-slot:activator="{ on, attrs }">
                  <span v-if="item.note.length<10"></span>
                  <span v-else v-bind="attrs" v-on="on">{{ item.note.substring(0,10)+".." }}</span>
                </template>
                <v-alert color="pink lighten-5">{{ item.note }}</v-alert>
              </v-tooltip>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                @click="editItem(item)"
                icon
                elevation="0"
                :ripple="false"
                height="28"
                min-width="36"
                width="36"
                color="#67748e"
              >
                <v-icon size="14" class="material-icons-round"
                >edit</v-icon
                >
              </v-btn>

              <v-btn
                @click="deleteItem(item)"
                icon
                elevation="0"
                :ripple="false"
                height="28"
                min-width="36"
                width="36"

                color="#67748e"
              >
                <v-icon size="14" class="material-icons-round">close</v-icon>
              </v-btn>
            </template>

            <template v-slot:[`items.winemaker`]="{ item }">
              <span>
                {{ item.winemaker }}
              </span>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="card-padding">
          <v-row>

            <!-- PAGINAZIONE CUSTOM -->

            <!-- <v-col cols="6" lg="3" class="d-flex align-center">
              <span class="text-body me-3 text-sm">Items per page:</span>
              <v-text-field
                hide-details
                type="number"
                outlined
                min="-1"
                max="15"
                background-color="rgba(255,255,255,.9)"
                color="rgba(0,0,0,.6)"
                light
                :value="itemsPerPage"
                @input="itemsPerPage = parseInt($event, 10)"
                placeholder="Items per page"
                class="
                  font-size-input
                  placeholder-lighter
                  text-color-light
                  input-alternative input-focused-alternative input-icon
                "
              >
              </v-text-field>
            </v-col> -->

            <v-col cols="6" class="ml-auto d-flex justify-end">
              <v-pagination
                @input="loadPagina()"
                prev-icon="fa fa-angle-left"
                next-icon="fa fa-angle-right"
                class="pagination"
                color="#D81B60"
                v-model="page"
                :length="pageCount"
                circle
              ></v-pagination>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </div>
  </template>




  <script>
  import users from "./Users";
  import wineServices from '@/services/wine.services';
  import winemakerService from "@/services/winemaker.service";
  import typeAndtypegrapesService from "@/services/typeAndtypegrapes.service";
  import { eventBus } from '@/event-bus.js';


  export default {
    name: "paginated-tables",
    components: {},

    data() {
      return {
        page: 1,
        pageCount: 0,

        itemsPerPage: 20,
        dialog: false,
        dialogDelete: false,
        users,
        // -------------------
        componentKey: 0,
        vini: [],
        tipi: [],
        tipiUva: {},
        produttori: [],
        tipoName: '',
        // distributori: [],
        itemId: "",
        conteggioVini: null,

        loading: false,
        items: [],
        cercaTipo: null,
        cercaProduttore: null,
        cercaDistro: null,

        tempTypeGrapesName: [],
        newTypeGrapesName: [],

        checkbox1: {
          a: true,
          b: false,
        },

        checkbox2: true,

        // -------------------
        search: "",
        editedIndex: -1,
        editedItem: {
          name: "",
          version: "",
          type: "",
          gradation: "",
          vinification: "",
          vintage: "",
          soldoutCheck: "",
          magnumCheck: "",
          // soldoutEdit: Boolean,
          // magnumEdit: Boolean,
          typegrapes: "",
          type_grapes_name: [],
          winemaker_id: "",
          // distributor: "",
          note: "",
          barcode: "",
          // latitude: "",
          // longitude: "",

        },
        defaultItem: {
          name: "",
          version: "",
          type: "",
          gradation: "",
          vinification: "",
          vintage: "",
          soldoutCheck: "",
          magnumCheck: "",
          // soldoutEdit: Boolean,
          // magnumEdit: Boolean,
          typegrapes: "",
          type_grapes_name: [],
          tipoId: "",
          winemaker_name: "",
          // distributor: "",
          note: "",
          barcode: "",
          // latitude: "",
          // longitude: "",

        },
        headers: [
          {
            text: "Nome",
            sortable: false,
            value: "name",
          },
          {
            text: "Tipo",
            value: "type",
          },
          {
            text: "Esaurito",
            value: "sold_out",
            sortable: false,
          },
           {
            text: "Magnum",
            value: "magnum",
          },
           {
            text: "Uva",
            value: "type_grapes_name",
          },
           {
            text: "Produttori",
            value: "winemaker_name",
          },
           {
            text: "Distributore",
            value: "distributor_name",
          },
          //  {
          //   text: "Barcode",
          //   value: "barcode",
          //
          // },
          {
            text: "Note",
            value: "note",
          },
          {
            text: "Actions",
            value: "actions",
            sortable: false,
          },
        ],
        progress: false,
        openFiltri: false,
        filter: {
          type:"",
          winemaker:'',
          esaurito:"",
          magnum:"",
          ricerca:'',
          ricercaNeiProd: '',
        }
      };
    },
    mounted() {
    //   this.getVini()
      this.getTipi()
      this.getTipiUva()
      this.getProduttori()
    //   // this.getDistributori()
    //   this.loadPagina()

      eventBus.$on('tipoId', (val) => {
        this.defaultItem.tipoId = val
        this.getVini(val)
    //   this.controlloOrdine = val
    });

    eventBus.$on('tipoName', (val) => {
      this.tipoName = val;
    });

     },

    methods: {
      mostraFiltri() {
        return this.openFiltri = !this.openFiltri
      },

      forceRerender(){
        this.componentKey += 1;
      },

      loadPagina(){
        this.page + 1
        this.getVini()

      },

      getVini(val){
        const params = {
            by_type_id: val,
        //   winemakerId: val,
        //   page: this.page,
        //   type: this.filter.type,
        //   winemaker_name: this.filter.winemaker,
        //   sold_out: this.filter.esaurito,
        //   magnum: this.filter.magnum,
        //   search: this.filter.ricerca

        }
        wineServices.getVini(params)
          .then((resp) => {
            this.vini = resp.data.results
            this.vini.sort()
            this.conteggioVini = resp.data.count
            this.pageCount = Math.ceil(resp.data.count/this.itemsPerPage)

        })

      },

      getViniRefresh(){
        this.progress = true
        const params = {
          by_type_id: this.defaultItem.tipoId,
          search: this.filter.ricerca
        }
        wineServices.getVini(params)
          .then((resp) => {
            this.vini = resp.data.results
            this.vini.sort()
            this.conteggioVini = resp.data.count
            this.pageCount = Math.ceil(resp.data.count/this.itemsPerPage)
            this.progress = false
        })
      },

      getTipi(){
        typeAndtypegrapesService.getTipi()
        .then((resp) => {
            this.tipi = resp.data.results
        })
      },

      getTipiUva(){
        typeAndtypegrapesService.getTipiUva()
        .then((resp) => {
            this.tipiUva = resp.data.results
        })
      },

      getProduttori(){
        winemakerService.getProduttori()
        .then((resp) => {
            this.produttori = resp.data.results
        })
      },

      newVino(){
        const payload = {
          name: this.editedItem.name,
          version: this.editedItem.version,
          type: this.editedItem.type,
          gradation: this.editedItem.gradation,
          vinification: this.editedItem.vinification,
          vintage: this.editedItem.vintage,
          sold_out: this.editedItem.sold_out,
          magnum: this.editedItem.magnum,
          type_grapes: this.editedItem.type_grapes,
          winemaker: this.defaultItem.tipoId,
          distributor: this.editedItem.distributor,
          note: this.editedItem.note,
          // latitude: this.editedItem.latitude,
          // longitude: this.editedItem.longitude,
          // barcode: this.editedItem.barcode,
        }
        console.log(payload)
        wineServices.newVino(payload)
        this.getViniRefresh()
        // .then((resp) => {
        //   console.log(resp)
        // }
        // )

      },

      editItem(item) {

        this.editedIndex = this.vini.indexOf(item);
        this.editedItem = Object.assign({}, item);
        // ------------------
        this.itemId = item.id
        // ------------------
        this.dialog = true
      },

      deleteItem(item) {
        this.editedIndex = this.vini.indexOf(item);
        this.editedItem = Object.assign({}, item);
         // ------------------
        this.itemId = item.id
        // -------------------
        this.dialogDelete = true;
      },

      deleteItemConfirm() {
        const itemId = this.itemId
        this.vini.splice(this.editedIndex, 1);
        wineServices.deleteVino(itemId)
        this.conteggioVini = null
        this.closeDelete();
      },

      close() {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },

      closeDelete() {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },

      modify() {
        const itemId = this.itemId
        const edited = {
          type: this.editedItemwinemaker,
          name: this.editedItem.name,
          version: this.editedItem.version,
          gradation: this.editedItem.gradation,
          vinification: this.editedItem.vinification,
          vintage: this.editedItem.vintage,
          sold_out: this.editedItem.sold_out,
          magnum: this.editedItem.magnum,
          type_grapes: this.editedItem.type_grapes,
          winemaker: this.editedItem.winemaker,
          distributor: this.editedItem.distributor,
          note: this.editedItem.note,
          // latitude: this.editedItem.latitude,
          // longitude: this.editedItem.longitude,
          // barcode: this.editedItem.barcode,
        }

        wineServices.editVino(itemId, edited)
          .then(() => {
            this.$dialog.message.success('Vino Salvato correttamente' , {
              type:'success',
              rounded: true,
              position: 'top-right',
              color: "green accent-5",
              outlined:true,
              timeout: 1000,
            })
          })

        this.getVini()
        this.dialog = false;


      },

      save() {

        if (this.editedIndex > -1) {
          Object.assign(this.vini[this.editedIndex], this.editedItem);
          this.modify()
          this.getVini()


        } else {
          this.vini.push(this.editedItem);
          this.newVino()
          this.getViniRefresh()
        //   this.loadPagina()
          this.dialog = false;

        }

      },


    },

    watch: {
      dialog(val) {
        val || this.close();
      },
      dialogDelete(val) {
        val || this.closeDelete();
      },
      "filter.ricerca"(value) {
        if(value.length >= 1) {
          return this.getViniRefresh()
        }
        if (value.length === 0) {
          return this.getViniRefresh()
        }
      },

      conteggioVini(newValue, oldValue){
      if(newValue != oldValue) {
        this.getViniRefresh()
      }
    },



    },

  // --------------------- PAGINAZIONE
    computed: {
      formTitle() {
        return this.editedIndex === -1 ? "Aggiungi Vino" : "Modifica Vino";
      },
      pages() {
        return this.pagination.rowsPerPage
          ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
          : 0;
      },
    },
  };
  </script>

  <style scoped>
  /*span {*/
  /*  color: #546E7A;*/
  /*}*/

  /*tbody tr:nth-of-type(odd) {*/
  /*  !* 'teal lighten-5' basides on material design color *!*/
  /*  background-color: #ffffff;*/
  /*}*/

  /*tbody tr:nth-of-type(even) {*/
  /*  !* 'deep-orange lighten-5' basides on material design color *!*/
  /*  background-color: #f7f7ff;*/
  /*}*/
  </style>
