<template>
  <v-dialog
      v-model="showTable"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-bolder text-capitalize btn-primary bg-gradient-primary py-3 px-6 ms-3"
          small
      >Aggiungi Rack
      </v-btn>
    </template>
    <small style="color: red">AddRack</small>
    <v-card v-if="showTable">
      <v-container class="px-6 py-6">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <h3 v-if="wareHouse">Crea un nuovo Rack nel Magazzino:
                  <span style="color: indigo">{{ wareHouse.name }}</span>
                </h3>
                <v-row>
                  <v-col
                      cols="12"
                      md="3"
                  >
                    <v-text-field
                        v-model="nameRack"
                        label="Nome"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      md="3"
                  >
                    <v-text-field
                        v-model.lazy.number="dtable.cols"
                        label="Colonne"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      md="3"
                  >
                    <v-text-field
                        v-model.lazy.number="dtable.rows"
                        label="Righe"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      md="4"
                  >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-left" v-for="(col, k) in dtable.cols" :key="k">
                        <span>
                          {{ letters[k] }}
                        </span>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row, i) in dtable.rows" :key="i" :id="`r${i}`">
                      <td v-for="(col, k) in dtable.cols" :key="k" :id="`d${k}`">
                        <span class="subtitle text-xxs text--lighten-1">{{ letters[k] }}{{ i + 1 }}</span>
                        <v-text-field :label="row[i]" @change="handleInput($event, getCellCode(k, i))"></v-text-field>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn class="float-right" @click="saveTable" color="indigo" dark>Salva</v-btn>
            <v-btn class="float-right mr-2" @click="closeTable" color="pink" dark>Chiudi</v-btn>
          </v-col>
        </v-row>
        <details>
          {{ this.wareHouse }}
        </details>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import warehouseService from "@/services/warehouse.service";
import { getCellCode } from "@/views/Pages/WareHouse/utils";


export default {
  name: "AddRack",
  props: [
    'wareHouse'
  ],
  data() {
    return {
      showTable: false,
      nameRack:"",
      dtable: {
        cols: 0,
        rows: 0,
      },
      columns: 5,
      rows: 10,
      rack: null,
      dati: [],
    }
  },
  mounted() {},
  computed: {
    letters() {
      let letters = []
      for(let i = "A".charCodeAt(0); i <= "Z".charCodeAt(0); i++) {letters.push(String.fromCharCode([i]))}
      return letters
    },
    structurePosition() {
      return { warehouse: this.wareHouse.name, rackName: this.nameRack }
    },
    buildStruct() {
      let struct = []
      for(let row =0; row < this.dtable.rows; row++) {
        for (let col = 0; col < this.dtable.cols; col++) {
          struct.push({
            boxPosition: this.structurePosition,
            cell:this.letters[col] + (row + 1),
            items:[]
          },)
        }
      }
      return struct
    }
  },
  methods: {
    handleInput() {
    },
    saveTable() {
      const params = {
        name: this.nameRack,
        columns: this.dtable.cols,
        rows: this.dtable.rows,
        warehouse: this.wareHouse.id,
        structure: this.buildStruct
      }
      warehouseService.saveRack(params).then(() => {
        this.$dialog.message.error('Rack Inserito', {
          rounded: true,
          position: 'top-right',
          color: "green accent-5",
          outlined: false,
        })
        this.$emit('refreshRack')
        this.showTable = false
      }).catch(error => {
        this.$dialog.notify.error(`Errore: ${error.data}`, {
          type: 'danger',
          rounded: true,
          position: 'top-right',
          color: "red accent-3",
          outlined: false,
        })
      })
    },
    closeTable() {
      this.showTable = false
        this.$emit('resetDialogRack')
    },
    getCellCode(row, col) {
      return getCellCode(row, col)
    }
  },
};
</script>
<style scoped>
td {
  border: 2px dashed #3bafcd;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
th {
  border: 1px solid #ff9900;
}
</style>
