var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.progress}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":70,"width":7,"color":"purple"}})],1),_c('v-card',{staticClass:"card-shadow border-radius-xl",attrs:{"elevation":"5"}},[_c('div',{staticClass:"card-header-padding"},[_c('div',{staticClass:"font-weight-bold text-h4 text-typo mb-0"},[_vm._v(" Elenco Casse "),_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","outlined":""}},[_vm._v(" Totale Casse: "+_vm._s(_vm.casse_totali)+" ")])],1)]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.casse,"item-key":"id","single-expand":"","expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event},"item-expanded":_vm.onItemExpanded},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{staticClass:"align-end",attrs:{"cols":"12"}},[_c('add-cassa',{on:{"addCassa":_vm.aggiungiCassa}})],1)],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"bg-gray pr-0 pl-1",attrs:{"colspan":headers.length}},[_c('v-card',[(_vm.loading)?_c('v-overlay',{attrs:{"value":_vm.loading,"absolute":""}},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"indeterminate":"","color":"purple"}})],1):_vm._e(),_c('v-card-title',[_vm._v(" Il giorno: "),_c('b',{staticClass:"ml-2 mr-2 text-pink"},[_vm._v(" "+_vm._s(item.data))]),_vm._v(" ci sono stati un totale di "),_c('b',{staticClass:"text-pink ml-2 mr-2"},[_vm._v(_vm._s(_vm.bistrotCount))]),_vm._v(" Bistrot. "),_vm._l((_vm.conteggi),function(conteggio,name){return _c('div',{key:name,staticClass:"ml-5"},[_c('span',[_vm._v(_vm._s(name)+":")]),_c('span',{staticClass:"pink--text"},[_vm._v(" "+_vm._s(conteggio))])])}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","color":"pink"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],2),_c('v-data-table',{staticClass:"striped-table blue-grey--text darken-4 font-weight-bold rounded-0",attrs:{"dense":"","search":_vm.search,"items":_vm.bistrots,"headers":_vm.headersBistrots},scopedSlots:_vm._u([{key:"item.wine_name",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{class:item.warehouse_item_id ? 'text-pink':'font-weight-bold text-purple',attrs:{"text":""},on:{"click":function($event){return _vm.editBistrot(item)}}},[_vm._v(_vm._s(item.wine_name)+" ")])]}},{key:"item.movement_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.movement_date))+" ")]}}],null,true)})],1)],1)]}},{key:"item.data",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":""}},[_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.data)))])])]}},{key:"item.totalea",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold text-purple",attrs:{"color":"gray","outlined":"","small":""}},[_vm._v(_vm._s(item.totalea)+"€")])]}},{key:"item.totaleb",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold text-purple",attrs:{"color":"gray","outlined":"","small":""}},[_vm._v(_vm._s(item.totaleb)+"€")])]}},{key:"item.incasso",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold text-indigo",attrs:{"color":"gray","outlined":"","small":""}},[_vm._v(_vm._s(item.incasso)+"€")])]}},{key:"item.total_price_cost",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"gray","outlined":"","small":""}},[_vm._v(_vm._s(item.total_price_cost)+"€")])]}},{key:"item.removed_quantity",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold text-pink",attrs:{"color":"gray","outlined":"","small":""}},[_vm._v(_vm._s(item.removed_quantity))])]}},{key:"item.checked",fn:function(ref){
var item = ref.item;
return [(item.checked ===  false)?_c('div',[_c('i',{staticClass:"fa fa-lock",staticStyle:{"color":"red"}})]):_c('div',[_c('i',{staticClass:"fa fa-lock-open",staticStyle:{"color":"green"}})])]}},{key:"item.email_sended",fn:function(ref){
var item = ref.item;
return [(item.email_sended ===  false)?_c('div',[_c('i',{staticClass:"fas fa-envelope",staticStyle:{"color":"red"}})]):_c('div',[_c('i',{staticClass:"far fa-envelope",staticStyle:{"color":"green"}})])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('edit-cassa',{attrs:{"itemCassa":item},on:{"saveCassa":_vm.saveEditCassa}}),_c('delete-button',{attrs:{"item":item},on:{"confirm-delete":_vm.deleteItemConfirm}})]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"max-width":"600","title":"modifica Bistrot","id":"editBistrot"},model:{value:(_vm.showEditBistrot),callback:function ($$v) {_vm.showEditBistrot=$$v},expression:"showEditBistrot"}},[_c('edit-bistrot',{attrs:{"bistrot":_vm.bistrot_item},on:{"closeDialog":_vm.closeEdit}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }