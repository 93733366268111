<template>
  <v-card class="card-shadow border-radius-xl">
    <v-card-title
        class="card-border-bottom"
        style="background-color: #E32E6F">
          <span class="font-weight-bold text-white text-h5 mb-0">
            Aggiungi Order Items
          </span>
    </v-card-title>
    <v-card-text class="card-padding">
      <v-container class="px-0">
        <!-- form per validazione  -->
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <!-- *********** LISTINO *********** -->
            <!--                          <v-col cols="3" ref="listino"></v-col>-->

            <!-- *********** VINO *********** -->
            <!-- {{ editedItem }} -->
            <v-col cols="6">
              <v-autocomplete
                  ref="vino"
                  v-model="editedItem.price_list_item"
                  hide-selected
                  :menu-props="{ top: false, offsetY: true }"
                  :label="editedItem.wine_name"
                  :items="listiniItems"
                  :search-input.sync="filter.ricercaRigaOrdine"
                  :clearable="true"
                  color="pink"
                  placeholder="Ricerca vino"
                  item-text="wine_name_vintage"
                  item-value="id"
                  flat
              ></v-autocomplete>
            </v-col> <!-- *********** QUANTITA' *********** -->
            <v-col cols="6">
              <v-text-field
                  @change="getBottleQty(editedItem.quantity)"
                  label="Quantità"
                  v-model="editedItem.quantity"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  color="pink"
                  flat
                  height="43"
                  type="number"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-card
              style="background-color: #ebebeb"
              v-if="editedItem.quantity">
            <v-card-text>
              <v-text-field
                  class="text-white font-weight-bold"
                  v-model="priceListItem.price_list_name"
                  label="Nome Listino"
                  hide-details="auto"
                  color="pink"
                  disabled
              ></v-text-field>
              <v-row>
                <v-col>
                  <v-text-field
                      v-model="priceListItem.price_cost"
                      prefix="€"
                      label="Listino"
                      color="pink"
                      hide-details="auto"
                  ></v-text-field>
                  <v-text-field
                      prefix="€"
                      v-model="priceListItem.price_reduce"
                      label="Acquisto"
                      color="pink"
                      hide-details="auto"
                      @input="calcolaCampoPercentuale"
                  ></v-text-field>
                  <v-text-field
                      v-model="priceListItem.price_wine_glass"
                      prefix="€"
                      label="Prezzo al Calice Consigliato"
                      color="pink"
                      hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                      v-model="priceListItem.percentage_value"
                      prefix="%"
                      label="Sconto"
                      color="pink"
                      hide-details="auto"
                      @input="calcolaCampoRiduzione"
                  ></v-text-field>
                  <v-text-field
                      v-model="priceListItem.price_sell"
                      prefix="€"
                      label="Prezzo Asporto Consigliato"
                      color="pink"
                      hide-details="auto"
                  ></v-text-field>
                  <div class="">
                    <v-btn
                        block
                        elevation="0"
                        :ripple="false"
                        large
                        class="block font-weight-normal text-capitalize btn-ls btn-primary bg-gradient-primary mt-3 py-3 px-6"
                        @click="salvaPriceListItem">
                      Modifica Listino
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
        <details>
          <summary style="color: red">Item Valorizza</summary>
          {{ valorizza }}
        </details>
      </v-container>
    </v-card-text>

    <v-card-actions class="card-padding d-flex justify-end">
      <v-btn
          @click="close"
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-normal text-capitalize btn-ls btn-outline-secondary bg-transparent py-3 px-6"
      >Cancella
      </v-btn
      >
      <v-btn
          :disabled="editedItem.quantity == null || false || editedItem.quantity === ''"
          @click="save"
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-normal text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
      >Salva
      </v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>

import listinoService from "@/services/listino.service";
import ordineService from "@/services/ordine.service";

export default {
  props: {
    gWOD: Object,
    orderId: [Number, String]
  },
  data() {
    return {
      valid: "",
      editedItem: {},
      listiniItems: [],
      priceListItem: {},
      filter: {},
      distributorOrWinemaker: {},
      // Valorizza i campi
      valorizza: {
        costo: null,
        quantity: null,
        price: "",
        bottleCount: null,
        listinoItemsId: "",
      },
      // Show Price List

    }
  },
  watch: {
    gWOD(val) {
      console.log('gWOD: ', val)
      this.listiniItems = []
      this.getRelativePriceListItem()
    },
    'editedItem.price_list_item' (val) {
      this.retrieveRelativePriceListItem(val)
      console.log('price_list_item: ', val)
    },
    "editedItem.quantity"(val) {
      if (val !== null) {
        this.valorizza.quantity = val
        this.costo()
      }
    }
  },
  mounted() {
    console.log('gWODDDD: ')
    this.listiniItems = []
    this.getRelativePriceListItem()
  },
  methods: {
    // Get
    async getRelativePriceListItem() {
      const params = this.gWOD
      params['page_size'] = 500
      await listinoService.getListiniItemsLight(params)
          .then(resp => {
            this.listiniItems = resp.data.results
          })
          .catch()
          .finally(() => {
            this.distributor = null
            this.dialog = true
          })
    },
    async retrieveRelativePriceListItem(id) {
      await listinoService.getListiniItemsById(id).then(resp => {
        this.priceListItem = resp.data
      }).catch(error => {
        console.log(error)
      }).finally()
    },
    close() {
      this.$emit('refresh')
      this.$emit('close')
    },
    save() {
      const payload = {
        status: "In Progress",
        order: this.orderId,
        pricelist: this.editedItem.pricelist,
        wine: this.editedItem.wine,
        quantity: this.editedItem.quantity,
        price_list_item: this.editedItem.price_list_item,
        order_item_cost: this.valorizza.costo,
      };
      // eslint-disable-next-line no-debugger
      // debugger;
      ordineService.newOrdineItem(payload).then(() => {
        this.priceListItem = {}
        this.$dialog.message.success("Riga aggiunta correttamente", {
          type: "success",
          rounded: true,
          position: "top-right",
          color: "green accent-5",
          outlined: false,
          timeout: 1000,
        });
      }).catch(err => {
        this.$dialog.message.error(`Error: ${err}`, {
              type: "error",
              rounded: true,
              position: "top-right",
              color: "green accent-5",
              outlined: false,
              timeout: 1000,
            }
        )
      }).finally(() => {
        this.close()
      })
    },
    salvaPriceListItem() {
      if (this.editedItem.quantity === undefined || this.editedItem.quantity === "") {
        this.$dialog.message.error("Campo Quantità Vuoto", {
          type: "error",
          rounded: true,
          position: "top-right",
          color: "red accent-3",
          outlined: false,
          timeout: 2000,
        });
      } else {
        listinoService.editListinoItem(this.priceListItem.id, this.priceListItem).then(resp => {
          console.log(resp.data)
          this.$dialog.message.success("Listino modificato correttamente", {
            type: "success",
            rounded: true,
            position: "top-right",
            color: "green accent-5",
            outlined: false,
            timeout: 1000,
          });
        })
      }
    },
    // Calcoli
    calcolaCampoPercentuale() {
      if (this.priceListItem.price_cost !== this.priceListItem.price_reduce) {
        this.priceListItem.percentage_value = ((
            (this.priceListItem.price_cost - this.priceListItem.price_reduce) /
            this.priceListItem.price_cost
        ) * 100).toFixed(2);
      } else {
        this.priceListItem.percentage_value = 0;
      }
    },
    calcolaCampoRiduzione() {
      this.priceListItem.price_reduce =
          (this.priceListItem.price_cost -
              (this.priceListItem.price_cost * this.priceListItem.percentage_value) / 100).toFixed(2);
    },
    getBottleQty(quantity) {
      this.valorizza.quantity = quantity;
    },
    estraiDati(val) {
      let dati = this.listiniItems.filter(function (item) {
        return item.id === val;
      });

      // eslint-disable-next-line no-debugger
      // debugger
      this.priceListItem = dati[0]
      this.valorizza.price = dati[0].price_reduce;
      this.valorizza.listinoId = val.id;
      this.valorizza.boxquantity = null;
      this.costo();
    },
    costo() {
      // eslint-disable-next-line no-debugger
      // debugger
      let boxQuantity = this.valorizza.boxquantity;
      let quantity = this.valorizza.quantity;
      let price = this.valorizza.price;
      if (boxQuantity != null)
        return (this.valorizza.costo = price * quantity * boxQuantity);
      else return (this.valorizza.costo = price * quantity);
    },
  }
}
</script>