<template>
  <div class="justify-center">
    <v-dialog
        v-model="dialog"
        width="600"
        transition="dialog-top-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            text
            v-bind="attrs"
            v-on="on"
        >
          <v-icon
              class="mr-4"
              small
              color="orange">mdi-warehouse
          </v-icon>
          Sposta
        </v-btn>
      </template>

      <template class="mx-4">
        <v-card>
          <v-toolbar
              color="pink"
              dark
          >Aggiungi bottiglia a magazzino
          </v-toolbar>

          <v-card-text>

            <v-spacer class="mt-5"/>
            Barcode Vino: <b>{{ vino }}</b> - Quantità disponibile: <b>{{ quantity }}</b>

            <v-spacer class="mb-5"/>
            <v-select
                v-model="magazzino"
                @click="getWarehouse"
                dense
                label="Scegli il Magazzino"
                color="pink"
                :items="magazzini"
                item-text="name"
                item-value="id"
            ></v-select>
            <v-select
                v-if="magazzino"
                v-model="rack"
                @click="getRack"
                :items="racks"
                label="Scegli il Rack"
                color="pink"
                dense
                item-text="name"
                item-value="id"
            ></v-select>
            <v-select
                v-model="item.cell"
                v-if="rack"
                @click="getCellRack"
                dense
                color="pink"
                label="Scegli la Cella"
                :items="cell_racks"
                item-text="name"
                item-value="id"
            ></v-select>
            <V-text-field
                v-if="item.cell"
                v-model="quantity_to_move"
                label="Scegli quantità"
            >
            </V-text-field>

          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="pink"
                dark
                @click="dialog = false"
            >
              Annulla
            </v-btn>
            <v-btn
                dark
                color="indigo"
                @click="save"
            >
              Aggiungi
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>


    </v-dialog>
  </div>
</template>

<script>
import listinoService from "@/services/listino.service";
import WarehouseService from "@/services/warehouse.service";

export default {
  props: {
    vino: {
      type: Number,
    },
    quantity: {
      type: [Number, String]
    },
    orderId: {
      type: [String, Number]
    },
    listItem: {
      type: Object
    },
    total_bottle_order: {
      type: Number
    },
    total_bottles_item_stocked: {
      type: Number
    },
    priceListItem: {type: Number}
  },
  data() {
    return {
      dialog: false,
      racks: [],
      rack: null,
      magazzini: [],
      magazzino: null,
      cell_racks: [],
      cell: null,
      quantity_to_move: this.quantity,
      item: {
        cell: null,
        id: null
      }
    }
  },
  created() {
    this.racks = [],
        this.rack = null,
        this.magazzini = [],
        this.magazzino = null,
        this.cell_racks = [],
        this.cell = null,
        this.quantity_to_move = this.quantity,
        this.item = {
          cell: null,
          id: null
        }
  },
  methods: {
    getWarehouse() {
      const params = {
        store: this.$store.state.auth.account.store[0]
      }
      WarehouseService.getMagazzini(params)
          .then(resp => {
            this.magazzini = resp.data.results
          })
          .catch()
    },
    getRack() {
      const params = {
        warehouse: this.magazzino
      }
      WarehouseService.getRacks(params)
          .then(resp => {
            this.racks = resp.data.results
          })
          .catch()
    },
    getCellRack() {
      const params = {
        rack: this.rack
      }
      WarehouseService.getCellRack(params)
          .then(resp => {
            this.cell_racks = resp.data.results
          })
          .catch()
    },
    addQuantity() {
      this.item.quantity++
    },
    removeQuantity() {
      this.item.quantity--
    },
    isOrderClosed() {

    },
    save() {
      const payload = {
        order_item_id: this.orderId,
        quantity: this.quantity_to_move,
        cell_rack: this.item.cell,
        store: this.$store.state.auth.user.store_id,
        wine: this.vino,
        p_list_item: this.listItem.price_list_item
      }
      listinoService.addWineToCell(payload)
          .then(() => {
            this.$dialog.message.success("Vino Inserito con Correttamente", {
              type: "success",
              rounded: true,
              position: "top-right",
              color: "green",
              outlined: false,
              timeout: 1000,
            })
            this.dialog = false
            this.$emit('refreshOrderItem')
          })
          .catch((xhr) => {
            this.$dialog.notify.warning(xhr.response.data.message, {
              type: "warning",
              rounded: true,
              position: "top-right",
              color: "red accent-3",
              outlined: false,
              timeout: 1000,
            })
          })
    }
  }
}
</script>

<style scoped>

</style>