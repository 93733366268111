<template>
  <div>
    <v-text-field
        v-model="search"
        :messages="resultSearch"
        append-icon="mdi-close"
        label="Ricerca Vino in tutti i magazzini"
        :loading="loading"
        class="flex-grow-1"
        color="pink"
        hide-details
    >
    </v-text-field>

  </div>
</template>

<script>

export default {
  name: "SearchInput",
  data() {
    return {
      search: '',
      resultSearch: '',
      loading: false
    }
  },
  watch: {
    search(value) {
      this.$emit('onSearchValue', value)
    }
  },
  methods: {
    prependIconCallback() {
      this.search = ''
    },
    performSearch() {
    }
  }
}
</script>

<style scoped>

</style>
