var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-6 py-6",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Ricorda di Ordinare:")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.bottles,"fixed-header":"","dense":"","loading":"","loading-text":"Caricamento pagina in corso..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" Bottiglie da ordinare: "+_vm._s(_vm.count)+" ")])],1)]},proxy:true},{key:"item.soldout",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex ml-4"},[_c('span',[(item.soldout)?_c('v-icon',{staticStyle:{"font-size":"1.7em"},attrs:{"color":"red lighten-2"}},[_vm._v("mdi-bottle-wine-outline")]):_c('v-icon',{staticStyle:{"font-size":"1.7em"},attrs:{"color":"green lighten-2"}},[_vm._v("mdi-bottle-wine")])],1)])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center ms-2"},[_c('span',{staticClass:"text-sm font-weight-normal text-body"},[(item.quantity.quantity_tot >= 5)?_c('v-chip',{staticClass:"d-flex justify-content-center",attrs:{"small":"","outlined":"","color":"green"}},[_vm._v(_vm._s(item.quantity.quantity_tot))]):(item.quantity.quantity_tot === 0)?_c('v-chip',{staticClass:"d-flex justify-content-center",attrs:{"small":"","outlined":"","color":"red"}},[_vm._v(_vm._s(item.quantity.quantity_tot))]):(
                (item.quantity.quantity_tot < 5) & (item.quantity.quantity_tot > 1)
              )?_c('v-chip',{staticClass:"d-flex justify-content-center",attrs:{"small":"","outlined":"","color":"light"}},[_vm._v(_vm._s(item.quantity.quantity_tot))]):(item.quantity.quantity_tot === null)?_c('v-chip',{staticClass:"d-flex justify-content-center",attrs:{"small":"","outlined":"","color":"red"}},[_vm._v("0")]):_vm._e()],1)])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-ls me-2 my-2 rounded-sm",attrs:{"icon":"","elevation":"0","ripple":false,"height":"28","min-width":"36","width":"36","color":"#67748e"},on:{"click":function($event){return _vm.rememberTo(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"material-icons-round",class:{'text-green': item.remember_to, 'text-gray': !item.remember_to},attrs:{"size":"14"}},[_vm._v(" mdi-check-decagram-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Rimuovi dalla Lista")])])]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }