<template>
  <v-card class="card-shadow">
    <v-card-title
        class="card-border-bottom"
        style="background-color: #E32E6F">
          <span class="font-weight-bold text-white text-h5 mb-0">
            Aggiungi Produttore
          </span>
    </v-card-title>
    <v-card-text class="card-padding">
      <v-container class="px-0">
        <v-row>
          <!-- *********** Ricerca *********** -->
          <v-col cols="12">
            <h2>Ricerca per Azienda o Indirizzo</h2>
            <gmap-autocomplete @place-selected="handlePlaceSelected"></gmap-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <!-- *********** NOME *********** -->
          <v-col cols="12">
            <v-text-field
                label="Nome"
                v-model="produttore.name"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** INDIRIZZO *********** -->
          <v-col cols="6">
            <v-text-field
                label="Indirizzo"
                v-model="produttore.address"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** LATITUDINE *********** -->
          <v-col cols="6">
            <v-text-field
                label="Latitudine"
                v-model="produttore.lat"
                color="pink"
                type="number"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** LONGITUDINE *********** -->
          <v-col cols="6">
            <v-text-field
                label="Longitudine"
                v-model="produttore.long"
                color="pink"
                type="number"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** REGIONE *********** -->
          <v-col cols="6">
            <v-text-field
                label="Regione"
                v-model="produttore.region"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** PROVINCIA *********** -->
          <v-col cols="6">
            <v-text-field
                label="Provincia"
                v-model="produttore.province"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** COMUNE *********** -->
          <v-col cols="6">
            <v-text-field
                label="Comune"
                v-model="produttore.town"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** NAZIONE *********** -->
          <v-col cols="6">
            <v-text-field
                label="Nazione"
                v-model="produttore.country"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** CITTA' *********** -->
          <v-col cols="6">
            <v-text-field
                label="Città"
                v-model="produttore.city"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** CAP *********** -->
          <v-col cols="6">
            <v-text-field
                label="CAP"
                v-model="produttore.postal_code"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** TELEFONO *********** -->
          <v-col cols="6">
            <!-- {{ produttore }} -->
            <v-text-field
                label="Telefono"
                v-model="produttore.telephone"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** EMAIL *********** -->
          <v-col cols="6">
            <v-text-field
                label="Email"
                v-model="produttore.email"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** SITO WEB *********** -->
          <v-col cols="6">
            <v-text-field
                label="Sito Web"
                v-model="produttore.website"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** FACEBOOK *********** -->
          <v-col cols="6">
            <v-text-field
                label="Condizioni di Vendita"
                v-model="produttore.condizioni_di_vendita"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** PARTITA IVA *********** -->
          <v-col cols="6">
            <v-text-field
                label="Partita IVA"
                v-model="produttore.vat"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** IBAN *********** -->
          <v-col cols="6">
            <v-text-field
                label="IBAN"
                v-model="produttore.iban"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** AGENTE *********** -->
          <v-col cols="6">
            <v-text-field
                label="Agente"
                v-model="produttore.commercial_agent"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** DESCRIZIONE *********** -->
          <v-col cols="6">
            <v-text-field
                label="Descrizione"
                v-model="produttore.description"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** ETTARI *********** -->
          <v-col cols="6">
            <v-text-field
                label="Ettari"
                v-model="produttore.hectares"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** ANNO DI FONDAZIONE *********** -->
          <v-col cols="6">
            <v-text-field
                label="Anno di Fondazione"
                v-model="produttore.year_foundation"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** NR BOTTIGLIE PRODOTTE *********** -->
          <v-col cols="6">
            <v-text-field
                label="Numero di Bottiglie Prodotte"
                v-model="produttore.bottle_produced"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** VIGNAIOLO *********** -->
          <v-col cols="6">
            <v-text-field
                label="Vignaiolo"
                v-model="produttore.winemaker"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** DIRETTO *********** -->
          <v-col cols="3">
            <!--                <v-checkbox-->
            <!--                    v-if="produttore.distributore == null"-->
            <!--                    v-model="diretto"-->
            <!--                    label="Diretto"-->
            <!--                    color="green"-->
            <!--                    hide-details-->
            <!--                ></v-checkbox>-->
          </v-col>
          <!-- *********** DISTRIBUTORE *********** -->
          <v-col cols="6">
            <v-autocomplete
                v-model="produttore.distributor"
                :items="distributori"
                :menu-props="{ top: false, offsetY: true }"
                item-text="name"
                item-value="id"
                :search-input.sync="filter.ricerca"
                label="Distributore"
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                flat
                chips
            >
            </v-autocomplete>
            <!--            <v-alert-->
            <!--                v-if="selezionaProduttore"-->
            <!--                color="red"-->
            <!--                dense-->
            <!--                outlined-->
            <!--                shaped-->
            <!--                text-->
            <!--                type="error"-->
            <!--            >Prima seleziona un produttore-->
            <!--            </v-alert-->
            <!--            >-->
          </v-col>

          <!-- *********** MAPPA *********** -->
          <v-col cols="12">
            <!--            <Map-->
            <!--                :latitude="produttore.lat"-->
            <!--                :longitude="produttore.long"-->
            <!--                :key="componentKey"-->
            <!--                @lastLat="getLatitude"-->
            <!--                @lastLng="getLongitude"-->
            <!--            >-->
            <!--            </Map>-->
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="card-padding d-flex justify-end">
      <v-btn
          @click="close"
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-normal text-capitalize btn-ls btn-outline-secondary bg-transparent py-3 px-6"
      >Cancella
      </v-btn
      >
      <v-btn
          @click="save"
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-normal text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
      >Salva
      </v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>

import winemakerService from "@/services/winemaker.service";
import GmapAutocomplete from "@/components/GmapAutocomplete.vue";


export default {
  name: 'AddProduttore',
  components: {GmapAutocomplete},
  data() {
    return {
      distributori: [],
      filter: {
        ricerca: '',
        ricercaNazione: "",
        ricercaRegione: ""
      },
      produttore: {
        "address": "",
        "bottle_produced": "",
        "city": "",
        "commercial_agent": "",
        "country": "",
        "description": "",
        "email": "",
        "condizioni_di_vendita": "",
        "hectares": "",
        "distributor":"",
        "iban": "",
        "instagram": "",
        "iso": "",
        "lat": "",
        "long": "",
        "mobile": "",
        "name": "",
        "note": "",
        "postal_code": "",
        "province": "",
        "region": "",
        "telephone": "",
        "town": "",
        "vat": "",
        "website": "",
        "winemaker": "",
        "year_foundation": null,
        "zone": null
      },
      search: "",
      selectedPlace: {
        name: '',
        address: '',
        city: '',
        country: ''
      },
      selezionaProduttore: "",
    }
  },
  mounted() {
    this.getDistributori()
  },
  methods: {
    // Actions
    addButton() {
      this.showProduttore = true
    },
    close() {
      this.$emit('refresh')
      this.$emit('close')
    },
    save() {
      const payload = {
        store: this.$store.state.user.store_id,
        ...this.produttore
      }
      winemakerService.newProduttore(payload).then(() => {
        this.$dialog.notify.success('Vino Aggiunto con successo!', {color: 'green'})
      }).catch(error => {
        this.$dialog.notify.error('Errore nella creazione del vino', {color: 'red'})
        console.log('creazione vino: ', error)
      }).finally(() => {
        this.close()
      })
    },
    // Get
    getDistributori() {
      const params = {}
      winemakerService.getDistributori(params).then(resp => {
        this.distributori = resp.data.results
      }).catch().finally()
    },
    handlePlaceSelected(place) {
      if (place.address_components) {
        this.produttore.name = place.name || '';
        this.produttore.lat = place.geometry?.location.lat() || '';
        this.produttore.long = place.geometry?.location.lng() || '';
        this.produttore.address = place.formatted_address || '';

        const addressComponents = place.address_components;
        const getComponent = (type) => {
          return addressComponents.find(component => component.types.includes(type))?.long_name || '';
        };

        this.produttore.region = getComponent('administrative_area_level_1');
        this.produttore.province = getComponent('administrative_area_level_2');
        this.produttore.town = getComponent('locality') || getComponent('sublocality') || '';
        this.produttore.country = getComponent('country');
        this.produttore.city = getComponent('locality');
        this.produttore.postal_code = getComponent('postal_code');
        // Setta altri campi qui se disponibili
        this.produttore.telephone = place.international_phone_number || place.formatted_phone_number || '';
        this.produttore.website = place.website || '';
      }
    },
  }
}
</script>