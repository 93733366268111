<template>
  <div>
    <v-overlay :value="progress">
      <v-progress-circular indeterminate :size="70" :width="7" color="pink">
      </v-progress-circular>
    </v-overlay>
    <v-card class="card-shadow border-radius-xl" elevation="5">
      <div class="container">
        <div class="font-weight-bold text-h4 text-typo mb-0">
          <v-row>
            <v-col cols="10">
              Listini
              <v-chip class="ma-2" color="green" outlined>
                Totale Listini: {{ conteggioListini }}
              </v-chip>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="2" class="align-end">
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="font-weight-normal text-capitalize btn-primary bg-gradient-primary py-3 px-6 ms-3"
                  >Aggiungi Listino
                  </v-btn
                  >
                </template>
                <v-card v-if="dialog" class="card-shadow border-radius-xl">
                  <div class="card-header-padding card-border-bottom bg-pink">
                    <span class="font-weight-bold text-h5 mb-0 text-white">{{ formTitle }}</span>
                  </div>
                  <v-card-text class="card-padding">
                    <v-container class="px-0">
                      <v-row>
                        <!-- *********** STORE *********** -->
                        <v-col cols="12">
                          <h4>Store: {{ store.store_name }}</h4>
                        </v-col>
                        <!-- *********** NOME *********** -->
                        <v-col cols="6">
                          <v-text-field
                              label="Nome Listino"
                              v-model="editedItem.name"
                              hide-details
                              class="input-style font-size-input text-light-input placeholder-light input-icon"
                              dense
                              flat
                              color="pink"
                              height="43"
                          ></v-text-field>
                        </v-col>
                        <!-- *********** VARIABILE *********** -->
                        <v-col cols="6">
                          <v-text-field
                              label="Variabile"
                              v-model="editedItem.variable"
                              :rules="[() => !!editedItem.variable || 'This field is required']"
                              hide-details
                              color="pink"
                              class="input-style font-size-input text-light-input placeholder-light input-icon"
                              dense
                              flat
                              height="43"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions class="card-padding d-flex justify-end">
                    <v-btn
                        @click="close"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        class="font-weight-normal text-capitalize btn-ls btn-outline-secondary bg-transparent py-3 px-6"
                    >Cancella
                    </v-btn
                    >
                    <v-btn
                        @click="save"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        class="font-weight-normal text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
                    >Salva
                    </v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card class="card-shadow card-padding border-radius-xl">
                  <v-card-title class="pt-0 text-h5 text-typo justify-center"
                  >Vuoi eliminare questo Listino?
                  </v-card-title
                  >
                  <v-card-actions class="pb-0">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="closeDelete"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        class="font-weight-normal text-capitalize btn-ls bg-transparent btn-outline-secondary py-3 px-6"
                    >Cancel
                    </v-btn
                    >

                    <v-btn
                        @click="deleteItemConfirm"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        class="font-weight-normal text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
                    >OK
                    </v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-card-text>
        <v-data-table
            item-key="name"
            class="row-pointer"
            v-model="selectedItem"
            :headers="headers"
            :items="listini"
            single-select
            :search="search"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            fixed-header
            dense
            loading
            loading-text="Caricamento pagina in corso..."
            hide-default-footer
            mobile-breakpoint="0"
            @click:row="rowClick"
        >
          <template v-slot:[`item.name`]="{item}">
            <div class="d-flex font-weight-bolder">
              {{ item.name }}
            </div>
          </template>
          <template v-slot:[`item.store`]="">
            <div class="d-flex align-center ms-2">
              {{ store.store_name }}
            </div>
          </template>
          <template v-slot:[`item.variable`]="{item}">
            <v-chip small outlined color="orange">{{ item.variable }} €</v-chip>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
                @click="deleteItem(item)"
                icon
                elevation="0"
                :ripple="false"
                height="28"
                min-width="36"
                width="36"
                class="btn-ls me-2 my-2 rounded-sm float-right"
                color="#de0d0d"
            >
              <v-icon size="14" class="material-icons-round">close</v-icon>
            </v-btn>
            <v-btn
                @click="editItem(item)"
                icon
                elevation="0"
                :ripple="false"
                height="28"
                min-width="36"
                width="36"
                class="btn-ls me-2 my-2 rounded-sm float-right"
                color="#67748e"
            >
              <v-icon size="14" class="material-icons-round">edit</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
                @input="loadPagina()"
                prev-icon="fa fa-angle-left"
                next-icon="fa fa-angle-right"
                class="pagination"
                color="#D81B60"
                v-model="page"
                :length="pageCount"
                circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import wineServices from "@/services/wine.services";
import listinoService from "@/services/listino.service";
import {eventBus} from "@/event-bus.js";

export default {
  name: "paginated-tables",
  components: {},
  data() {
    return {
      page: 1,
      pageCount: 0,
      rowSelected: false,
      selectedId: -1,
      selectedItem: [0],
      selectedRows: {},
      itemsPerPage: 20,
      dialog: false,
      dialogDelete: false,
      listinoId: null,
      listini: [],
      store: [],
      itemId: "",
      conteggioListini: "",
      loading: false,
      items: [],
      distribuzione: ["Directly", "Large Distribution"],
      search: "",
      editedIndex: -1,
      editedItem: {
        name: "",
        store: "",
        variable: "",
      },
      defaultItem: {
        name: "",
        store: "",
        variable: "",
      },
      headers: [
        {
          text: "Nome",
          align: "center",
          cellClass: "border-bottom",
          sortable: false,
          value: "name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ",
        },
        {
          text: "Store",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "store",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Variabile",
          align: "center",
          cellClass: "border-bottom",
          sortable: false,
          value: "variable",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Actions",
          value: "actions",
          align: 'end',
          sortable: false,
          cellClass: "border-bottom",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
      ],
      progress: false,
      openFiltri: false,
      filter: {
        ricerca: "",
      }
    };
  },
  mounted() {
    this.getListini();
    this.loadPagina();
  },
  methods: {
    rowClick(item, row) {
      row.select(true)
      this.$emit("priceListId", item.id);
      this.$emit("variable", item.variable);
      this.$emit("var_a", item.var_a);
      this.$emit("var_b", item.var_b);
      this.$emit('passNameListino', item.name)
      eventBus.$emit("keyListino", row.index); // questo emit mostra le statistiche
    },
    loadPagina() {
      this.page
      this.getStoreInfo();
    },
    getStoreInfo() {
      wineServices.getStore(this.$store.state.auth.account.store[0]).then((resp) => {
        this.store = resp.data;
      });
    },
    getListini() {
      this.progress = true;
      const params = {
        store: this.$store.state.auth.account.store[0],
        page: this.page,
      };
      listinoService.getListini(params).then((resp) => {
        this.listini = resp.data.results;
        this.conteggioListini = resp.data.count;
        eventBus.$emit("totaleListini", resp.data.count);
        this.listini.sort();
        this.pageCount = Math.ceil(resp.data.count / this.itemsPerPage);
        this.progress = false;
      });
    },
    newListino() {
      const payload = {
        name: this.editedItem.name,
        store: this.$store.state.auth.account.store[0],
        variable: this.editedItem.variable,
      };
      listinoService.newListino(payload);
      this.getListini();
    },
    editItem(item) {
      this.editedIndex = this.listini.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.itemId = item.id;
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.listini.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.itemId = item.id;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      const itemId = this.itemId;
      this.listini.splice(this.editedIndex, 1);
      listinoService.deleteListino(itemId);
      this.conteggioListini = null;
      eventBus.$emit("delListino");
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    modify() {
      const itemId = this.itemId;
      const edited = {
        name: this.editedItem.name,
        store: this.editedItem.store,
        variable: this.editedItem.variable,
      };

      listinoService.editListino(itemId, edited).then(() => {
        this.$dialog.message.success('Listino Modificato con Successo', {
          type: 'success',
          position: 'top-right',
          color: 'green'
        })
      }).catch(() => {
        this.$dialog.message.error('Errore Modifica Listino', {
          type: 'error',
          position: 'top-right',
          color: 'red',
        })
      }).finally(() => {
        this.getListini();
        this.dialog = false;
      })
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.listini[this.editedIndex], this.editedItem);
        this.modify();
        this.getListini();
      } else {
        this.listini.push(this.editedItem);
        this.newListino();
        this.loadPagina();
        this.dialog = false;
      }
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    "filter.ricerca"(value) {
      if (value.length > 2) {
        return this.getListini();
      }
      if (value.length === 0) {
        return this.getListini();
      }
    },
    // TRIGGER LISTINO ID -> LISTINI ITEMS
    selectedItem: {
    handler(value) {
      console.log('SELECTED-ITEM: ', value)
      if (value && value.id) {
        this.$emit("priceListId", value.id);
      }
    },
    immediate: true, // Chiamata immediatamente dopo il montaggio del componente
    deep: true // Monitora anche le modifiche ai sottoproprietà di value
  },
    conteggioListini(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getListini();
      }
    }
    ,
  },
// --------------------- PAGINAZIONE
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Aggiungi Listino" : "Modifica Listino";
    },
    pages() {
      return this.pagination.rowsPerPage
          ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
          : 0;
    },
  }
  ,
}
;
</script>
<style>
tr.v-data-table__selected {
  background: #dbecff !important;
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
