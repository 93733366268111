<template>
  <div v-if="showCard">
    <v-overlay :value="progress">
      <v-progress-circular indeterminate :size="70" :width="7" color="purple">
      </v-progress-circular>
    </v-overlay>
    <v-card class="card-shadow border-radius-xl" elevation="5">
      <div class="card-header-padding">
        <div class="font-weight-normal text-h4 text-typo mb-0">
          Produttori Associati a <span class="font-weight-bold text-pink">{{ distributoreNome }}</span>:
          <v-chip class="ma-2" color="green" outlined>
            Totale Produttori: {{ grebWinemakers.length }}
          </v-chip>
        </div>
      </div>
      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="winemakers"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-dialog
                  v-model="addWinemaker"
                  width="500"


              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      elevation="0"
                      :ripple="false"
                      height="43"
                      v-bind="attrs"
                      v-on="on"
                      class="
                      font-weight-normal
                      text-capitalize
                      btn-primary
                      bg-gradient-primary
                      py-3
                      px-6
                      ms-3
                    "
                      @click="openDialog">Aggiungi Produttore
                  </v-btn>
                </template>
                <associa-produttore
                    v-model="addWinemaker"
                    :wineMakers="grebWinemakers"
                    @closeDialog="addWinemaker = false"
                    @refreshWinemakers="rWineMakers">
                </associa-produttore>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.how_many_wines`]="{ item }">
            <v-chip
                small
                text-color="white"
                color="purple"
                link
                @click="retrieveWine(item)"
            ><span class="font-weight-bolder">{{ item.how_many_wines }}</span>
            </v-chip>
          </template>
          <template v-slot:[`item.actions`]="{item}">
            <v-btn
                @click="deleteItem(item)"
                icon
                elevation="0"
                :ripple="false"
                height="28"
                min-width="36"
                width="36"
                class="btn-ls me-2 my-2 rounded-sm"
                color="red"
            >
              <v-icon size="14" class="material-icons-round">close</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog width="800" v-model="showWines">
      <v-card>
        <v-card-title class="text-h5 pink text-white lighten-2">
          Vini del Produttore: {{ winemaker }}
          <v-spacer></v-spacer> <!-- Questo componente spinge il pulsante di chiusura tutto a destra -->
          <v-btn class="text-white" icon @click="showWines = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
              dense
              :items="wines"
              :headers="wineHeaders"
          >
            <template v-slot:[`item.name`]="{ item }">
              <div @click="copyToClipboard(item.name)" class="dotted-underline">{{ item.name }}</div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                  @click="sendToPriceList(item)"
                  icon
                  elevation="0"
                  :ripple="false"
                  height="28"
                  min-width="36"
                  width="36"
                  class="btn-ls me-2 my-2 rounded-sm"
                  color="#67748e"
              >
                <v-icon size="14" class="material-icons-round">currency_exchange</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import AssociaProduttore from "@/views/Applications/Widgets/AssociaProduttore.vue";
import WinemakerService from "@/services/winemaker.service";
import wineServices from "@/services/wine.services";

export default {
  name: "DatatableDetailsDistributori",
  components: {
    AssociaProduttore,
  },
  props: [
    'showCard',
    'grebWinemakers',
    'fornitoreId',
    'distributoreNome'
  ],
  data() {
    return {
      progress: false,
      viewCard: false,
      headers: [
        {
          text: "Produttore",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "name",
          class:
              "text-secondary font-weight-normal opacity-7 border-bottom ps-6",
        },
        {
          text: "Diretto",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "direct_winemaker",
          class:
              "text-secondary font-weight-normal opacity-7 border-bottom ps-6",
        },
        {
          text: "Regione",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "region",
          class:
              "text-secondary font-weight-normal opacity-7 border-bottom ps-6",
        },
        {
          text: "Nazione",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "country",
          class:
              "text-secondary font-weight-normal opacity-7 border-bottom ps-6",
        },
        {
          text: "Vini",
          align: "start",
          cellClass: "border-bottom",
          sortable: true,
          value: "how_many_wines",
          class: "border-bottom opacity-7 ps-6",
        },
        {
          text: "Actions",
          align: "end",
          cellClass: "border-bottom",
          sortable: true,
          value: "actions",
          class: "border-bottom opacity-7 ps-6",
        },
      ],
      winemakers: [],
      showWines: false,
      wines: [],
      wineHeaders: [
        {
          text: "ID",
          sortable: false,
          value: "id",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Etichetta",
          sortable: false,
          value: "images",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Nome",
          sortable: false,
          value: "name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Annata",
          sortable: false,
          value: "vintage",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Actions",
          align: "end",
          sortable: true,
          value: "actions",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
      ],
      winemaker: '',
      addWinemaker: false,
      distributoreID: ""
    }
  },
  watch: {
    grebWinemakers(val) {
      this.winemakers = val
    },
    fornitoreId: {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.distributoreID = newVal
        }
      }
    }
  },
  methods: {
    rWineMakers() {
      WinemakerService.retrieveWinemaker(this.distributoreID).then(resp => {
        this.winemakers = resp.data
      })
    },
    retrieveWine(wine) {
      this.winemaker = wine.name
      const params = {
        'winemakerId': wine.id
      }
      wineServices.getVini(params).then(resp => {
        this.wines = resp.data.results
        this.showWines = true
      })
    },
    async deleteItem(item) {
      const payload = {
        winemaker: item.id,
        distributor: this.fornitoreId
      }
      await wineServices.console.log(payload)
    },
    sendToPriceList(item) {
      console.log(item)
    },
    async copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);
        await this.$dialog.message.success("Testo copiato negli appunti", {
              type: "success",
              rounded: true,
              position: "top-right",
              color: "green",
              outlined: false,
              timeout: 1000,
            }
        );
      } catch (err) {
        await this.$dialog.message.error("Impossibile copiare il testo negli appunti", {
          type: "success",
          rounded: true,
          position: "top-right",
          color: "green",
          outlined: false,
          timeout: 1000,
        })
      }
    },
    openDialog() {
      this.addWinemaker = true
    }
  }
}
</script>

<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0);
}

.dotted-underline {
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-color: #7c7c7c;
  cursor: pointer;
}
</style>