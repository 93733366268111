<template>
  <v-card class="card-shadow">
    <v-card-title
        class="card-border-bottom"
        style="background-color: #E32E6F">
          <span class="font-weight-bold text-white text-h5 mb-0">
            Modifica Produttore
          </span>
    </v-card-title>
    <v-card-text class="card-padding">
      <v-container class="px-0">
        <v-row>
          <!-- *********** Ricerca *********** -->
          <v-col cols="12">
            <h2>Ricerca per Azienda o Indirizzo</h2>
            <gmap-autocomplete @place-selected="handlePlaceSelected"></gmap-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <!-- *********** NOME *********** -->
          <v-col cols="12">
            <!-- {{ editedItem }} -->
            <v-text-field
                label="Nome"
                v-model="produttore.name"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** TIPO DISTRIBUZIONE *********** -->
          <!-- <v-col cols="6">
            <v-select
              label="Distribuzione"
              v-model="produttore.distribution"
              color="pink"
              :items="distribuzione"
              :menu-props="{ top: true, offsetY: true }"
              item-text="name"
              item-value="id"
              :search-input.sync="search"
              required
            ></v-select>
          </v-col> -->

          <!-- *********** INDIRIZZO *********** -->
          <v-col cols="6">
            <!-- {{ produttore }} -->
            <!-- <v-btn @lastLat="getLatitude">GET</v-btn> -->
            <v-text-field
                label="Indirizzo"
                v-model="produttore.address"
                color="pink"
                id="pac-input"

                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** LATITUDINE *********** -->
          <v-col cols="6">
            <!-- {{ produttore }} -->
            <v-text-field
                label="Latitudine"
                v-model="produttore.lat"
                color="pink"
                type="number"
                hide-details
                class="input-style font-size-input  text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** LONGITUDINE *********** -->
          <v-col cols="6">
            <!-- {{ produttore }} -->
            <v-text-field
                label="Longitudine"
                v-model="produttore.long"
                color="pink"
                type="number"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** REGIONE *********** -->
          <v-col cols="6">
            <!-- {{ produttore }} -->
            <v-text-field
                label="Regione"
                v-model="produttore.region"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** PROVINCIA *********** -->
          <v-col cols="6">
            <!-- {{ produttore }} -->
            <v-text-field
                label="Provincia"
                v-model="produttore.province"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** COMUNE *********** -->
          <v-col cols="6">
            <!-- {{ produttore }} -->
            <v-text-field
                label="Comune"
                v-model="produttore.town"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** NAZIONE *********** -->
          <v-col cols="6">
            <!-- {{ produttore }} -->
            <v-text-field
                label="Nazione"
                v-model="produttore.country"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** CITTA' *********** -->
          <v-col cols="6">
            <!-- {{ produttore }} -->
            <v-text-field
                label="Città"
                v-model="produttore.city"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** CAP *********** -->
          <v-col cols="6">
            <!-- {{ produttore }} -->
            <v-text-field
                label="CAP"
                v-model="produttore.postal_code"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>


          <!-- *********** TELEFONO *********** -->
          <v-col cols="6">
            <!-- {{ produttore }} -->
            <v-text-field
                label="Telefono"
                v-model="produttore.telephone"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** EMAIL *********** -->
          <v-col cols="6">
            <!-- {{ produttore }} -->
            <v-text-field
                label="Email"
                v-model="produttore.email"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** SITO WEB *********** -->
          <v-col cols="6">
            <!-- {{ produttore }} -->
            <v-text-field
                label="Sito Web"
                v-model="produttore.website"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** FACEBOOK *********** -->
          <v-col cols="6">
            <!-- {{ produttore }} -->
            <v-text-field
                label="Condizioni di Vendita"
                v-model="produttore.condizioni_di_vendita"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** PARTITA IVA *********** -->
          <v-col cols="6">
            <!-- {{ produttore }} -->
            <v-text-field
                label="Partita IVA"
                v-model="produttore.vat"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** IBAN *********** -->
          <v-col cols="6">
            <!-- {{ produttore }} -->
            <v-text-field
                label="IBAN"
                v-model="produttore.iban"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** AGENTE *********** -->
          <v-col cols="6">
            <!-- {{ produttore }} -->
            <v-text-field
                label="Agente"
                v-model="produttore.commercial_agent"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** DESCRIZIONE *********** -->
          <v-col cols="6">
            <!-- {{ produttore }} -->
            <v-text-field
                label="Descrizione"
                v-model="produttore.description"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** ETTARI *********** -->
          <v-col cols="6">
            <!-- {{ produttore }} -->
            <v-text-field
                label="Ettari"
                v-model="produttore.hectares"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** ANNO DI FONDAZIONE *********** -->
          <v-col cols="6">
            <!-- {{ produttore }} -->
            <v-text-field
                label="Anno di Fondazione"
                v-model="produttore.year_foundation"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** NR BOTTIGLIE PRODOTTE *********** -->
          <v-col cols="6">
            <!-- {{ produttore }} -->
            <v-text-field
                label="Numero di Bottiglie Prodotte"
                v-model="produttore.bottle_produced"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>

          <!-- *********** VIGNAIOLO *********** -->
          <v-col cols="6">
            <!-- {{ produttore }} -->
            <v-text-field
                label="Vignaiolo"
                v-model="produttore.winemaker"
                color="pink"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                height="43"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
                v-model="produttore.distributor"
                color="pink"
                :items="distributori"
                :menu-props="{ top: false, offsetY: true }"
                item-text="name"
                item-value="id"
                :search-input.sync="filter.ricerca"
                label="Distributore"
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                flat
                chips
            >
            </v-autocomplete>
          </v-col>
            <!-- *********** MAPPA *********** -->
            <!-- <v-col cols="12" v-if="produttore.address">

              <Map
                :latitude="produttore.lat"
                :longitude="produttore.long"
                :key="componentKey"
                @lastLat="getLatitude"
                @lastLng="getLongitude"
              >
              </Map>

            </v-col> -->

        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="card-padding d-flex justify-end">
      <v-btn
          @click="close"
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-normal text-capitalize btn-ls btn-outline-secondary bg-transparent py-3 px-6"
      >Annulla
      </v-btn
      >
      <v-btn
          @click="update"
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-normal text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
      >Modifica
      </v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>

import winemakerService from "@/services/winemaker.service";
import GmapAutocomplete from "@/components/GmapAutocomplete.vue";

export default {
  props: {
    item: Object
  },
  components: {GmapAutocomplete},
  data() {
    return {
      componentKey: 0,
      dialog: false,
      dialogAdd: false,
      distributori:[],
      filter: {
        ricerca: ''
      },
      produttore: [],
      produttori: [],
      selectedPlace: [],
      title: '',
    }
  },
  mounted() {
    this.produttore = this.item
    this.produttore.distributor = this.item.distributor.id
    this.getDistributori()
  },
  methods: {
    // Ricerca Google
    handlePlaceSelected(place) {
      if (place.address_components) {
        this.produttore.name = place.name || '';
        this.produttore.lat = place.geometry?.location.lat() || '';
        this.produttore.long = place.geometry?.location.lng() || '';
        this.produttore.address = place.formatted_address || '';

        const addressComponents = place.address_components;
        const getComponent = (type) => {
          return addressComponents.find(component => component.types.includes(type))?.long_name || '';
        };

        this.produttore.region = getComponent('administrative_area_level_1');
        this.produttore.province = getComponent('administrative_area_level_2');
        this.produttore.town = getComponent('locality') || getComponent('sublocality') || '';
        this.produttore.country = getComponent('country');
        this.produttore.city = getComponent('locality');
        this.produttore.postal_code = getComponent('postal_code');
        // Setta altri campi qui se disponibili
        this.produttore.telephone = place.international_phone_number || place.formatted_phone_number || '';
        this.produttore.website = place.website || '';
      }
    },
    onClickOutside() {
      console.log('ClickOutside!!!');
      this.dialog = true
    },
    // Get
    getDistributori() {
      const params = {
        page_size: 1000,
        select: true
      }
      winemakerService.getDistributori(params).then(resp => {
        this.distributori = resp.data.results
      }).catch().finally()
    },
    close() {
      this.$emit('refresh')
      this.$emit('close')
    },
    update() {
      const payload = {
        id: this.produttore.id,
        address: this.produttore.address,
        bottle_produced: this.produttore.bottle_produced,
        city: this.produttore.city,
        commercial_agent: this.produttore.commercial_agent,
        country: this.produttore.country,
        description: this.produttore.description,
        distribution: this.produttore.distribution,
        distributor: this.produttore.distributor,
        email: this.produttore.email,
        condizioni_di_vendita: this.produttore.condizioni_di_vendita,
        hectares: this.produttore.hectares,
        iban: this.produttore.iban,
        lat: this.produttore.lat,
        long: this.produttore.long,
        mobile: this.produttore.mobile,
        name: this.produttore.name,
        note: this.produttore.note,
        postal_code: this.produttore.postal_code,
        province: this.produttore.province,
        region: this.produttore.region,
        telephone: this.produttore.telephone,
        town: this.produttore.town,
        vat: this.produttore.vat,
        website: this.produttore.website,
        winemaker: this.produttore.winemaker,
        year_foundation: this.produttore.year_foundation,
      }
      winemakerService.editProduttore(payload).then(() => {
        this.$dialog.notify.success("Produttore modificato correttamente", {
          rounded: true,
          position: "top-right",
          color: "green accent-5",
          timeout: 1000,
        })
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.close()
      })
    }
  }
}
</script>