<template>
  <div class="mt-3">
    <v-overlay :value="progress">
      <v-progress-circular indeterminate :size="70" :width="7" color="purple">
      </v-progress-circular>
    </v-overlay>
    <v-card class="card-shadow border-radius-xl" elevation="5">
      <v-card-title>
        {{ nomeOrdine }}
        <v-chip class="ma-2" color="green" outlined>
          Righe d'ordine: {{ totalItems }}
        </v-chip>
      </v-card-title>
      <v-card-text>
        <v-data-table
            :items="ordiniItems"
            :headers="headers"
            item-key="id"
            :search="search"
            fixed-header
            loading
            loading-text="Caricamento pagina in corso..."
            :page.sync="page"
            hide-default-footer
            @page-count="pageCount = $event"
            :items-per-page="itemsPerPage"
            single-expand
            :expanded.sync="expanded"
            show-expand
            
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="12" md="5">
                  <v-text-field
                      hide-details
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      flat
                      filled
                      outlined
                      color="pink lighten-3"
                      solo
                      v-model="filter.ricerca"
                      placeholder="Ricerca riga d'ordine"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon color="#adb5bd" size="16" class="material-icons-round mt-n2"
                      >search
                      </v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="5">
                  <v-text-field
                      hide-details
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      flat
                      filled
                      outlined
                      color="pink lighten-3"
                      solo
                      v-model="search"
                      placeholder="Ricerca nella pagina"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon color="#adb5bd" size="16" class="material-icons-round mt-n2"
                      >search
                      </v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-spacer></v-spacer>
              <v-btn
                  @click="openDialogAggiungiRiga"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="font-weight-normal text-capitalize btn-primary bg-gradient-primary py-3 px-6 ms-3"
              >Aggiungi Riga
              </v-btn>
              <!--  ******************* Dialog Add/Edit/Delete ******************* -->
              <v-dialog v-if="ordineId" v-model="dialog" max-width="800px">
                <add-order-item
                    :g-w-o-d="getDistriOrProd"
                    :orderId="ordineId"
                    @refresh="getOrderItems"
                    @close="dialog = false"
                ></add-order-item>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card class="card-shadow card-padding border-radius-xl">
                  <v-card-title class="pt-0 text-h5 text-typo justify-center"
                  >Vuoi eliminare questo riga d'ordine?
                  </v-card-title
                  >
                  <v-card-actions class="pb-0">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="closeDelete"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        class="font-weight-normal text-capitalize btn-ls bg-transparent btn-outline-secondary py-3 px-6"
                    >Cancel
                    </v-btn
                    >
                    <v-btn
                        @click="deleteItemConfirm"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        class="font-weight-normal text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
                    >OK
                    </v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogEdit" max-width="800px">
                <edit-order-item
                    :itemOrder="editItem"
                    @refresh="getOrderItems"
                    @close="dialogEdit = false"
                ></edit-order-item>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:expanded-item="{ headers, }">
            <td v-if="expanded.length > 0" class="card-header-padding" :colspan="headers.length">
              <v-card class="border-radius-xl" style="background:#f5f1f1 ">
                <v-card-title>
                  dettagli:
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-list class="border-radius-xl">
                        <v-list-item>
                          ID:
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <span v-on="on" class="text-pink dotted-underline"
                                    @click="copyToClipboard(expanded[0].price_list_item)">
                                    {{ expanded[0].price_list_item }}
                              </span>
                            </template>
                            <span>Clicca per copiare</span>
                          </v-tooltip>
                        </v-list-item>
                        <v-list-item>
                          Name: <span class="text-pink">{{ expanded[0].wine_name }}</span>
                        </v-list-item>
                        <v-list-item>
                          Vintage: <span class="text-pink">{{ expanded[0].wine_vintage }}</span>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col>
                      <v-list class="border-radius-xl">
                        <span>Bottiglie Stoccate in:</span>
                        <v-list-item v-for="(path, index) in expanded[0].where_stocked" :key="index">
                          {{ formatString(path) }}
                        </v-list-item>
                        <v-list-item>
                          Prezzo rispetto l'anno scorso: <span class="text-pink">+15%</span>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </td>
          </template>
          <template v-slot:[`item.wine_name`]="{ item }">
            <div class="pl-0 ml-0">
              <v-chip color="pink" outlined small>{{ item.wine_name }}</v-chip>
            </div>
          </template>
          <template v-slot:[`item.wine_vintage`]="{ item }">
            <div class="">
              <span class="text-sm font-weight-normal text-body">
                {{ item.wine_vintage }}
              </span>
            </div>
          </template>
          <template v-slot:[`item.winemaker_name`]="{ item }">
            <div class="d-flex align-center ms-2">
              <span class="text-sm font-weight-normal text-body">
                {{ item.winemaker_name }}
              </span>
            </div>
          </template>
          <template v-slot:[`item.distributor_name`]="{ item }">
            <div class="d-flex align-center ms-2">
                        <span class="text-sm font-weight-normal text-body">
                          {{ item.distributor_name }}
                        </span>
            </div>
          </template>
          <template v-slot:[`item.quantity`]="{ item }">
            <div class="d-flex align-center ms-2">
                        <span v-if="item.box_quantity" class="text-sm font-weight-normal text-body">
                          {{ item.bottle_in_the_box }}
                        </span>
              <span v-else class="text-sm font-weight-normal text-body">
                          {{ item.quantity }}
                        </span>
            </div>
          </template>
          <template v-slot:[`item.total_bottle_order`]="{ item }">
            <div class="d-flex align-center ms-2">
                        <span class="text-sm font-weight-normal" style="color: #1A73E8">
                          {{ item.total_bottle_order }}
                        </span>
            </div>
          </template>
          <template v-slot:[`item.status_display`]="{ item }">
            <v-chip small dark :class="item.status_display === 'Stocked'?'green':'info'">{{ item.status_display }}
            </v-chip>
          </template>
          <template v-slot:[`item.item_complete`]="{ item }">
            <div class="d-flex align-center ms-2">
                        <span class="text-sm font-weight-normal" style="color: #0a2c5a">
                          <v-chip :color="item.item_complete === 'Not Complete' ? 'red':'green'" small
                                  class="text-white">{{ item.item_complete }}</v-chip>
                        </span>
            </div>
          </template>
          <template v-slot:[`item.quantity_stocked`]="{ item }">
            <div class="d-flex align-center ms-2">
                <span class="text-sm font-weight-normal text-body">
                  {{ item.quantity_stocked }}
                </span>
            </div>
          </template>
          <template v-slot:[`item.total_cost_order`]="{ item }">
            <div class="d-flex align-center ms-2">
                        <span class="text-sm font-weight-normal text-body">
                          {{ parseFloat(item.total_cost_order).toFixed(2) }}
                        </span>
            </div>
          </template>
          <template v-slot:[`item.issue`]="{ item }">
                      <span v-if="item.issue">
                        <v-icon size="14" class="material-icons-round mt-n2" style="color: #d32f2f"
                        >check</v-icon
                        >
                      </span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu style="background-color: white">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list-item style="background-color: white">
                <v-btn
                    @click="handleEditItem(item)"
                    text
                >
                  <v-icon
                      size="14"
                      class="material-icons-round mr-6"
                      style="color: green"
                  >edit
                  </v-icon>
                  Modifica
                </v-btn>
              </v-list-item>
              <v-list-item style="background-color: white">
                <v-list-item-title>
                  <v-btn
                      @click="deleteItem(item)"
                      text
                  >
                    <v-icon
                        size="14"
                        class="material-icons-round mr-6"
                        style="color: red"
                    >close
                    </v-icon>
                    Elimina
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
              <v-list-item style="background-color: white">
                <v-list-item-title>
                  <AddWineToCellOrder
                      v-if="item.item_complete !== 'Order Completed'"
                      :vino="item.wine"
                      :quantity="item.quantity"
                      :listItem="item"
                      :order-id="item.id"
                      :total_bottle_order="item.total_bottle_order"
                      :total_bottles_item_stocked="item.total_bottles_item_stocked"
                      @refreshOrderItem="getOrderItems"/>
                </v-list-item-title>
              </v-list-item>
              <v-list-item style="background-color: white">
                <v-list-item-title>
                  <print-barcode-order-items :value="item"></print-barcode-order-items>
                </v-list-item-title>
              </v-list-item>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="6" class="ml-auto d-flex justify-center">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#D81B60"
              v-model="page"
              :length="totalPages"
              @input="getOrderItems"
            ></v-pagination>
          </v-col>
      </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import ordineService from "@/services/ordine.service";
import AddWineToCellOrder from "@/views/Pages/WareHouse/components/AddWineToCellOrder.vue";

import wineServices from "@/services/wine.services";
import AddOrderItem from "@/views/Pages/Ordini/AddOrderItem.vue";
import EditOrderItem from "@/views/Pages/Ordini/EditOrderItem.vue";
import PrintBarcodeOrderItems from "@/views/Pages/Ordini/PrintBarcodeOrderItemFromChatGpt.vue";

export default {
  components: {AddWineToCellOrder, AddOrderItem, EditOrderItem, PrintBarcodeOrderItems},
  props: {
    idOrdine: [Number, String],
    getDistriOrProd: Object,
    nomeOrdine: String
  },
  data() {
    return {
      progress: false,
      // Id Ordine e Oggetto Winemaker o Distributor
      ordineId: null,
      getWinemakerOrDistributor: {},
      // Table
      ordiniItems: [],
      headers: [
        {
          text: "Vino",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "wine_name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Annata",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "wine_vintage",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Produttore",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "winemaker_name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Distributore",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "distributor_name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Stato",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "status_display",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Tot.",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "total_bottle_order",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Btl",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "quantity",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Stocked",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "quantity_stocked",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "€",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "total_cost_order",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Act",
          value: "actions",
          align: 'end',
          sortable: false,
          cellClass: "border-bottom",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
      ],
      conteggioOrdiniItems: 0,
      // Table search
      searchWine: true,
      search: "",
      openFiltri: false,
      filter: {
        ricerca: "",
        nomelistino: "",
        ricercaRigaOrdine: "",
      },
      // Dialog
      dialog: false,
      dialogDelete: false,
      dialogEdit: false,
      // Valorizzazione della Dialog Edit Item
      editItem: {},
      deleteItemId: '',
      // Paginazione
      page: 1,
      itemsPerPage: 10,
      totalItems:0,
      
      // Parte in cui recupera i listini per ogni Produttore
      listiniItems: [],
      expanded: [],
      singleExpand: true,
    }
  },
  watch: {
    idOrdine(newVal) {
      this.ordineId = newVal
      this.getWinemakerOrDistributor = this.getDistriOrProd
      this.getOrderItems()
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    }
  },
  methods: {
    // Get
    getOrderItems() {
      this.progress = true
      const params = {
        order: this.ordineId,
        page:this.page,
        page_size: this.itemsPerPage
      }
      ordineService.getOrdiniItems(params).then(resp => {
        this.ordiniItems = resp.data.results
        this.totalItems = resp.data.count;
        // this.pageCount = Math.ceil(resp.data.count / this.itemsPerPage);
        this.progress = false;
      }).catch().finally(() => {
        this.$emit('itemChanged')
      })
    },
    // Boolean
    openDialogAggiungiRiga() {
      this.dialog = true
    },
    // Riga di espansione
    clickRow(item, event) {
      if (event.isExpanded) {
        const indexExpanded = this.expanded.findIndex(i => i === item);
        this.expanded.splice(indexExpanded, 1)
      } else {
        wineServices.getVino(item.wine).then(resp => {
          this.expanded = resp.data.results
        })
      }
    },
    // Pagination
    onPaginationChanged(pagination) {
      console.log('Pagination changed:', pagination);
      if (pagination.itemsPerPage === -1) {
        console.log('User selected "All"');
        // Gestisci qui l'evento "All"
      }
    },
    async copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);
        await this.$dialog.message.success("Testo copiato negli appunti", {
              type: "success",
              rounded: true,
              position: "top-right",
              color: "green",
              outlined: false,
              timeout: 1000,
            }
        );
      } catch (err) {
        await this.$dialog.message.error("Impossibile copiare il testo negli appunti", {
          type: "success",
          rounded: true,
          position: "top-right",
          color: "green",
          outlined: false,
          timeout: 1000,
        })
      }
    },
    // Dialog
    handleEditItem(item) {
      console.log('item: ', item)
      this.editItem = item
      this.$nextTick(() => {
        this.dialogEdit = true
      })
    },
    deleteItem(item) {
      this.deleteItemId = item.id
      this.dialogDelete = true
      console.log(item)
    },
    closeDelete() {
      console.log('closeDelete')
    },
    deleteItemConfirm(item) {
      ordineService.deleteOrdineItem(this.deleteItemId).then(() => {
        this.$dialog.message.success('Ordine Item Eliminato', {})
      }).catch((xhr) => {
        this.$dialog.message.error(xhr.response.data[0])
      }).finally(() => {
        this.getOrderItems()
        this.dialogDelete = false
      })
      console.log('deleteItemConfirm', item)
    },
    formatString(arr) {
      const arrCopy = [...arr];
      const bottles = arrCopy.pop()
      const location = arrCopy.join('/');
      return `- ${bottles} bottiglie in ${location}`;
    },
  }
}

</script>
<style scoped>
tr.v-data-table__selected {
  background: #5b99de !important;
}

.fill-height {
  height: 100%;
}

.dotted-underline {
  text-decoration: 2px underline;
  text-decoration-style: dotted;
  text-decoration-color: #7c7b7b;
  cursor: pointer;
}

.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  color: deeppink;
  box-shadow: none;
  background-color: #f5f1f1;
}
</style>