<template>
  <div>
    <v-card
        class="mt-3"
        outlined
        style="background-color: #ebebeb">
      <v-card-title>
        Modifica Bistrot: <span style="color: #cb0c9f; margin-left: 3px"> #{{ b.id }}</span>
      </v-card-title>
      <v-card-text>
        <v-select
            v-if="b.warehouse_item_id === null"
            v-model="warehouseItemId"
            :items="warehouseItems"
            :item-text="item => `
              ${item.wine_name} -
              ${item.wine_vintage} -
              ${item.warehouse} -
              ${item.rack} -
              ${item.cell_name} -
              `"
            item-value="id"
            @change="selectWarehouse"
        ></v-select>
        <v-text-field
            label="Ora dello Scarico"
            color="pink"
            type="text"
            disabled
            v-model="formattedDate">
        </v-text-field>
        <v-text-field
            label="Tipo di Scarico"
            color="pink"
            type="text"
            disabled
            v-model="b.download_name">
        </v-text-field>
        <v-text-field
            label="Bottiglia"
            color="pink"
            type="text"
            disabled
            v-model="b.wine_name">
        </v-text-field>
        <v-text-field
            label="Quantità Scaricata"
            color="pink"
            type="text"
            v-model="b.removed_quantity">
        </v-text-field>
        <v-select
            label="Magazzino"
            v-model="magazzino_id"
            :items="magazzini"
            item-text="name"
            item-value="id"
        ></v-select>
        <v-select
            v-model="rack_id"
            label="Rack"
            color="pink"
            :items="racks"
            item-text="name"
            item-value="id">
          type="text"
          v-model="b.rack_name">
        </v-select>
        <v-select
            :items="celle"
            item-text="name"
            item-value="id"
            label="Cella"
            color="pink"
            type="text"
            v-model="b.cell">
        </v-select>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="pink"
            dark
            @click="save"
        >
          Modifica
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import WarehouseService from "@/services/warehouse.service";

export default {
  name: 'EditBistrot',
  props: {
    bistrot: Object
  },
  data() {
    return {
      b: {},
      magazzini: [],
      magazzino_id: '',
      racks: [],
      rack_id: "",
      celle: [],
      celle_id: "",
      warehouseItems:[],
      warehouseItemId:"",
      warehouseItem:{}
    }
  },
  watch: {
    bistrot: {
      immediate: true,
      async handler(newVal) {
        console.log('newVal: ', newVal)
        // Assicurati che `newVal` esista prima di tentare di accedervi
        if (!newVal) return;

        this.b = {...newVal};
        if (this.b.warehouse_item_id === null) {
          console.log('carica tutti i warehouse relativi ...')
          const cleaned_string = this.b.wine_name.split(" - ")[0]
          const params = { by_wine_name: cleaned_string, store: this.$store.state.auth.user.store_id }
          WarehouseService.getWarehouseItems(params).then(res => {
            this.warehouseItems = res.data.results
            console.log(res.data.results)
          })
        }

        // Popola magazzini (assicurati che questa operazione sia completata prima di procedere)
        await this.getMagazzini();

        // Imposta il valore di `magazzino_id` in base al bistrot corrente
        this.magazzino_id = newVal.warehouse_id || "" // Assicurati che 'newVal.store' contenga l'id del magazzino corretto

        // Popola i rack in base al magazzino selezionato
        await this.updateRacks(this.magazzino_id);

        // Imposta il valore di `rack_id` in base al bistrot corrente
        this.rack_id = newVal.rack_id; // Assumi che 'newVal.rack' contenga l'id del rack corretto

        // Se necessario, qui puoi anche chiamare un metodo per aggiornare le celle
      }
    },
    magazzino_id(value) {
      if (!value) return;
      this.updateRacks(value);
    },
    async rack_id(value) {
      const params = {
        rack: value
      }
      await WarehouseService.getCellRack(params).then(resp => {
        this.celle = resp.data.results
      })
    },
  },
  filters: {},
  mounted() {
    this.b = this.bistrot
    console.log(this.bistrot)
    // this.getMagazzini()
  },
  computed: {
    formattedDate: {
      get() {
        // Usa qui il metodo di formattazione per ottenere la data formattata
        return this.formatDate(this.bistrot.movement_date);
      },
    },
  },
  methods: {
    async getMagazzini() {
      try {
        const params = {
          store: this.$store.state.auth.user.store_id
        }
        const resp = await WarehouseService.getMagazzini(params);
        this.magazzini = resp.data.results;

        // Potresti voler impostare magazzino_id qui se è il momento appropriato
        // e se hai bisogno di aspettare che magazzini sia popolato
      } catch (error) {
        console.error(error);
      }
    },
    async updateRacks(magazzinoId) {
      try {
        const params = {
          warehouse__id: magazzinoId
        }
        const resp = await WarehouseService.filterRackById(params);
        this.racks = resp.data.results;

        // Qui potresti voler impostare rack_id se necessario
      } catch (error) {
        console.error(error);
      }
    },
    formatDate(value) {
      if (!value) return '';
      const date = new Date(value);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() restituisce mesi da 0 (gennaio) a 11 (dicembre)
      const year = date.getFullYear().toString().substring(2);
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      // Formatta la data nel nuovo formato
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    },
    selectWarehouse() {
      const selectedItem = this.warehouseItems.find(item => item.id === this.warehouseItemId);
      this.warehouseItem = selectedItem;
      this.rack_id = selectedItem.rack_id
      this.celle_id = selectedItem.cell_id
    },
    save() {
      const payload = {
        'id': this.b.id,
        "warehouse_item_id": this.b.warehouse_item_id || this.warehouseItemId,
        "wineId": this.b.wineId || null,
        "removed_quantity": this.b.removed_quantity || null,
        "old_quantity": this.b.old_quantity || 0,
        "warehouse": this.b.warehouse || this.warehouseItem.warehouse,
        "rack_name": this.b.rack_name || this.warehouseItem.rack,
        "cell_name": this.b.cell_name || this.warehouseItem.cell_name,
        "movement_date": this.b.movement_date || null,
        "store": this.b.store || null,
        "price_list_item": this.b.price_list_item || null,
        "download_type": this.b.download_type || null,
        "cell": this.b.cell || null
      }
      WarehouseService.editBistrot(payload).then(() => {
        this.$emit('closeDialog')
        this.$dialog.message.success("Scarico Modificato Correttamente", {
          rounded: true,
          position: "top-right",
          color: "green",
          outlined: false,
          timeout: 1000,
        })

      }).catch().finally()
      console.log('payload: ', payload)
    }
  },
}
</script>