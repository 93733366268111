<template>
  <div>
    <v-overlay :value="racksLoading">
      <v-progress-circular indeterminate :size="70" :width="7" color="purple">
      </v-progress-circular>
    </v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col>
          <stats-card-total v-if="showStats" :stats="statsValue"></stats-card-total>
          <stats-card v-if="showStats" :stats="statsValue"></stats-card>
        </v-col>
      </v-row>
      <v-card class="card-shadow border-radius-xl">
        <v-card-title>
          <v-toolbar
              flat
          >
            <v-toolbar-title class="font-weight-bold text-h4 text-typo mb-0">
              <div v-if="$store.state.auth.account">
                Magazzini
                -
                <span style="color: #e98f41">{{ $store.state.auth.user.store_name }}</span>
              </div>
            </v-toolbar-title>
            <v-divider
                class="mx-4"
                inset
                vertical
            ></v-divider>
            <div class="d-flex flex-grow-1">
              <!-- Applica flex-grow-1 a search-input per occupare tutto lo spazio disponibile -->
              <search-input class="flex-grow-1" @onSearchValue="passResultSearch"></search-input>
            </div>
          </v-toolbar>
        </v-card-title>
      </v-card>
      <v-row>
        <v-col>
          <v-card class="card-shadow border-radius-xl mt-2 pt-0 pl-0 pr-0 mb-2">
            <v-data-table
                v-model="selected"
                :headers="headersWarehouse"
                :items="warehousesData"
                single-select
                item-key="id"
                show-select
                hide-default-footer
                @click:row=showRack
            >
              <template v-slot:top>
                <v-toolbar
                    flat
                >
                  <v-toolbar-title class="font-weight-bold text-h4 text-typo mb-0 border-radius-2xl">
                    Lista Magazzini: {{ warehousesData.length }}
                  </v-toolbar-title>
                  <v-divider
                      class="mx-4"
                      inset
                      vertical
                  ></v-divider>
                  <v-spacer></v-spacer>
                  <v-btn
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="font-weight-bolder text-capitalize btn-primary bg-gradient-primary py-3 px-6 ms-3"
                      small
                      @click="createSnapshot"
                  >Crea SnapShot
                  </v-btn>
                  <add-ware-house
                      :show-add-warehouse="showAddWarehouse"
                      :ware-house="warehouses"
                      @resetDialog="closeDialog"/>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div v-if="item.id === editedItem.id">
                  <v-icon color="red" class="mr-3" @click="closeEdit">
                    close_fullscreen
                  </v-icon>
                  <v-icon color="green" @click="saveEdit(item)">
                    done
                  </v-icon>
                </div>
                <div v-else>
                  <v-icon class="mr-3" @click="editItem(item)">
                    edit
                  </v-icon>
                  <v-icon outlined color="red" @click="deleteItem(item)">
                    delete_outline
                  </v-icon>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="card-shadow border-radius-xl">
            <v-card-text class="mt-2 pt-0 pl-0 pr-0 mb-2">
              <rack-component
                  :racks="racksData"
                  :loading="racksLoading"
                  :warehouse="selectedWarehouse"
                  :externalSearchParam="resultSearch"
                  @passItemToShowRack="openShowRack"
                  @initRack="fetchRacks"
                  @rack="passRack"
              ></rack-component>
            </v-card-text>
          </v-card>
          <v-card class="shadow border-radius-lg">
            <v-card-title>Cell Rack</v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <cell-rack-table
              v-if="selectedRackId"
              :wine-search="resultSearch"
              :rackId="selectedRackId"
              :rackName="selectedRackName"
              :data-rack="racksData"
              :info="rackInfo"
          ></cell-rack-table>
        </v-col>
      </v-row>
    </v-container>
    <!-- ******************* DIALOG DELETE WAREHOUSE ******************** -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
        >Vuoi eliminare questo Magazzino?
        </v-card-title
        >
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
              @click="dialogDelete = false"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-normal text-capitalize btn-ls bg-transparent btn-outline-secondary py-3 px-6"
          >Cancel
          </v-btn
          >
          <v-btn
              @click="deleteItemConfirm"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-normal text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
          >OK
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import addWareHouse from "@/views/Pages/WareHouse/AddWareHouse";
import warehouseService from "@/services/warehouse.service";
import RackComponent from "@/views/Pages/WareHouse/RackComponent";
import SearchInput from "@/views/Pages/WareHouse/components/SearchInput.vue";
import StatsCard from "@/views/Pages/WareHouse/components/StatsCard.vue";
import StatsCardTotal from "@/views/Pages/WareHouse/components/StatsCardTotal.vue";
import WarehouseService from "@/services/warehouse.service";
import CellRackTable from "@/views/Pages/WareHouse/CellRackTable.vue";

export default {
  name: "ListWareHouse",
  components: {
    addWareHouse,
    CellRackTable,
    RackComponent,
    SearchInput,
    StatsCard,
    StatsCardTotal
  },
  data() {
    return {
      warehouseId: null,
      rack: [],
      headerRack: [
        {text: "ID", value: "id"},
        {text: "wine", value: "wine"},
        {text: "quantità", value: "quantity"},

      ],
      headersWarehouse: [
        {
          text: "Name",
          align: "auto",
          cellClass: "border-bottom",
          sortable: false,
          value: "name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        // {
        //   text: "Principale",
        //   align: "start",
        //   cellClass: "border-bottom",
        //   sortable: false,
        //   value: "principal",
        //   class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        // },
        {
          text: "Actions",
          align: "end",
          cellClass: "border-bottom",
          sortable: false,
          value: "actions",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
      ],
      editedItem: {
        id: 0,
        name: '',
        principal: null
      },
      defaultWarehouseItem: {
        id: 0,
        quantity: ''
      },
      defaultItem: {
        id: 0,
        name: '',
      },
      editedIndex: -1,
      showAddWarehouse: false,
      selected: [],
      selectedWarehouse: {},
      columns: 0,
      rowsTable: 0,
      nameRack: '',
      rackObject: null,
      wh: null,
      itemRack: null,
      showCellRackTable: false,
      resultSearch: null,
      showResultSearch: false,
      resultsSearchItem: null,
      warehouses: null,
      dialogDelete: false,
      warehousesLoading: false,
      warehousesData: [],
      racksLoading: false,
      racksData: [],
      showStats: false,
      Magazzini: [],
      statsValue: [],
      statsValueTotal: [],
      selectedRackId: "",
      selectedRackName: "",
      rackInfo: {
        total_bottles: 0,
        total_type: 0
      },
      structure: []
    }
  },
  computed: {
    returnWarehouse() {
      return this.warehouseItems.map(items => items.warehouse)
    },
    returnRack() {
      return this.warehouseItems.map(items => items.rack)
    },
    storeLogged() {
      return this.$store.state.store.auth.user
    }
  },
  mounted() {
    this.initWarehouse()
  },
  methods: {
    rowSelect(idx) {
      console.dir(idx)
      this.selectedRow = idx;
    },
    isSelected(item) {
      return this.selected.includes(item.id); // o qualsiasi logica usi per determinare se l'elemento è selezionato
    },
    // Init
    initWarehouse() {
      this.fetchWarehouses()
    },
    getPageNumber(val) {
      this.page = val;
      this.getWarehouseItem()
    },
    // Warehouse
    editItem(item) {
      this.editedIndex = this.warehouses = item;
      this.editedItem = Object.assign({}, item);
    },
    closeEdit() {
      this.editedItem.id = 0
    },
    saveEdit() {
      warehouseService.editWarehouse(this.editedItem).then(() => {
        this.editedItem = this.defaultItem
        this.editedItem.id = 0
        this.initWarehouse()
      }).catch()
    },
    // editWarehouse(item) {
    //   console.table(item)
    // },
    deleteItem(item) {
      this.dialogDelete = true
      this.warehouseId = item.id
    },
    deleteItemConfirm() {
      warehouseService.deleteWarehouse(this.warehouseId)
          .then(resp => {
            this.$dialog.message.success("Rack eliminato correttamente", {
              type: "success",
              rounded: true,
              position: "top-right",
              color: "green",
              outlined: false,
              timeout: 1500,
            });
            console.log(resp.data)
          })
          .catch(error => {
            this.$dialog.message.error("Rack eliminato correttamente", {
              type: "error",
              rounded: true,
              position: "top-right",
              color: "red",
              outlined: false,
              timeout: 1500,
            });
            console.log(error)
          })
          .finally(() => {
            this.dialogDelete = false
            this.fetchWarehouses()
          })
    },
    // Rack
    passRack(rack) {
      console.log('Rack: ', rack)
      this.rackInfo.total_bottles = rack.total_bottle
      this.rackInfo.total_type = rack.type_of_wine

      this.selectedRackId = rack.id
      this.selectedRackName = rack.name

    },
    closeDialog() {
      this.refreshRack = true
      this.refreshRack = false
      this.initWarehouse()
    },
    showRack(item, row) {
      row.select(true)
      this.selectedWarehouse = item
      this.warehouseId = this.selectedWarehouse.id
      this.fetchStats(item.id)
    },
    openShowRack() {
      this.showCellRackTable = true
    },
    setNameWarehouse(item) {
      this.wh = item
    },
    async selectedRow({item}) {
      this.loading = true
      this.racksLoading = true
      this.racksData = []
      this.showCellRackTable = false
      this.warehouseId = item.id
      await this.fetchWarehouses()
      await this.fetchRacks()
    },
    async fetchWarehouses() {
      this.warehousesLoading = true;
      this.warehousesData = [];
      const params = {
        store: this.$store.state.auth.account.store[0],
        search: this.resultSearch
      }
      try {
        const resp = await warehouseService.getMagazzini(params)
        this.warehousesData = resp.data.results
        await this.fetchStats(this.warehousesData[0].id)
      } catch (error) {
        console.log(error)
      } finally {
        this.warehousesLoading = false;
        this.loading = false
      }
    },
    async fetchRacks() {
      if (!this.warehouseId) return;
      this.racksLoading = true;
      this.racksData = [];
      const params = {
        warehouse: this.warehouseId,
        search: this.resultSearch
      };
      try {
        const resp = await warehouseService.getRacks(params)
        this.racksData = resp.data.results;
      } catch (error) {
        console.log(error);
      } finally {
        this.racksLoading = false;
      }
    },
    async fetchStats(id) {
      console.log('fetchStats dell id: ', id)
      const params = {
        store: this.$store.state.auth.account.store[0]
      }
      const resp = await warehouseService.getWarehouseAnalytics(id, params)
      this.statsValue = resp.data.results
      this.showStats = true
    },
    passResultSearch(data) {
      this.showResultSearch = true
      this.resultSearch = data
      if (this.resultSearch.length >= 3 || this.resultSearch.length === 0) {
        this.selectedRackId = ""
        this.fetchWarehouses()
        this.fetchRacks()
      }
    },
    createSnapshot() {
      this.racksLoading = true;
      const params = {
        store: this.$store.state.auth.account.store[0],
      }
      WarehouseService.createSnapshot(params).then(resp => {
        console.log(resp.data)
        this.$dialog.message.success("Snapshot creato correttamente", {
          type: "success",
          rounded: true,
          position: "top-right",
          color: "green",
          outlined: false,
          timeout: 1500,
        });
      }).catch(error => {
        console.log(error.data)
      }).finally(() => {
        this.racksLoading = false
      })
    }
  }
}
;
</script>

<style scoped>
tr.v-data-table__selected {
  background-color: #d4def5 !important;
}

tr {
  cursor: pointer
}

.custom-highlight-row {
  background-color: #ea0c3f
}

.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none;
}
</style>
