<template>
  <v-dialog
      v-model="dialog"
      max-width="800px"
      v-if="itemOrder"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn text v-bind="attrs" v-on="on" class="text-color-light">
        <v-icon size="14" color="indigo" class="material-icons-round mr-2">edit</v-icon>
        Modifica
      </v-btn>
    </template>
    <v-card class="card-shadow border-radius-xl">
      <div class="card-header-padding card-border-bottom">
        <span class="font-weight-bold text-h5 text-typo mb-0">Modifica Ordine</span>
      </div>

      <v-card-text class="card-padding">
        <v-card
            class="pl-5 pr-5 mt-5 ml-5 mr-5"
            outlined
            style="background-color: #ebebeb"
        >
          <v-row>
            <v-col>
              <v-autocomplete
                  disabled
                  v-model="ricerca"
                  :search-input.sync="search"
                  :items="risultatiRicerca"
                  item-value="id"
                  :item-text="getName"
                  return-object
                  label="Ricerca per Produttore e/o Distributore"
                  clearable
                  no-data-text="Not data found"
              >
                <template v-slot:append>
                  <v-progress-circular
                      v-if="loading"
                      indeterminate
                      size="20"
                      color="primary"
                  ></v-progress-circular>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card>
        <v-card
            v-if="ricerca"
            class="pl-5 pr-5 mt-5 ml-5 mr-5"
            outlined
            style="background-color: #ebebeb"
        >
          <v-card-text>
            nome Vino: <b>generale</b>
            <br>
            nome Produttore:
            <b v-if="ricerca.is_direct">
              <span>{{ ricerca.winemaker_name }}</span>
            </b>
            <b v-else>{{ ricerca.distributor_name }}</b><br>
            <span v-if="!ricerca.is_direct">nome Distributore:</span>
            <b v-if="!ricerca.is_direct">{{ ricerca.distributor_name }}</b><br>
            è diretto: {{ ricerca.is_direct }}
          </v-card-text>
        </v-card>
        <v-container class="px-0">
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="editOrder.custom_id" label="Titolo Ordine*"></v-text-field>
              <v-text-field v-model="editOrder.total_bottle_order" label="Totale Bottiglie Ordinate"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                  label="Stato Ordine"
                  v-model="editOrder.status"
                  :items="statusOrder"
                  item-value="key"
                  item-text="key"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="card-padding d-flex justify-end">
        <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-normal text-capitalize btn-ls btn-outline-secondary bg-transparent py-3 px-6"
            @click="dialog = false"
        >Cancella
        </v-btn>
        <v-btn
            @click="update"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-normal text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
        >Salva
        </v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import OrdineService from "@/services/ordine.service";
import winemakerService from "@/services/winemaker.service";

export default {
  name: 'EditOrder',
  props: ['itemOrder'],
  data() {
    return {
      dialog: false,
      model: "",
      search: "",
      ricerca: '',
      loading: false,
      ricercaVino: null,
      ricercaDistributore: null,
      ricercaProduttore: null,
      risultatiRicerca: [],
      risultatiDistributori: [],
      risultatiProduttori: [],
      editOrderItem: {},
      composeNewOrder: {
        wineId: '',
        distributorId: '',
        winemakerId: '',
        custom_id: ""
      },
      editOrder: {},
      statusOrder: [
        {key: "Sent via Gest"},
        {key: "Sent to supplier"},
        {key: "In Progress"},
        {key: "Delivered"},
        {key: "Done"},
      ]
    }
  },
  methods: {
    getName(item) {
      if (item) {
        return item.is_direct ? item.winemaker_name : item.distributor_name;
      }
      return ""; // Restituisci una stringa vuota se l'oggetto è nullo
    },
    searchDistributori(valore) {
      const params = {
        search: valore,
      }
      winemakerService.getDistributori(params)
          .then(resp => {
            this.risultatiDistributori = resp.data.results
          })
    },
    update() {
      const payload = {
        id: this.editOrder.id,
        store: this.editOrder.store,
        custom_id: this.editOrder.custom_id,
        status: this.editOrder.status
      }
      OrdineService.editOrderFromOrder(payload)
          .then(() => {
            this.model = ""
            this.composeNewOrder = {
              wineId: '',
              distributorId: '',
              winemakerId: '',
              customId: ""
            }
            this.$emit('refreshOrder')
            this.$dialog.message.success("Ordine aggiunto correttamente", {
              rounded: true,
              position: "top-right",
              color: "green",
              outlined: false,
              timeout: 1000,
            })
            this.dialog = false
          }).catch(() => {
        this.dialog.message.error('Non sono riuscito a salvare il tuo ordine', {
          rounded: true,
          position: "top-right",
          color: "red",
          outlined: false,
          timeout: 1000,
        })
      });

    }
  },
  watch: {
    dialog() {
      this.editOrder = this.itemOrder
    },
    search(value) {
      if (this.search !== null && this.search.length > 3) {
        this.loading = true
        if (value !== null) {
          const params = {
            q: value,
          }
          OrdineService.by_winemaker_name_or_distributor_name(params)
              .then(resp => {
                this.risultatiRicerca = resp.data
                this.loading = false
                //this.risultatiRicerca = Array.from(new Set(resp.data.results.map(item => item['winemaker_name'])));

              })
              .catch()
        }
      }
    },
  },
  computed: {
    getItem() {
      return this.itemOrder
    }
  }
}
</script>

<style scoped></style>
