<template>
  <v-container fluid class="px-6 py-6">
    <v-row class="mt-0">
      <v-col cols="12">
        <v-card>
          <v-card-title>Ricorda di Ordinare:</v-card-title>
          <v-card-text>
            <v-data-table
                :headers="headers"
                :items="bottles"
                fixed-header
                dense
                loading
                loading-text="Caricamento pagina in corso..."
            >
              <template v-slot:top>
                <v-toolbar
                    flat
                >
                  <v-toolbar-title>
                    Bottiglie da ordinare: {{ count }}
                  </v-toolbar-title>
                </v-toolbar>
              </template>
              <template v-slot:[`item.soldout`]="{ item }">
                <div class="d-flex ml-4">
              <span>
                <v-icon style="font-size:1.7em" v-if="item.soldout"
                        color="red lighten-2">mdi-bottle-wine-outline</v-icon>
                <v-icon style="font-size:1.7em" v-else color="green lighten-2">mdi-bottle-wine</v-icon>
              </span>
                </div>
              </template>
              <template v-slot:[`item.quantity`]="{ item }">
                <div class="d-flex align-center ms-2">
              <span class="text-sm font-weight-normal text-body">
                <v-chip small outlined v-if="item.quantity.quantity_tot >= 5" color="green"
                        class="d-flex justify-content-center">{{ item.quantity.quantity_tot }}</v-chip>
                <v-chip small outlined v-else-if="item.quantity.quantity_tot === 0" color="red"
                        class="d-flex justify-content-center">{{ item.quantity.quantity_tot }}</v-chip>
                <v-chip small outlined v-else-if="
                  (item.quantity.quantity_tot < 5) & (item.quantity.quantity_tot > 1)
                " color="light" class="d-flex justify-content-center">{{ item.quantity.quantity_tot }}</v-chip>
                <v-chip small outlined v-else-if="item.quantity.quantity_tot === null" color="red"
                        class="d-flex justify-content-center">0</v-chip>
              </span>
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        @click="rememberTo(item)"
                        icon elevation="0"
                        :ripple="false"
                        height="28"
                        min-width="36"
                        width="36"
                        v-bind="attrs"
                        v-on="on"
                        class="btn-ls me-2 my-2 rounded-sm" color="#67748e">
                      <v-icon size="14" class="material-icons-round"
                              :class="{'text-green': item.remember_to, 'text-gray': !item.remember_to}">
                        mdi-check-decagram-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Rimuovi dalla Lista</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import listinoService from "@/services/listino.service";

export default {
  name: "RicordaOrdini",
  components: {},
  data() {
    return {
      bottles: [],
      count: 0,
      headers: [
        {
          text: "Nome",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "wine_name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Annata",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "wine_vintage",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Produttore",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "winemaker_name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Tipo",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "wine_type",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Acquisto",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "price_reduce",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Sconto",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "percentage_value",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Listino",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "price_cost",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Asporto",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "price_sell",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Calice",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "price_wine_glass",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Esaurito",
          value: "soldout",
          sortable: true,
          cellClass: "border-bottom",
          class: "text-secondary font-weight-bolder opacity-7 ps-6",
        },
        {
          text: "Quantità",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "quantity",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Actions",
          value: "actions",
          cellClass: "border-bottom",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
    }
  },
  mounted() {
    this.getBottles()
  },
  methods: {
    getBottles() {
      const params = {
        by_remember_to: true
      }
      listinoService.getListiniItems(params)
          .then(resp => {
            this.count = resp.data.count
            this.bottles = resp.data.results
          })
    },
    rememberTo(item) {
      const params = {
        remember_to: !item.remember_to
      }
      listinoService.editListinoItem(item.id, params).then(() => {
        this.snackbarRemember = true
        this.$dialog.message.success("Aggiunto/Rimosso", {
          rounded: true,
          position: "top-right",
          color: "green",
          outlined: false,
          timeout: 3000,
        })
        this.getBottles();
      });
    },
  }
};
</script>
