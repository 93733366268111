<template>
  <v-card class="card-shadow">
    <v-card-title
        class="card-border-bottom"
        style="background-color: #9e09cc">
          <span class="font-weight-bold text-white text-h5 mb-0">
            Modifica Vino
          </span>
    </v-card-title>
    <v-card-text class="card-padding">
      <v-container class="px-0">
        <v-row>
          <!-- *********** NOME VINO *********** -->
          <v-col cols="12">
            <v-text-field
                label="Nome"
                v-model="dialogItem.name"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                color="pink"
                flat
                height="43"
            ></v-text-field>
          </v-col>
          <!-- *********** VINTAGE *********** -->
          <v-col cols="6">
            <v-text-field
                label="Annata"
                v-model="dialogItem.vintage"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                color="pink"
                flat
                height="43"
            ></v-text-field>
          </v-col>
          <!-- *********** TYPE GRAPES *********** -->
          <v-col cols="6">
            <v-autocomplete
                v-model="dialogItem.type_grapes"
                :items="tipiUva"
                :menu-props="{ bottom: true, offsetY: true }"
                item-text="name"
                item-value="id"
                :search-input.sync="filter.type_grapes_dialog"
                label="Uva"
                class="mx-4"
                flat
                chips
                deletable-chips
                multiple
            >
              <template v-slot:selection="{item}">
                <v-chip
                    color="pink"
                    text-color="white"
                >{{ item.name }}
                </v-chip>
              </template>
            </v-autocomplete>

          </v-col>
          <!-- *********** GRADAZIONE *********** -->
          <v-col cols="6">
            <v-text-field
                label="Gradazione"
                v-model="dialogItem.gradation"
                hide-details
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                color="pink"
                flat
                height="43"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-checkbox
                label="Magnum"
                v-model="dialogItem.magnum"
                :value="dialogItem.magnumCheck"
                color="pink"
                hide-details
            ></v-checkbox>
          </v-col>
          <v-col cols="6">
            <v-select
                label="Tipo"
                v-model="dialogItem.type"
                :items="tipi"
                :menu-props="{ top: false, offsetY: true }"
                item-text="name"
                item-value="id"
                :search-input.sync="search"
                flat
                dense
                color="pink"
                chips
                item-color="pink"
                required
            >
              <template v-slot:selection="{item}">
                <v-chip
                    color="pink"
                    text-color="white"
                >{{ item.name }}
                </v-chip>
              </template>
            </v-select>
          </v-col>
          <!-- *********** PRODUTTORI *********** -->
          <v-col cols="6">
            <v-autocomplete
                v-model="dialogItem.winemaker"
                :items="produttori"
                :menu-props="{ top: false, offsetY: true }"
                item-text="name"
                item-value="id"
                :search-input.sync="cercaProduttore"
                label="Produttore"
                cache-items
                class="mx-6"
                dense
                color="pink"
                flat
                hide-no-data
                hide-details
            >
              <template v-slot:append-outer>
                <v-icon
                    style="font-size: 25px"
                    color="orange"
                    @click="addButton"
                >add_circle
                </v-icon
                >
              </template>
            </v-autocomplete>
            <v-alert
                v-if="selezionaProduttore"
                dense
                color="pink"
                outlined
                shaped
                text
                type="error"
            >Prima seleziona un produttore
            </v-alert
            >
          </v-col>
          <!-- *********** BARCODE *********** -->
          <v-col cols="6">

          </v-col>
          <!-- *********** MAGNUM *********** -->
          <v-col cols="6">
          </v-col>
          <!-- *********** NOTE *********** -->
          <v-col cols="12">
            <v-textarea
                label="Vinificazione"
                v-model="dialogItem.vinification"
                color="pink"
                class="input-style font-size-input text-light-input placeholder-light input-icon"
            ></v-textarea>
            <v-textarea
                v-model="dialogItem.note"
                item-text="note"
                color="pink"
                label="Note"
            >
            </v-textarea>
            <!--              <v-file-input-->
            <!--                  label="Carica Immagine"-->
            <!--                  ref="image"-->
            <!--                  v-model="label"-->
            <!--                  truncate-length="15"-->
            <!--                  @change="selectImage"-->
            <!--              ></v-file-input>-->
            <!--              <v-progress-linear-->
            <!--                  v-if="label"-->
            <!--                  height="15"-->
            <!--                  color="indigo"-->
            <!--                  :value="valueUpload"-->
            <!--              >-->
            <!--                <strong>{{ valueUpload }}%</strong>-->
            <!--              </v-progress-linear>-->
            <!--              <v-btn v-if="label" @click="uploadImage"-->
            <!--              >Salva Immagine-->
            <!--              </v-btn-->
            <!--              >-->
            <!--            <br/>-->
            <!--              <div v-if="dialogItem.images.length > 0">-->
            <!--                <div-->
            <!--                    v-for="(image, index) in dialogItem.images"-->
            <!--                    :key="index"-->
            <!--                    class="d-inline-flex"-->
            <!--                >-->
            <!--                  <v-hover v-slot="{ hover }">-->
            <!--                    &lt;!&ndash;                                <v-img&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                    :src=`${api}${image.image}`&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                    max-width="400"&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                >&ndash;&gt;-->
            <!--                    <v-img-->
            <!--                        :src="image.image"-->
            <!--                        max-width="100"-->
            <!--                    >-->
            <!--                      <v-expand-transition>-->
            <!--                        <div-->
            <!--                            v-if="hover"-->
            <!--                            class="d-flex transition-fast-in-fast-out v-card&#45;&#45;reveal text-h2 white&#45;&#45;text"-->
            <!--                            style="height: 100%"-->
            <!--                        >-->
            <!--                          <v-btn-->
            <!--                              dense
            color=pink-->
            <!--                              color="pink"-->
            <!--                              dark-->
            <!--                              @click="deleteImage(image.id)"-->
            <!--                          >Cancella-->
            <!--                          </v-btn-->
            <!--                          >-->
            <!--                        </div>-->
            <!--                      </v-expand-transition>-->
            <!--                    </v-img>-->
            <!--                  </v-hover>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <v-btn-->
            <!--                  v-if="dialogItem.label"-->
            <!--                  color="red"-->
            <!--                  dark-->
            <!--                  small-->
            <!--                  @click="deleteImage"-->
            <!--              >Elimina-->
            <!--              </v-btn-->
            <!--              >-->
            <!--              <v-alert-->
            <!--                  v-if="showAlertSuccess"-->
            <!--                  border="left"-->
            <!--                  colored-border-->
            <!--                  color="green"-->
            <!--                  elevation="2"-->
            <!--              >Etichetta {{ messaggio }} Con Successo-->
            <!--              </v-alert-->
            <!--              >-->
            <v-btn color="pink" dark @click="getEtichetta(dialogItem)">Recupera Etichetta</v-btn>

            <v-row>
              <v-col
                  v-for="(immagine, index) in immagini"
                  :key="index"
                  class="d-flex child-flex"
                  cols="4"
              >
                      <v-card class="image-card" @click="toggleSelection(index)">
        <v-img
          :src="immagine"
          aspect-ratio="1"
          class="grey lighten-2"
        >
          <!-- Placeholder per l'immagine -->
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
          </template>

          <!-- Overlay con icona di spunta quando l'immagine è selezionata -->
          <v-overlay :value="selectedImages.includes(index)" absolute>
            <v-icon color="green" size="56">mdi-check-circle</v-icon>
          </v-overlay>
        </v-img>
      </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn class="float-end" color="pink" dark @click="saveImages" v-if="selectedImages.length > 0">Salva Immagini</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions
        class="card-padding d-flex justify-end"
        style="background-color: #eceff1"
    >
      <v-btn
          @click="close"
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-normal text-capitalize btn-ls btn-outline-secondary bg-transparent py-3 px-6"
      >Cancella
      </v-btn
      >
      <v-btn
          @click="update"
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-normal text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
      >Modifica
      </v-btn
      >
      <v-dialog
          v-if="showProduttore"
          v-model="showProduttore"
          content-class="position-absolute top-0"
          width="95%"
      >
        <add-produttore
            @close="showProduttore = false"
            @refresh="getProduttori"
        ></add-produttore>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>
<script>
import typeAndtypegrapesService from "@/services/typeAndtypegrapes.service";
import winemakerService from "@/services/winemaker.service";
import wineServices from "@/services/wine.services";
import AddProduttore from "@/views/Pages/Winemaker/AddProduttore.vue";

export default {
  props: {
    item: Object
  },
  components: {
    AddProduttore,
  },
  data() {
    return {
      dialog: false,
      dialogItem: {},
      immagini: [],
      selectedImages: [],
      // Produttori
      cercaProduttore: "",
      produttori: [],
      selezionaProduttore: "",
      showProduttore: false,
      search: "",
      tipi: [],
      tipiUva: [],
      filter: {
        type_grapes: "",
        type: ""
      }
    }
  },
  mounted() {
    this.dialogItem = this.item
    this.getTipi()
    this.getTipiUva()
    this.getProduttori()
  },
  watch: {
    "filter.type_grapes"(value) {
      console.log(value)
    },
    "filter.type_grapes_dialog"(value) {
      console.log(value)
    },
    item(val) {
      this.dialogItem = val
    }
  },
  methods: {
    // Actions
    addButton() {
      this.showProduttore = true
    },
    close() {
      this.immagini = []
      this.dialogItem = {}
      this.$emit('refresh')
      this.$emit('close')
    },
    update() {
      const payload = {
        id: this.dialogItem.id,
        name: this.dialogItem.name,
        version: this.dialogItem.version,
        type: this.dialogItem.type,
        gradation: this.dialogItem.gradation,
        vinification: this.dialogItem.vinification,
        vintage: this.dialogItem.vintage,
        magnum: this.dialogItem.magnum,
        type_grapes: this.dialogItem.type_grapes,
        winemaker: this.dialogItem.winemaker,
        distributor: this.dialogItem.distributor,
        note: this.dialogItem.note,
      };
      wineServices.editVino(payload).then(() => {
        this.immagini = []
        this.selectedImages = []
        this.$dialog.message.success('Vino modificato con successo!', {
          position: "top-right",
          color: 'green'
        })
      }).catch(() => {
        this.$dialog.message.error('Errore nella creazione del vino', {
          color: 'red'
        })
      }).finally(() => {
        this.dialogItem = {}
        this.$emit('refresh')
        this.close()
      })
    },
    // Get
    getTipi() {
      typeAndtypegrapesService.getTipi().then((resp) => {
        this.tipi = resp.data.results;
      });
    },
    getTipiUva() {
      typeAndtypegrapesService.getTipiUva().then((resp) => {
        this.tipiUva = resp.data.results;
      });
    },
    getProduttori() {
      const params = {
        select: "",
        page_size: 2000,
      };
      winemakerService.getProduttori(params).then((resp) => {
        this.produttori = resp.data.results;
      });
    },
    getEtichetta(item) {
      const params = {
        pk: item.id
      }
      wineServices.getImage(params).then(resp => {
        this.immagini = resp.data.urls
        console.log(resp.data)
      }).catch().finally()
      console.log(item)
    },
    toggleSelection(index) {
      const selectedIndex = this.selectedImages.indexOf(index);
      if (selectedIndex >= 0) {
        this.selectedImages.splice(selectedIndex, 1);
      } else {
        this.selectedImages.push(index);
      }
    },
    saveImages() {
      console.log("IMMAGINI SELEZIONATE: ", this.selectedImages)
      const selectedImagesToProcess = this.selectedImages.map(index => this.immagini[index])
      selectedImagesToProcess.forEach(img => {
        const payload = {
          wine_id: this.dialogItem.id,
          image_url: img
        }
        wineServices.saveImage(payload).then(() => {
          this.$dialog.message.success('Vino modificato con successo!', {})
        }).catch(() =>
            this.$dialog.message.error('Errore nella creazione del image', {})
        )
      })
    }
  }
}

</script>

<style scoped>

</style>