<template>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-0">
        <v-col cols="12">
          <datatable-ordini
              ref="datatableOrdini"
              @showDataOrderItems="showOrderItems"
              @getOrderItemsFromOrderId="getOrderItems"
              @getDistributoreOrProduttore="passDistriOrProd"
              @passOrderName="getOrderName"
          ></datatable-ordini>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <datatable-ordini-items
              v-if="showItems"
              :idOrdine="getOrderItemsFromOrderId"
              :nomeOrdine="orderName"
              :getDistriOrProd="getDistributoreOrProduttore"
              @itemChanged="refreshAll"
          ></datatable-ordini-items>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>

import DatatableOrdini from "@/views/Pages/Ordini/DatatableOrdini.vue";
import DatatableOrdiniItems from "@/views/Pages/Ordini/DatatableOrdiniItems.vue";

export default {
  name: "OrdiniView",
  components: {
    DatatableOrdiniItems,
    DatatableOrdini,

  },
  data() {
    return {
      showItems: false,
      getOrderItemsFromOrderId: '',
      getDistributoreOrProduttore: {},
      orderName: ''
    }
  },
  methods: {
    showOrderItems() {
      this.showItems = true
    },
    getOrderItems(id) {
      this.getOrderItemsFromOrderId = id
    },
    passDistriOrProd(obj) {
      this.getDistributoreOrProduttore = obj
    },
    getOrderName (value) {
      this.orderName = value
    },
    refreshAll () {
      this.$refs.datatableOrdini.initAll()
    }
  }
};
</script>
