<template>
    <v-card elevation="0">
      <v-data-table
          :headers="headersRack"
          :items="racksData"
          single-select
          hide-default-footer
          :loading="loadingRack"
          show-select
          loading-text="Loading... Please wait"
          @click:row="getDetails"
      >
        <template v-slot:top>
          <v-toolbar
              flat
          >
            <v-toolbar-title class="font-weight-bold text-h4 text-typo mb-0">
              Lista dei Rack: {{ racksData.length }}
            </v-toolbar-title>
            <v-divider
                class="mx-4"
                inset
                vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <add-rack :ware-house="wareHouse" @refreshRack="initRacks"></add-rack>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <edit-rack :rackId="selectedRackId" @reloadRack="initRacks"></edit-rack>
          <print-barcode :warehouse="wh" :rackName="selectedRackName" :value="item"></print-barcode>
          <print-cell-barcode
              :warehouse="wh"
              :rackName="selectedRackName"
              :idRack="selectedRackId"
          ></print-cell-barcode>
          <v-btn
              icon
              elevation="0"
              :ripple="false"
              height="28"
              min-width="36"
              width="36"
              color="red"
              @click="openDeleteRack(item)"
          >
            <v-icon size="14" class="material-icons-round">close</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <delete-item
          :show="showDelete"
          :item="itemRack"
          :item-tipo="itemTipo"
          @closeDelete="openDeleteRack"
          @confirmDelete="deleteRack"
      ></delete-item>
    </v-card>
</template>

<script>
import warehouseService from "@/services/warehouse.service";
import DeleteItem from "@/views/Components/DeleteItem";
import addRack from "@/views/Pages/WareHouse/AddRack";
import editRack from "@/views/Pages/WareHouse/EditRack.vue";
import PrintBarcode from "./components/PrintBarcode.vue";
import PrintCellBarcode from "@/views/Pages/WareHouse/components/PrintCellBarcode.vue";

export default {
  name: "RackComponent",
  components: {
    DeleteItem,
    addRack,
    editRack,
    PrintBarcode,
    PrintCellBarcode
  },
  props: {
    loading: Boolean,
    racks: Array,
    warehouse: Object,
    refresh: Boolean,
    externalSearchParam: String
  },
  data() {
    return {
      // WareHouse
      expanded: [],
      wareHouse: null,
      racksData: [],
      rack: "",
      headersRack: [
        {
          text: "Nome",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "ID",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "id",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Colonne",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "columns",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Scaffali",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "rows",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Actions",
          align: "end",
          cellClass: "border-bottom",
          sortable: false,
          value: "actions",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
      ],
      // Dialog Delete
      showDelete: false,
      itemRack: null,
      itemTipo: 'Rack',
      // Dialog Add Rack

      showAdd: false,
      // Dialog General
      selected: false,
      // Loading
      loadingRack: false,
      selectedRow: null,
      selectedRackId: 0,
      selectedRackName: '',
      showCellRack: false,
      wh: '',

    }
  },
  watch: {
    warehouse: {
      immediate: true,
      handler(value) {
        if (value) {
          this.wareHouse = this.warehouse
          this.initRacks()
        }
      }
    },
    // searchItem(value) {
    //     if (value != null) {
    //         console.log('Sono nel rack:', value)
    //     }
    // },
    racks(value) {
      this.racksData = value
    },
    loading(value) {
      this.loadingRack = value
    }
  },
  mounted() {
  },
  methods: {
    initRacks() {
      this.$emit('initRack')
    },
    getDetails(rack, row) {
      this.loadingRack = true
      row.select(true)
      this.selectedRackId = rack.id
      this.selectedRackName = rack.name
      this.$emit('rack', rack)
    },
    resetDialogRack() {
      this.showAdd = false
    },
    // addRack() {
    //   this.showAdd = true
    // },
    // editRack(item) {
    //   log
    // },
    openDeleteRack(item) {
      this.itemRack = item
      this.showDelete = !this.showDelete

    },
    deleteRack(id) {
      warehouseService.deleteRack(id).then(() => {
        this.$dialog.message.success("Rack eliminato correttamente", {
          type: "success",
          rounded: true,
          position: "top-right",
          color: "green",
          outlined: false,
          timeout: 1500,
        });
      }).catch().finally(() => {
        this.$emit('initRack')
      })
    }
  }
};
</script>

<style>
tr.v-data-table__selected {
  background-color: #d4def5 !important;
}
tr {
  cursor: pointer
}
.custom-highlight-row {
  background-color: #ea0c3f
}
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none;
}
</style>
