<template>
  <v-dialog
      v-model="moveDialog"
      transition="dialog-bottom-transition"
      max-width="600"
  >
    <template v-slot:activator="{on, attrs}">
      <v-icon
          v-bind="attrs"
          v-on="on"
          class="cursor-pointer mr-2"
          small
          color="primary">mdi-human-dolly
      </v-icon>
    </template>
    <template v-slot:default="">
      <v-card class="">
        <v-toolbar
            color="pink"
            dark
        >Bottiglia: {{ warehouseItem.wine_name }}
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                Dove vuoi spostare la bottiglia:
                <b class="text-pink">{{ warehouseItem.wine_name }}</b>?<br>
                adesso si trova: <br>
                Magazzino: <b class="text-pink">{{ warehouseItem.warehouse }}</b>
                Rack: <b class="text-pink">{{ warehouseItem.rack }}</b>
                Cella: <b class="text-pink">{{ warehouseItem.cell_name }}</b>
              </v-col>
            </v-row>
            <v-row v-if="move">
              <v-col>
                <v-select
                    v-model="magazzino"
                    @click="getWarehouse"
                    color="pink"
                    dense
                    :loading="loadingWarehouse"
                    :items="magazzini"
                    item-text="name"
                    item-value="id"
                ></v-select>
                <v-select
                    v-if="magazzino"
                    v-model="rack"
                    @click="getRack"
                    color="pink"
                    :loading="loadingRack"
                    :items="racks"
                    dense
                    item-text="name"
                    item-value="id"
                ></v-select>
                <v-select
                    v-model="cell"
                    v-if="rack"
                    @click="getCellRack"
                    :loading="loadingCell"
                    dense
                    :items="cell_racks"
                    color="pink"
                    item-text="name"
                    item-value="id"
                ></v-select>
                <v-text-field
                    v-if="cell"
                    dense
                    v-model="quantity"
                    color="pink"
                    label="Quantity"
                >
                      <span slot="append">
                         /{{ warehouseItem.quantity }}
                      </span>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              v-if="cell"
              color="grey"
              dark
              @click="moveDialog = false">
            Annulla
          </v-btn>
          <v-btn
              class="float-right"
              v-if="cell"
              color="green"
              dark
              @click="moveBottle"
          >Sposta
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import WarehouseService from "@/services/warehouse.service";

export default {
  name: "MoveWine",
  props: ['warehouseItem'],
  data() {
    return {
      moveDialog: false,
      // Move
      move: true,
      racks: [],
      rack: null,
      magazzini: [],
      magazzino: null,
      cell_racks: [],
      cell: null,
      wine_id: this.warehouseItem.wine,
      quantity: null,
      loadingWarehouse: false,
      loadingRack: false,
      loadingCell: false,
    }
  },
  mounted() {
    this.dialogReset()
  },
  methods: {
    getWarehouse() {
      this.loadingWarehouse = true
      const params = {
        store: this.$store.state.auth.account.store[0]
      }
      WarehouseService.getMagazzini(params)
          .then(resp => {
            this.magazzini = resp.data.results
          })
          .catch().finally(() => this.loadingWarehouse = false)
    },
    getRack() {
      this.loadingRack = true
      const params = {
        warehouse: this.magazzino
      }
      WarehouseService.getRacks(params)
          .then(resp => {
            this.racks = resp.data.results
          })
          .catch().finally(() => this.loadingRack = false)
    },
    getCellRack() {
      this.loadingCell = true
      const params = {
        rack: this.rack
      }
      WarehouseService.getCellRack(params)
          .then(resp => {
            this.cell_racks = resp.data.results
          })
          .catch().finally(() => this.loadingCell = false)
    },
    moveBottle() {
      console.log('warehouseItem: ', this.warehouseItem)
      const payload = {
        warehouse_item_id: this.warehouseItem.id,
        cell: this.cell,
        quantity: this.quantity,
        wine_id: this.warehouseItem.wine,
        p_list_item: this.warehouseItem.p_list_item
      }
      WarehouseService.moveWarehouseItem(payload)
          .then(() => {
            this.$dialog.message.success("Vino Spostato Correttamente", {
              rounded: true,
              position: "top-right",
              color: "green",
              outlined: false,
              timeout: 1000,
            })
            this.dialogReset()
            this.$emit('refresh')
            this.moveDialog = false
          })
          .catch(error => {
            this.$dialog.message.error(error.response.data.error, {
              rounded: true,
              position: "top-right",
              color: "red",
              outlined: false,
              timeout: 4000,
            })
          })
    },
    dialogReset() {
      this.warehouse_item_id = ''
      this.cell = ''
      this.quantity = ''
      this.wine_id = ''
    }
  }
}
</script>

<style scoped>

</style>