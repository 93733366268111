<template>
  <v-text-field
      v-model="search"
      @input="onInput"
      ref="autocompleteInput"
      hide-details
      class="input-style font-size-input text-light-input placeholder-light input-icon"
      outlined
      color="pink"
      flat
      height="53"
  ></v-text-field>
</template>

<script>
import { loadGoogleMapsApi } from '@/util/loadGoogleMapsApi';
export default {
  data() {
    return {
      search: '',
      autocomplete: null,
    };
  },
  mounted() {
    loadGoogleMapsApi(
        'AIzaSyAX5CDkFKyK9cfNQgdNUTGCmV1iNJkZ6Mk',
        'it',
        'IT'
    ).then(() => {
      this.setupAutocomplete();
    }).catch(error => {
      console.error(error);
    });
  },
  methods: {
    onInput() {
      // Logica da eseguire quando il testo cambia
    },
    setupAutocomplete() {
      const input = this.$refs.autocompleteInput.$el.querySelector('input');
      // eslint-disable-next-line no-undef
      const autocomplete = new google.maps.places.Autocomplete(input);

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        this.onPlaceChanged(place);
      });
    },
    onPlaceChanged(place) {
      this.$emit('place-selected', place);
    },
    setPlace(place) {
      this.place = place;
    },
  },
};
</script>


