<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-0">
        <v-col cols="6">
          <datatable-distributori
              @showDetailDistributore="showDistributore"
              @fornitoreID="assignId"
              @distributoreName="passName"
          ></datatable-distributori>
        </v-col>
        <v-col cols="6">
          <datatable-details-distributori
              :show-card="showDistributoreDetail"
              :grebWinemakers="winemakers"
              :fornitoreId="idDistributor"
              :distributoreNome="nomeDistributore"
          ></datatable-details-distributori>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import DatatableDistributori from "../../Applications/Widgets/DatatableDistributori.vue";
import DatatableDetailsDistributori from "@/views/Applications/Widgets/DatatableDetailsDistributori.vue";

export default {
  components: {
    DatatableDistributori,
    DatatableDetailsDistributori,
  },
  data() {
    return {
      showDistributoreDetail: false,
      winemakers: [],
      idDistributor: null,
      nomeDistributore:""
    }
  },
  methods: {
    showDistributore(val) {
      this.winemakers = val
      this.showDistributoreDetail = true
    },
    assignId(id) {
      this.idDistributor = id
    },
    passName (value) {
      this.nomeDistributore = value
    }
  }
};
</script>