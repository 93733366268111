<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card class="card-shadow card-padding border-radius-xl">
      <v-card-title v-if="item" class="pt-0 text-h5 text-typo justify-center"
      >Vuoi eliminare questo Rack: {{ item.name }}?</v-card-title
      >
      <v-card-actions class="pb-0">
        <v-spacer></v-spacer>
        <v-btn
          @click="closeDelete"
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-normal text-capitalize btn-ls bg-transparent btn-outline-secondary py-3 px-6"
        >Cancel</v-btn
        >
        <v-btn
          @click="deleteItemConfirm(item)"
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-normal text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
        >OK</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "DeleteItem",
  props: [ 'show','item', 'itemTipo' ],
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      messaggio: '',
      idItem: null,
      nameItem:''
    }
  },
  watch: {
    show (value) {
      this.dialog = value
      if (value) {
        this.messaggio = this.itemTipo
        this.dialogDelete = true
      }
    }
  },
  mounted() {
  },
  methods: {
    deleteItemConfirm(item) {
      this.$emit('confirmDelete', item.id)
      this.closeDelete();
    },
    closeDelete() {
      this.$emit('closeDelete')
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      // //   this.editedIndex = -1;
      // });
    },
  }
};
</script>

<style scoped>

</style>
