<template>
  <div>
    <v-overlay :value="progress">
      <v-progress-circular indeterminate :size="70" :width="7" color="purple">
      </v-progress-circular>
    </v-overlay>

    <v-card class="card-shadow border-radius-xl" elevation="5" v-if="prodName" v-show="showTable">
      <div class="card-header-padding">
        <div class="font-weight-bold text-h4 text-typo mb-0 inline-group">
          {{ prodName }}
          <v-chip class="ma-2" color="green" outlined>
            Totale Vini: {{ conteggioVini }}
          </v-chip>
          <v-btn class="float-end" color="indigo" icon @click="closeTable">
            <v-icon color="red darken-2">mdi-close</v-icon>
          </v-btn>
        </div>
        <div>
          <!-- ******************* DIALOG ƒASSOCIA VINO *******************-->
          <v-dialog v-model="dialogAssociaVino" max-width="800">
            <template v-slot:activator="{ on, attrs}">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="
                      font-weight-normal
                      text-capitalize
                      btn-primary
                      bg-gradient-primary
                      py-3
                      px-6
                      ms-3
                    "
              >Associa Vino
              </v-btn>
            </template>
            <v-card class="card-shadow">
              <v-card-title
                  class="card-border-bottom"
                  style="background-color: #eceff1"
              >
                    <span class="font-weight-bold text-h5 text-typo mb-0">
                      Associa Vino al Produttore <span class="text-pink">{{ prodName }}</span>
                    </span>
              </v-card-title>
              <v-card-text class="card-padding">
                <v-container class="px-0">
                  <v-row>
                    <!-- *********** NOME VINO *********** -->
                    <v-col cols="12">
                      <v-autocomplete
                          v-model="risultatoVino"
                          :items="viniAssociaVino"
                          :menu-props="{ top: false, offsetY: true }"
                          item-text="name"
                          item-value="id"
                          :search-input.sync="cercaVino"
                          label="Vino"
                          cache-items
                          class="mx-4"
                          flat
                          hide-no-data
                          hide-details
                      ></v-autocomplete>
                    </v-col>
                    <!-- *********** WINEMAKER *********** v-on:change="payloadTypeGrapes"-->
                    <v-autocomplete
                        v-model="defaultItem.winemakerId"
                        :items="produttori"
                        :menu-props="{ top: true, offsetY: true }"
                        item-text="name"
                        item-value="id"
                        :search-input.sync="cercaProduttore"
                        label="Produttore"
                        disabled
                        cache-items
                        class="mx-4"
                        flat
                        hide-no-data
                        hide-details
                    ></v-autocomplete>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions
                  class="card-padding d-flex justify-end"
                  style="background-color: #eceff1"
              >
                <v-btn
                    @click="close"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-outline-secondary
                        bg-transparent
                        py-3
                        px-6
                      "
                >Cancella
                </v-btn
                >
                <v-btn
                    @click="saveAssocia"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-primary
                        bg-gradient-primary
                        py-3
                        px-6
                      "
                >Salva
                </v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <add-wine-from-winemaker
              :winemaker="parseInt(defaultItem.winemakerId)"
              @refreshWine="getVini"
          ></add-wine-from-winemaker>
        </div>
      </div>
      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="vini"
            :search="search"
            fixed-header
            dense
            loading
            loading-text="Caricamento pagina in corso..."
            :page.sync="page"
            hide-default-footer
            @page-count="pageCount = $event"
            :items-per-page="itemsPerPage"
            mobile-breakpoint="0"
        >
          <template v-slot:top="{}">
            <v-toolbar flat>
              <v-row>
                <v-col cols="12" md="5">
                  <v-text-field
                      hide-details
                      class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    "
                      flat
                      filled
                      outlined
                      color="pink lighten-3"
                      solo
                      v-model="filter.ricerca"
                      placeholder="Ricerca Vini"
                  >
                    <template slot="prepend-inner">
                      <v-icon
                          color="#adb5bd"
                          size="16"
                          class="material-icons-round mt-n2"
                      >search
                      </v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="5">
                  <v-text-field
                      hide-details
                      class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    "
                      flat
                      filled
                      outlined
                      color="pink lighten-3"
                      solo
                      v-model="search"
                      placeholder="Ricerca nella pagina"
                  >
                    <template slot="prepend-inner">
                      <v-icon
                          color="#adb5bd"
                          size="16"
                          class="material-icons-round mt-n2"
                      >search
                      </v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <span class="text-body font-weight-bold">
              <v-chip
                  link
                  small
                  class=""
                  :color="item.is_in_price_list_for_store ? 'light-green' : 'red darken-4'"
                  label
                  outlined
                  text-color="blue-grey darken-1"
                  @click="editItem(item)"
              >
                {{ item.name }}
              </v-chip>
            </span>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            {{ item.type_name }}
          </template>
          <template v-slot:[`item.sold_out`]="{ item }">
            <span v-if="item.sold_out">
              <v-icon
                  size="20"
                  class="material-icons-round mt-n2"
                  style="color: #d32f2f"
              >check</v-icon
              >
            </span>
          </template>
          <template v-slot:[`item.magnum`]="{ item }">
            <!-- <span > -->
            <span v-if="item.magnum">
              <v-icon
                  size="20"
                  class="material-icons-round mt-n2"
                  color="light-green"
              >check</v-icon
              >
            </span>
            <span v-else></span>
          </template>
          <template v-slot:[`item.type_grapes_name`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                      v-bind="attrs"
                      v-on="on"
                      :color="item.type_grapes_name.length === 0 ? 'red':'indigo lighten-2'"
                      dark
                      x-small
                  >
                    {{ item.type_grapes_name.length }} UVE
                  </v-chip>
                </template>
                <div>
                  <p v-for="(grape, index) in item.type_grapes_name" :key="`grape-${index}`" class="mb-0">
                    {{ grape.name }}
                  </p>
                </div>
              </v-tooltip>
          </template>
          <template v-slot:[`item.note`]="{ item }">
            <v-tooltip bottom color="white" max-width="350px">
              <template v-slot:activator="{ on, attrs }">
                <span v-if="item.note.length < 10"></span>
                <span v-else v-bind="attrs" v-on="on">{{
                    item.note.substring(0, 10) + ".."
                  }}</span>
              </template>
              <v-alert color="pink lighten-5">{{ item.note }}</v-alert>
            </v-tooltip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex align-center"> <!-- Aggiungi questa riga -->
              <v-btn
                  @click="deleteItem(item)"
                  icon
                  elevation="0"
                  :ripple="false"
                  height="28"
                  min-width="36"
                  width="36"
                  color="red"
              >
                <v-icon size="14">close</v-icon>
              </v-btn>
              <add-wine-to-price-list
                  v-if="!item.is_in_price_list_for_store"
                  icon="mdi-plus"
                  :wine="item"
                  @refreshWine="getVini"
              ></add-wine-to-price-list>
            </div> <!-- Chiudi il div qui -->
          </template>
          <template v-slot:[`items.winemaker`]="{ item }">
            <span>
              {{ item.winemaker }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
                @input="getPageNumber"
                prev-icon="fa fa-angle-left"
                next-icon="fa fa-angle-right"
                class="pagination"
                color="#D81B60"
                v-model="page"
                :length="pageCount"
                circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
        >Attenzione!
        </v-card-title>
        <v-card-text>
          <div>
            <h2>Vuoi annullare l'associazione del <br>
              vino {{ editedItem.name }}
              dal produttore?</h2>
          </div>
        </v-card-text>
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
              @click="closeDelete"
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-normal
                text-capitalize
                btn-ls
                bg-transparent
                btn-outline-secondary
                py-3
                px-6
              "
          >Cancel
          </v-btn
          >
          <v-btn
              @click="removeItemConfirm"
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-normal
                text-capitalize
                btn-ls btn-primary
                bg-gradient-primary
                py-3
                px-6
              "
          >OK
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import wineServices from "@/services/wine.services";
import winemakerService from "@/services/winemaker.service";
import listinoService from "@/services/listino.service";
import {eventBus} from "@/event-bus.js";
import AddWineToPriceList from "@/components/Dialog/AddWineToPriceList.vue";
import addWineFromWinemaker from "@/views/Pages/Winemaker/components/AddWineFromWinemaker.vue";

export default {
  name: "paginated-tables",
  components: {
    AddWineToPriceList,
    addWineFromWinemaker
    // eslint-disable-next-line vue/no-reserved-component-names
    // Map
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      dialog: false,
      dialogAssociaVino: false,
      dialogDelete: false,
      // -------------------
      componentKey: 0,
      vini: [],
      viniAssocia: [],
      viniAssociaVino: [],
      tipi: [],
      tipiUva: {},
      produttori: [],
      prodName: "",
      // distributori: [],
      itemId: "",
      conteggioVini: null,
      loading: false,
      items: [],
      cercaTipo: null,
      cercaProduttore: null,
      risultatoVino: null,
      cercaVino: "",
      cercaDistro: null,
      tempTypeGrapesName: [],
      newTypeGrapesName: [],
      checkbox1: {
        a: true,
        b: false,
      },
      checkbox2: true,
      // -------------------
      search: "",
      editedIndex: -1,
      editedItem: {
        name: "",
        nameAssocia: "",
        version: "",
        type: "",
        gradation: "",
        vinification: "",
        vintage: "",
        soldoutCheck: "",
        magnumCheck: "",
        // soldoutEdit: Boolean,
        // magnumEdit: Boolean,
        typegrapes: "",
        type_grapes_name: [],
        winemaker_id: "",
        // distributor: "",
        note: "",
        barcode: "",
        // latitude: "",
        // longitude: "",
      },
      defaultItem: {
        name: "",
        nameAssocia: "",
        version: "",
        type: "",
        gradation: "",
        vinification: "",
        vintage: "",
        soldoutCheck: "",
        magnumCheck: "",
        // soldoutEdit: Boolean,
        // magnumEdit: Boolean,
        typegrapes: "",
        type_grapes_name: [],
        winemakerId: "",
        winemaker_name: "",
        // distributor: "",
        note: "",
        barcode: "",
      },
      headers: [
        {
          text: "Nome",
          sortable: false,
          value: "name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
          cellClass: "border-bottom",
        },
        {
          text: "Anno",
          value: "vintage",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
          cellClass: "border-bottom",
        },
        {
          text: "Tipo",
          value: "type",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
          cellClass: "border-bottom",
        },
        {
          text: "Magnum",
          value: "magnum",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
          cellClass: "border-bottom",
        },
        {
          text: "Uva",
          value: "type_grapes_name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
          cellClass: "border-bottom",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
          cellClass: "border-bottom",
        },
      ],
      progress: false,
      openFiltri: false,
      filter: {
        type: "",
        winemaker: "",
        esaurito: "",
        magnum: "",
        ricerca: "",
        ricercaAssociaVini: "",
        ricercaNeiProd: "",
      },
      showTable: false
    };
  },
  mounted() {
    this.getViniAssocia();
    this.getProduttori();
    this.getPageNumber();

    eventBus.$on("produttoreId", (val) => {
      this.page = 1;
      this.showTable = true
      this.idProduttore = val;
      console.log('idProduttore', this.idProduttore)
      this.defaultItem.winemakerId = val;
      console.log('winemakerId', this.defaultItem.winemakerId)
      this.getVini(val);
      //   this.controlloOrdine = val
    });

    eventBus.$on("prodName", (val) => {
      this.prodName = val;
    });
  },
  methods: {
    closeTable() {
      this.showTable = false
    },
    getPageNumber(val) {
      eventBus.$emit("pageNumber", val);
      this.page = val;
      this.getVini();
    },
    mostraFiltri() {
      return (this.openFiltri = !this.openFiltri);
    },
    forceRerender() {
      this.componentKey += 1;
    },
    getVini() {
      this.progress = true;
      const params = {
        winemakerId: this.idProduttore,
        page: this.page,
        type: this.filter.type,
        winemaker_name: this.filter.winemaker,
        sold_out: this.filter.esaurito,
        magnum: this.filter.magnum,
        search: this.filter.ricerca,
      };
      wineServices.getVini(params).then((resp) => {
        this.vini = resp.data.results;
        this.vini.sort();
        this.conteggioVini = resp.data.count;
        this.pageCount = Math.ceil(resp.data.count / this.itemsPerPage);
        this.progress = false;
      });

    },
    getViniAssocia() {
      this.progress = true;
      const params = {};
      wineServices.getVini(params).then((resp) => {
        this.viniAssocia = resp.data.results;
        this.viniAssocia.sort();
      });
    },
    searchVini(page) {
      this.progress = true;
      const params = {
        page: page,
        winemakerid: this.idProduttore,
        search: this.filter.ricerca,
      };
      listinoService.getListiniItems(params).then((resp) => {
        this.vini = resp.data.results;
        this.vini.sort();
        this.conteggioVini = resp.data.count;
        this.pageCount = Math.ceil(resp.data.count / this.itemsPerPage);
        this.progress = false;
      });
    },
    searchViniInAssociaVini(page) {
      this.progress = true;
      const params = {
        page: page,
        search: this.viniAssocia,
      };
      listinoService.getListiniItems(params).then((resp) => {
        this.viniAssociaVino = resp.data.results;
        this.viniAssociaVino.sort();
        this.conteggioVini = resp.data.count;
        this.pageCount = Math.ceil(resp.data.count / this.itemsPerPage);
        this.progress = false;
      });
    },
    getProduttori() {
      winemakerService.getProduttori().then((resp) => {
        this.produttori = resp.data.results;
      });
    },
    newVino() {
      const payload = {
        name: this.editedItem.name,
        version: this.editedItem.version,
        type: this.editedItem.type,
        gradation: this.editedItem.gradation,
        vinification: this.editedItem.vinification,
        vintage: this.editedItem.vintage,
        sold_out: this.editedItem.sold_out,
        magnum: this.editedItem.magnum,
        type_grapes: this.editedItem.type_grapes,
        winemaker: this.defaultItem.winemakerId,
        distributor: this.editedItem.distributor,
        note: this.editedItem.note,
      };
      wineServices.newVino(payload).then(() => {
        this.$dialog.message.success("Vino modificato correttamente", {
          type: "success",
          rounded: true,
          position: "top-right",
          color: "green",
          outlined: false,
          timeout: 1000,
        });
        this.getVini()
      });
      this.dialog = false;

    },
    editItem(item) {
      this.editedIndex = this.vini.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // ------------------
      this.itemId = item.id;
      // ------------------
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.vini.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // ------------------
      this.itemId = item.id;
      // -------------------
      this.dialogDelete = true;
    },
    removeItemConfirm() {
      const payload = {
        id: this.itemId,
        winemaker: null
      }
      wineServices.editVino(payload).then(() => {
        this.$dialog.notify.success("Vino eliminato correttamente", {
          type: "success",
          rounded: true,
          position: "top-right",
          color: "green accent-5",
          outlined: false,
          timeout: 1000,
        });
        this.closeDelete();
      }).catch(e => {
        this.$dialog.notify.error('Errore nella modifica', {color: 'red'})
        console.log(e)
      }).finally(() => {
        this.conteggioVini = null;
      });


    },
    close() {
      this.dialog = false;
      this.dialogAssociaVino = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.dialogAssociaVino = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    modify() {
      const payload = {
        id: this.itemId,
        winemakerId: this.editedItem.winemakerId,
        name: this.editedItem.name,
        version: this.editedItem.version,
        type: this.editedItem.type,
        gradation: this.editedItem.gradation,
        vinification: this.editedItem.vinification,
        vintage: this.editedItem.vintage,
        sold_out: this.editedItem.sold_out,
        magnum: this.editedItem.magnum,
        type_grapes: this.editedItem.type_grapes,
        winemaker: this.editedItem.winemaker,
        distributor: this.editedItem.distributor,
        note: this.editedItem.note,
      };
      wineServices.editVino(payload).then(() => {
        this.$dialog.message.success("Vino modificato correttamente", {
          type: "success",
          rounded: true,
          position: "top-right",
          color: "green",
          outlined: false,
          timeout: 1000,
        });
        this.getVini()
      });
      this.dialog = false;
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.vini[this.editedIndex], this.editedItem);
        this.modify();
      } else {
        this.vini.push(this.editedItem);
        this.newVino();
        this.dialog = false;
        this.dialogAssociaVino = false;
      }
    },
    saveAssocia() {
      const payload = {
        id: this.risultatoVino,
        winemaker: this.idProduttore,
      };
      wineServices.editVino(payload).then(() => {
        this.$dialog.message.success("Vino associato correttamente", {
          rounded: true,
          position: "top-right",
          color: "green",
          outlined: false,
          timeout: 2000,
        });
        this.dialogAssociaVino = false
      }).catch(error => {
        this.$dialog.notify.error("Errore nell'associare il vino", {'coloer': 'red'})
        console.error(error)
      }).finally(() => {
        this.getVini()
      });
    },
    // Style Chips Type Uva
    getMarginLeft(index) {
      if (index === 0) return 0; // Il primo chip non ha margin-left
      // Diminuisci il margin-left negativo per ogni chip successivo
      // Puoi aggiustare il fattore -10px in base alla tua UI
      return -10 * index;
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogAssociaVino(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    "filter.ricerca"(value) {
      if (value.length > 2) {
        return this.searchVini();
      }
      if (value.length === 0) {
        const page = 1;
        return this.searchVini(page);
      }
    },
    cercaVino(value) {
      if (value) {
        if (value.length > 2) {
          const params = {
            search: value
          }
          wineServices.getVini(params).then(resp => {
            this.viniAssociaVino = resp.data.results
          }).catch().finally();
        }
        if (value.length === 0) {
          const page = 1;
          this.searchViniInAssociaVini(page)
        }
      }
    },
    conteggioVini(newValue, oldValue) {
      if (newValue != oldValue) {
        this.getVini();
      }
    },
  },
  // --------------------- PAGINAZIONE
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Aggiungi Vino" : "Modifica Vino";
    },
    pages() {
      return this.pagination.rowsPerPage
          ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
          : 0;
    },
    filteredWines() {
      return this.viniAssocia.filter(item => item.winemaker === null);
    }
  },
};
</script>

<style scoped>
/* Stile per il contenitore dei chip */
.chips-container {
  display: flex;
  align-items: center;
  position: relative;
}

/* Stile per sovrapporre i chip */
.overlapping-chip {
  position: absolute;
  border: 1px #00bcd4 solid;
  margin-left: -3px; /* Regola questo valore per controllare quanto i chip si sovrappongono */
}

/* Applica lo sfalsamento solo ai chip successivi al primo */
.overlapping-chip:not(:first-child) {
  margin-left: -10px; /* Aumenta questo valore per un maggiore sfalsamento */
}

</style>


