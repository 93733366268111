<template>
  <v-dialog @close="close" v-model="dialog" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">Vuoi Aggiungere questo Vino a quale Listino?</v-card-title>
        <v-card-text>
            <v-select
                    v-model="payload.price_list"
                    :items="listini"
                    label="Scegli il Listino"
                    item-text="name"
                    item-value="id"
                    append-icon="expand_more"
            >
            </v-select>
            <details>
                <summary class="text-danger">Data Payload:</summary>
                {{ payload }}
            </details>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click.native="close">Close</v-btn>
          <v-btn color="green darken-1" text @click.native="save">Salva</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>
<script>
import ListinoService from "@/services/listino.service";
export default {
    name: "QuickAddWineToPriceListItem",
    props: {
        openDialog: {
        default: false,
      },
        wineObject: Object
    },
    data() {
        return {
            dialog:false,
            listini:[],
            payload: {
                price_list: "",
                price_cost: 0,
                price_sell: 0,
                price_reduce: 0,
                percentage_value: 0,
                price_wine_glass: 0,
                price_web: 0,
                wine: this.wineObject.id,
                distributor: "",
            }
        }
    },
    mounted() {
        this.getListini()
    },
    methods: {
        close() {
        this.$emit('update:openDialog', false)
      },
        save() {
            ListinoService.newListinoItem(this.payload).then(() =>{
                this.$emit('update:openDialog', false)
                this.$dialog.message.success('Vino Aggiunto a Listino', {
                 type: 'undefined',
                 rounded: true,
                 position: 'top-right',
                 color: "green",
                 outlined: false,
              })
            }).catch()
        },
        getListini() {
            ListinoService.getListini(this.payload).then(resp => {
                this.listini = resp.data.results
            })
        }
    },
    watch: {
        openDialog(value) {
            this.dialog = value
            this.payload.wine = this.wineObject.id
        }
    }
}
</script>

<style scoped>

</style>