<template>
  <div>
    <v-dialog v-model="openDialog" max-width="1200px">
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0">
            Edit WineMaker
          </span>
        </div>
        <v-card-text class="card-padding">
          <v-container class="px-0">
            <v-row>
              <!-- *********** Ricerca *********** -->
              <v-col cols="12">
                <h2> Ricerca per Azienda o Indirizzo</h2>
                <v-text-field
                    v-model="search"
                    id="pac-input"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    outlined
                    flat
                    height="53"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <!-- *********** NOME *********** -->
              <v-col cols="12">
                <v-text-field
                    label="Nome"
                    v-model="produttore.name"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** INDIRIZZO *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Indirizzo"
                    v-model="produttore.address"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** LATITUDINE *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Latitudine"
                    v-model="produttore.lat"
                    type="number"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** LONGITUDINE *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Longitudine"
                    v-model="produttore.long"
                    type="number"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** REGIONE *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Regione"
                    v-model="produttore.region"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** PROVINCIA *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Provincia"
                    v-model="produttore.province"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** COMUNE *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Comune"
                    v-model="produttore.town"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** NAZIONE *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Nazione"
                    v-model="produttore.country"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** CITTA' *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Città"
                    v-model="produttore.city"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** CAP *********** -->
              <v-col cols="6">
                <v-text-field
                    label="CAP"
                    v-model="produttore.postal_code"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** TELEFONO *********** -->
              <v-col cols="6">
                <!-- {{ produttore }} -->
                <v-text-field
                    label="Telefono"
                    v-model="produttore.telephone"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** EMAIL *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Email"
                    v-model="produttore.email"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** SITO WEB *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Sito Web"
                    v-model="produttore.website"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** FACEBOOK *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Facebook"
                    v-model="produttore.facebook"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** PARTITA IVA *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Partita IVA"
                    v-model="produttore.vat"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** IBAN *********** -->
              <v-col cols="6">
                <v-text-field
                    label="IBAN"
                    v-model="produttore.iban"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** AGENTE *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Agente"
                    v-model="produttore.commercial_agent"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** DESCRIZIONE *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Descrizione"
                    v-model="produttore.description"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** ETTARI *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Ettari"
                    v-model="produttore.hectares"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** ANNO DI FONDAZIONE *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Anno di Fondazione"
                    v-model="produttore.year_foundation"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** NR BOTTIGLIE PRODOTTE *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Numero di Bottiglie Prodotte"
                    v-model="produttore.bottle_produced"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** VIGNAIOLO *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Vignaiolo"
                    v-model="produttore.winemaker"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** DIRETTO *********** -->
              <v-col cols="3">
            <!-- *********** MAPPA *********** -->
            <v-dialog
                v-model="dialogMap"
                transition="dialog-bottom-transition"
                width="55em"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="
                      font-weight-normal
                      text-capitalize
                      btn-primary
                      bg-gradient-primary
                      py-3
                      px-6
                      ms-3
                    "
                    v-bind="props"
                    @click="dialogMap=true"
                >
                  Apri Mappa
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <Map
                      :latitude="produttore.lat"
                      :longitude="produttore.long"
                      :key="componentKey"
                      @lastLat="getLatitude"
                      @lastLng="getLongitude"
                  ></Map>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                      font-weight-normal
                      text-capitalize
                      btn-primary
                      bg-gradient-primary
                      py-3
                      px-6
                      ms-3
                    "
                      @click="dialogMap = false">Chiudi Mappa
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
              </v-col>
              <!-- *********** DISTRIBUTORE *********** -->
              <v-col cols="6">
                <v-autocomplete
                    v-model="produttore.distributor"
                    :items="distributori"
                    :menu-props="{ top: true, offsetY: true }"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="filter.ricerca"
                    label="Distributore"
                    class="mx-4"
                    flat
                    chips
                    deletable-chips
                >
                </v-autocomplete>

                <v-alert
                    v-if="selezionaProduttore"
                    color="red"
                    dense
                    outlined
                    shaped
                    text
                    type="error"
                >Prima seleziona un produttore
                </v-alert
                >
              </v-col>
            </v-row>
          </v-container>
          <details>
            {{ produttore }}
          </details>
        </v-card-text>
        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
              @click="close"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-normal text-capitalize btn-ls btn-outline-secondary bg-transparent py-3 px-6"
          >Cancella
          </v-btn
          >
          <v-btn
              @click="save"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-normal text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
          >Salva
          </v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import winemakerService from "@/services/winemaker.service"
import {eventBus} from "@/event-bus.js"
import Map from "@/components/Map.vue"

export default {
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Map,
  },
  props: {
    objectData: Object,
    visible: Boolean,
    itemRiga: Number,
  },
  data() {
    return {
      // Maps
      componentKey: 0,
      geocoder: null,
      //
      selezionaProduttore: false,
      filter: {
        ricerca: "",
      },
      search: "",
      diretto: false,
      produttore: {},
      distributore: [],
      distributori: [],
      dialog: false,
      dialogMap: false,
      dialogAction: "",
      editedIndex: -1,
    };
  },
  mounted() {
    eventBus.$on("googleInit", () => {
      this.initMapAutocomplete();
    });
    this.getDistributori();
  },
  computed: {
    openDialog: {
      get () {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.produttore = this.objectData
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('closeDialog', false)
        }
      }
    },
    formTitle() {
      return this.dialogAction === "add" ? "Add Produttore" : "Edit Produttore";
    },
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    getDistributori() {
      this.progress = true;
      const params = {
        select: "",
        page_size: 5000,
        search: this.filter.ricerca,
      };
      winemakerService.getDistributori(params).then((resp) => {
        this.distributori = resp.data.results;
        this.distributori.sort();
      });
    },
    getProduttori() {
      this.progress = true;
      winemakerService.getProduttori().then((resp) => {
        this.produttori = resp.data.results
        this.conteggioProduttori = resp.data.count;
        this.pageCount = Math.ceil(resp.data.count / this.itemsPerPage);
        this.progress = false;
      });
    },
    getProduttore(id) {
      winemakerService.getProduttore(id).then((resp) => {
        this.produttore = resp.data
      });
    },
    close() {
      this.$emit('closeDialog', false)
    },
    save() {
      if (this.dialogAction === "add") {
        const payload = {
          vdistribution: this.produttore.vdistribution,
          vcountry: this.produttore.vcountry,
          vprovince: this.produttore.vprovince,
          vtown: this.produttore.vtown,
          vzone: this.produttore.vzone,
          lat: this.produttore.lat,
          long: this.produttore.long,
          name: this.produttore.name,
          distribution: this.produttore.distribution,
          region: this.produttore.region,
          province: this.produttore.province,
          town: this.produttore.town,
          country: this.produttore.country,
          address: this.produttore.address,
          city: this.produttore.city,
          postal_code: this.produttore.postal_code,
          commercial_agent: this.produttore.commercial_agent,
          description: this.produttore.description,
          bottle_produced: this.produttore.bottle_produced,
          winemaker: this.produttore.winemaker,
          telephone: this.produttore.telephone,
          mobile: this.produttore.mobile,
          email: this.produttore.email,
          website: this.produttore.website,
          facebook: this.produttore.facebook,
          vat: this.produttore.vat,
          iban: this.produttore.iban,
          note: this.produttore.note,
          year_foundation: this.produttore.year_foundation,
          hectares: this.produttore.hectares,
          direct_winemaker: this.diretto,
          distributor: this.produttore.distributor,
        };
        winemakerService.editProduttore(payload).then(() => {
          this.$emit("callGetProduttori");
          this.$dialog.message.success("Produttore aggiunto correttamente", {
            type: "success",
            rounded: true,
            position: "top-right",
            color: "green accent-5",
            outlined: false,
            timeout: 1000,
          });
        });
      } else {
        // ---- MODIFY
        const edited = {
          id: this.produttore.id,
          vdistribution: this.produttore.vdistribution,
          vcountry: this.produttore.vcountry,
          vprovince: this.produttore.vprovince,
          vtown: this.produttore.vtown,
          vzone: this.produttore.vzone,
          lat: this.produttore.lat,
          long: this.produttore.long,
          name: this.produttore.name,
          distribution: this.produttore.distribution,
          region: this.produttore.region,
          province: this.produttore.province,
          town: this.produttore.town,
          country: this.produttore.country,
          address: this.produttore.address,
          city: this.produttore.city,
          postal_code: this.produttore.postal_code,
          commercial_agent: this.produttore.commercial_agent,
          description: this.produttore.description,
          bottle_produced: this.produttore.bottle_produced,
          winemaker: this.produttore.winemaker,
          telephone: this.produttore.telephone,
          mobile: this.produttore.mobile,
          email: this.produttore.email,
          website: this.produttore.website,
          facebook: this.produttore.facebook,
          vat: this.produttore.vat,
          iban: this.produttore.iban,
          note: this.produttore.note,
          year_foundation: this.produttore.year_foundation,
          hectares: this.produttore.hectares,
          direct_winemaker: this.diretto,
          distributor: this.produttore.distributor,
        };
        winemakerService.editProduttore(this.objectData.id, edited).then(() => {
          this.$emit("callGetProduttori");
          this.$dialog.message.success("Produttore modificato correttamente", {
            type: "success",
            rounded: true,
            position: "top-right",
            color: "green accent-5",
            outlined: false,
            timeout: 1000,
          });
          this.close()
        }).catch().finally()
        // --- END MODIFY
      }
    },

    // MAPS
    getLatitude(value) {
      this.produttore.lat = value;
    },
    getLongitude(value) {
      this.produttore.long = value;
    },
    initMapAutocomplete() {
      // eslint-disable-next-line no-undef
      this.geocoder = new google.maps.Geocoder();
      const input = document.getElementById("pac-input");
      // eslint-disable-next-line no-undef
      const autocomplete = new google.maps.places.Autocomplete(input);
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(autocomplete, "place_changed", () => {
        const place = autocomplete.getPlace();
        // we need to update the map
        // eslint-disable-next-line no-undef
        eventBus.$emit("newTextAddress", place);
        this.search = "";
        this.produttore.address = place.formatted_address;
        for (const [key] of Object.entries(place.address_components)) {
          if (place.address_components[key].types[0] === "country") {
            this.produttore.country = place.address_components[key]["long_name"];
          } else if (place.address_components[key].types[0] === "postal_code") {
            this.produttore.postal_code = place.address_components[key]["long_name"];
          } else if (
              place.address_components[key].types[0] === "administrative_area_level_1"
          ) {
            this.produttore.region = place.address_components[key]["long_name"];
          } else if (
              place.address_components[key].types[0] === "administrative_area_level_2"
          ) {
            this.produttore.province = place.address_components[key]["long_name"];
          } else if (
              place.address_components[key].types[0] === "administrative_area_level_3"
          ) {
            this.produttore.town = place.address_components[key]["long_name"];
          } else if (place.address_components[key].types[0] === "locality") {
            this.produttore.city = place.address_components[key]["long_name"];
          } else if (place.name || place.website || place.international_phone_number) {
            this.produttore.name = place.name;
            this.produttore.website = place.website;
            this.produttore.telephone = place.international_phone_number;
          }
        }
      });
    },
    // end MAPS
  },
};
</script>
<style>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform .2s ease-in-out;
}
</style>
