import axios from "axios";
import authHeader from "@/services/auth.header";
const API_URL = process.env.VUE_APP_API_URL;

class TypeAndTypeGrapesService {
  // FARE getTipi per 'ELENCO VINI' con tutte le voci in una pagina
  getTipi() {
    return (
      axios
        .get(API_URL + `type/?page_size=10000`, {
          headers: authHeader(),
        })
    );
  }
  getTipiPagina(page) {
    return (
      axios
        .get(API_URL + `type/?page=${page}`, {
          headers: authHeader(),
        })
    );
  }
  newTipo(payload) {
    return axios.post(API_URL + "type/", payload, {
      headers: authHeader(),
    });
  }
  editTipo(itemId, edited) {
    return (
      axios
        .put(API_URL + `type/${itemId}/`, edited, {
          headers: authHeader(),
        })
    );
  }
  deleteTipo(itemId) {
    return axios.delete(API_URL + `type/${itemId}/`, {
      headers: authHeader(),
    });
  }
  // ----------------------------------------------------------------------------------------------------------------------------- //
  getTipiUva(params) {
    return (
      axios
        .get(API_URL + `typegrapes/?page_size=1000`, {
          headers: authHeader(), params: params
        })
    );
  }
  getCountry() {
    return (
      axios
        .get(API_URL + `wine/get_country/`, {
          headers: authHeader()
        })
    );
  }
  getProvince() {
    return (
      axios
        .get(API_URL + `wine/get_province/`, {
          headers: authHeader()
        })
    );
  }
  // getTipiUvaPagina(page) {
  //   return (
  //     axios
  //       // .get(API_URL + 'lms/?id=' + this.activeLesson,  {headers: authHeader()})
  //       .get(API_URL + `typegrapes/?page=${page}`, {
  //         headers: {
  //           Authorization: `Token ${TOKEN}`,
  //         },
  //       })
  //   );
  // }
  newTipoUva(payload) {
    return axios.post(API_URL + "typegrapes/", payload, {
      headers: authHeader(),
    });
  }
  editTipoUva(itemId, edited) {
    return (
      axios
        .put(API_URL + `typegrapes/${itemId}/`, edited, {
          headers: authHeader(),
        })
    );
  }
  deleteTipoUva(itemId) {
    return axios.delete(API_URL + `typegrapes/${itemId}/`, {
      headers: authHeader(),
    });
  }
  deleteTipoUvaFromWine(payload) {
    return axios.patch(API_URL + 'wine/' + payload.id + '/', payload, {
      headers: authHeader(),
    })
  }

}

export default new TypeAndTypeGrapesService()
