<template>
  <div>
    <!-- <v-btn @click="openDialogDistro('add')">Add</v-btn> -->
    <v-btn
        @click="openDialogDistro('add')"
        elevation="0"
        :ripple="false"
        height="43"
        class="font-weight-normal text-capitalize btn-primary bg-gradient-primary py-3 px-6 ms-3"
    >Aggiungi Distributore
    </v-btn>
    <v-dialog v-model="dialog" max-width="800px">
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding bg-pink card-border-bottom">
          <span class="font-weight-bold text-white text-h5 mb-0">
            Aggiungi Distributore
          </span>
        </div>
        <v-card-text class="card-padding" v-if="dialog">
          <v-container class="px-0">
            <v-row>
              <!-- *********** Ricerca *********** -->
              <v-col cols="12">
                <h2> Ricerca per Azienda o Indirizzo</h2>
                <gmap-autocomplete @place-selected="handlePlaceSelected"></gmap-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <!-- *********** NOME *********** -->
              <v-col cols="12">
                <v-text-field
                    label="Nome"
                    v-model="distributore.name"
                    id="pac-input"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** INDIRIZZO *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Indirizzo"
                    v-model="distributore.address"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** LATITUDINE *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Latitudine"
                    v-model="distributore.lat"
                    type="number"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** LONGITUDINE *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Longitudine"
                    v-model="distributore.long"
                    type="number"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** REGIONE *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Regione"
                    v-model="distributore.region"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** PROVINCIA *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Provincia"
                    v-model="distributore.province"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** COMUNE *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Comune"
                    v-model="distributore.town"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** NAZIONE *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Nazione"
                    v-model="distributore.country"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** CITTA' *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Città"
                    v-model="distributore.city"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** CAP *********** -->
              <v-col cols="6">
                <v-text-field
                    label="CAP"
                    v-model="distributore.postal_code"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** TELEFONO *********** -->
              <v-col cols="6">
                <!-- {{ distributore }} -->
                <v-text-field
                    label="Telefono"
                    v-model="distributore.telephone"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** EMAIL *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Email"
                    v-model="distributore.email"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** SITO WEB *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Sito Web"
                    v-model="distributore.website"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** CONDIZIONI DI VENDITA *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Condizioni di Vendita"
                    v-model="distributore.condizioni_di_vendita"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** PARTITA IVA *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Partita IVA"
                    v-model="distributore.vat"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** IBAN *********** -->
              <v-col cols="6">
                <v-text-field
                    label="IBAN"
                    v-model="distributore.iban"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** AGENTE *********** -->
              <v-col cols="6">
                <v-text-field
                    label="Agente"
                    v-model="distributore.commercial_agent"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    height="43"
                ></v-text-field>
              </v-col>

              <!-- *********** MAPPA *********** -->
              <v-col cols="12">

              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
              @click="close"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-normal text-capitalize btn-ls btn-outline-secondary bg-transparent py-3 px-6"
          >Cancella
          </v-btn
          >
          <v-btn
              @click="save"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-normal text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
          >Salva
          </v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import winemakerService from "@/services/winemaker.service";
import GmapAutocomplete from "@/components/GmapAutocomplete.vue";

export default {
  components: {
    GmapAutocomplete
  },
  props: {
    itemRiga: Number,
  },
  data() {
    return {
      componentKey: 0,
      geocoder: null,
      selezionaDistributore: false,
      filter: {
        ricerca: "",
      },
      search: "",
      diretto: false,
      distributore: {
        "name": "",
        "region": "",
        "province": "",
        "town": "",
        "country": "",
        "address": "",
        "city": "",
        "postal_code": "",
        "commercial_agent": "",
        "description": "",
        "bottle_produced": "",
        "telephone": "",
        "mobile": "",
        "email": "",
        "website": "",
        "condizioni_di_vendita": "",
        "instagram": "",
        "vat": "",
        "iban": "",
        "lat": "",
        "long": "",
        "note": "",
        "store": null,
        "winemaker": []
      },
      dialog: false,
      dialogAction: "",
    };
  },
  mounted() {
  },
  computed: {},
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    getDistributori() {
      this.progress = true;
      const params = {
        select: "",
        page_size: 5000,
        search: this.filter.ricerca,
      };
      winemakerService.getDistributori(params).then((resp) => {
        this.distributore = resp.data.results;
        this.distributore.sort();
      });
    },
    getDistributore(id) {
      winemakerService.getDistributore(id).then((resp) => {
        this.distributore = resp.data;
      });
    },
    openDialogDistro() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    save() {
        const payload = {
          id: this.distributore.id,
          lat: this.distributore.lat,
          long: this.distributore.long,
          name: this.distributore.name,
          distribution: this.distributore.distribution,
          region: this.distributore.region,
          province: this.distributore.province,
          town: this.distributore.town,
          country: this.distributore.country,
          address: this.distributore.address,
          city: this.distributore.city,
          postal_code: this.distributore.postal_code,
          commercial_agent: this.distributore.commercial_agent,
          telephone: this.distributore.telephone,
          mobile: this.distributore.mobile,
          email: this.distributore.email,
          website: this.distributore.website,
          condizioni_di_vendita: this.distributore.condizioni_di_vendita,
          vat: this.distributore.vat,
          iban: this.distributore.iban,
          store: this.$store.state.auth.user.store_id,
        };
        winemakerService.newDistributore(payload).then(() => {
          this.$emit("callGetDistributori");
          this.dialog = false
          this.$dialog.notify.success('Distributore Aggiunto con successo!', {color: 'green'})
        });
    },
    handlePlaceSelected(place) {
        this.distributore.name = place.name || '';
        this.distributore.lat = place.geometry?.location.lat() || '';
        this.distributore.long = place.geometry?.location.lng() || '';
        this.distributore.address = place.formatted_address || '';

        const addressComponents = place.address_components;
        const getComponent = (type) => {
          return addressComponents.find(component => component.types.includes(type))?.long_name || '';
        };

        this.distributore.region = getComponent('administrative_area_level_1');
        this.distributore.province = getComponent('administrative_area_level_2');
        this.distributore.town = getComponent('locality') || getComponent('sublocality') || '';
        this.distributore.country = getComponent('country');
        this.distributore.city = getComponent('locality');
        this.distributore.postal_code = getComponent('postal_code');
        // Setta altri campi qui se disponibili
        this.distributore.telephone = place.international_phone_number || place.formatted_phone_number || '';
        this.distributore.website = place.website || '';
    },
  },
};
</script>