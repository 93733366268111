<template>
  <div>
    <v-overlay :value="progress">
      <v-progress-circular indeterminate :size="70" :width="7" color="pink">
      </v-progress-circular>
    </v-overlay>
    <v-card class="card-shadow border-radius-xl" elevation="5" v-if="idListino">
      <div class="card-header-padding">
        <div class="font-weight-bold text-h4 text-typo mb-0">
          {{ namePriceList }}
          <v-chip class="ma-2" color="green" outlined>
            Totale Voci: {{ conteggioListiniItems }}
          </v-chip>

          <v-chip link class="ma-2" color="indigo lighten-3" outlined @click.stop="mostraFiltri">
            Filtra Vini
          </v-chip>
          <v-row v-show="openFiltri">
            <v-col cols="12">
              <v-card color="#E8EAF6" shaped elevation="3">
                <v-card-text>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="3">
                        <v-select label="Tipo" v-model="filter.type" :items="tipi"
                                  :menu-props="{ top: true, offsetY: true }" item-text="name" item-value="name" required
                                  small-chips clearable @change="getListiniItems" no-data-text="Non ci sono valori"
                                  class="mb-0" color="pink">
                        </v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete
                            v-model="filter.winemaker_name"
                            :items="produttori"
                            :menu-props="{ bottom: true, offsetY: true }"
                            color="pink"
                            item-text="name"
                            item-value="name"
                            return_object
                            label="Produttori"
                            deletable-chips
                            cache-items
                            small-chips
                            clearable
                            flat
                        ></v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-autocomplete
                            v-model="filter.country"
                            label="Nazione"
                            :items="filteredCountries"
                            item-value="country"
                            item-text="country"
                            return-object
                            deletable-chips
                            color="pink"
                            small-chips
                            clearable
                            cache-items
                            flat>
                        </v-autocomplete>
                      </v-col>
                      <v-col>
                        <!-- {{ produttori }} -->
                        <v-autocomplete
                            v-model="filter.region"
                            label="Regione"
                            :items="filteredRegions"
                            item-value="region"
                            item-text="region"
                            return-object
                            deletable-chips
                            color="pink"
                            small-chips
                            clearable
                            cache-items
                            flat>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field v-model="filter.minBottiglia" color="pink"
                                      label="Prezzo Bottiglia da:"></v-text-field>
                        <v-text-field v-model="filter.maxBottiglia" color="pink"
                                      label="Prezzo Bottiglia a:"></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field v-model="filter.minCalice" color="pink" label="Prezzo Calice da:"></v-text-field>
                        <v-text-field v-model="filter.maxCalice" color="pink" label="Prezzo Calice a:"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-card
                            style="border: 1px solid #fff"
                            outlined
                            shaped color="#E8EAF6" class="mb-2">
                          <v-card-title>Giacenza:</v-card-title>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" sm="6" md="4">
                                <v-select
                                    v-model="filter.giacenza.filterOperator"
                                    :items="operators"
                                    label="Seleziona Operatore">
                                </v-select>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                    v-model="filter.giacenza.filterValue"
                                    label="Quantità"
                                    type="number">
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col coles="6" class="d-flex" style="flex-direction: column">
                        <v-card style="border: 1px solid #fff" outlined shaped flat color="#E8EAF6"
                                class="mb-1 flex-grow-1">
                          <v-card-title>
                            <span style="color: #6c757d">Stai Filtrando per:</span>
                          </v-card-title>
                          <v-card-text>
                            <span class="mr-3" v-if="filter.type"><b>Tipo: </b>
                              <v-chip dark small color="#B388FF" @click:close="filter.type=''">
                                {{ filter.type }}
                              </v-chip></span>
                            <span class="mr-3" v-if="filter.winemaker_name"><b>Produttore: </b>
                              <v-chip dark small color="#B388FF">
                                {{ filter.winemaker_name }}
                              </v-chip></span>
                            <span class="mr-3" v-if="filter.country.country"><b>Nazione: </b>
                              <v-chip close dark small color="#B388FF" @click:close="filter.country={}">
                                {{ filter.country.country }}
                              </v-chip>
                            </span>
                            <span class="mr-3" v-if="filter.region.region"><b>Provincia:</b>
                              <v-chip close dark small color="#B388FF" @click:close="filter.region=''">
                                {{ filter.region.region }}
                              </v-chip></span>
                            <span class="mr-3" v-if="filter.esaurito">vini
                              <v-chip close dark small color="#B388FF" @click:close="filter.esaurito=''">
                                {{ filter.esaurito ? "esauriti" : "" }}
                              </v-chip></span>
                            <span class="mr-3" v-if="filter.magnum">dimensioni
                              <v-chip close dark small color="#B388FF" @click:close="filter.country=''">
                                {{ filter.magnum ? "magnum" : "" }}
                              </v-chip></span>
                            <span class="mr-3" v-if="filter.minBottiglia > 0"><b>Costo Bottiglia:</b>
                              <v-chip dark small color="#B388FF" @click:close="resetBottiglia">
                                da {{ filter.minBottiglia }} a {{ filter.maxBottiglia }}
                              </v-chip></span>
                            <span class="mr-3" v-if="filter.minCalice"><b>Costo Calice:</b>
                              <v-chip dark small color="#B388FF">
                                da {{ filter.minCalice }} a {{ filter.maxCalice }}
                              </v-chip></span>
                            <span class="mr-3" v-if="filter.giacenza">
                              <span class="font-weight-bolder">Giacenza: </span>
                              <v-chip dark small color="#B388CC">
                                {{ filter.giacenza.filterOperator }}
                              </v-chip>
                              <v-chip dark small color="#B388FF" @click:close="giacenza = false">
                                {{ filter.giacenza.filterValue }}
                              </v-chip>
                            </span>
                          </v-card-text>
                          <v-card-actions class="float-end">
                            <v-btn
                                dark
                                color="pink"
                                @click.stop="applicaFiltri"
                            >Applica Filtri
                            </v-btn>
                            <v-btn
                                dark
                                color="indigo"
                                @click.stop="resetFiltri"
                            >Reset Filtri
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row></v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-card-text>
        <v-data-table
            ref="pagina"
            :headers="headers"
            :items="listiniItems"
            :search="search"
            fixed-header
            dense
            loading
            loading-text="Non ci sono vini da visualizzare"
            :page.sync="page"
            hide-default-footer
            @page-count="pageCount = $event"
            :items-per-page="itemsPerPage"
            single-expand
            :expanded.sync="expanded"
            show-expand
            mobile-breakpoint="0">
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="12" md="5">
                  <v-text-field
                      hide-details
                      class="input-style font-size-input text-light-input placeholder-light input-icon" flat
                      filled outlined
                      color="pink lighten-3" solo v-model="filter.ricerca" placeholder="Ricerca nei Listini">
                    <template slot="prepend-inner">
                      <v-icon color="#adb5bd" size="16" class="material-icons-round mt-n2">search
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="5">
                  <v-text-field
                      hide-details
                      class="input-style font-size-input text-light-input placeholder-light input-icon" flat
                      filled outlined
                      color="pink lighten-3" solo v-model="search" placeholder="Ricerca nella pagina">
                    <template slot="prepend-inner">
                      <v-icon color="#adb5bd" size="16" class="material-icons-round mt-n2">search
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-spacer></v-spacer>
              <!-- ******************* DIALOG *******************-->
              <v-dialog v-model="dialog" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" elevation="0" :ripple="false" height="43"
                         class="font-weight-normal text-capitalize btn-primary bg-gradient-primary py-3 px-6 ms-3">
                    Aggiungi Voce Listino
                  </v-btn>
                </template>
                <v-card class="card-shadow border-radius-xl">
                  <div class="card-header-padding card-border-bottom">
                    <span class="font-weight-bold text-h5 text-typo mb-0">
                      {{ formTitle }}
                    </span>
                  </div>

                  <v-card-text class="card-padding">
                    <v-container class="px-0">
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                          <!-- *********** VINO *********** -->
                          <v-col cols="6">
                            <h3 class="ms-2">Vino</h3>
                            <v-autocomplete
                                v-model="editedItem.wine"
                                :label="editedItem.wine_name"
                                :items="vini"
                                :search-input.sync="filter.ricercavino"
                                item-text="full_name"
                                item-value="id"
                                placeholder="Scrivi..."
                                flat hide-selected cache-items>
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row>
                          <!-- *********** PREZZO DI ACQUISTO *********** -->
                          <v-col cols="6">
                            <v-text-field
                                label="Listino"
                                prefix="€"
                                type="number"
                                v-model="editedItem.price_cost"
                                :rules="[rules.required]" hide-details
                                class="input-style font-size-input text-light-input placeholder-light input-icon"
                                dense flat
                                height="43" required></v-text-field>
                          </v-col>
                          <!-- *********** PERCENTUALE DI SCONTO *********** -->
                          <v-col cols="6">
                            <v-text-field
                                label="Sconto"
                                prefix="%"
                                type="number"
                                value="0"
                                v-model="editedItem.percentage_value"
                                hide-details
                                class="input-style font-size-input text-light-input placeholder-light input-icon"
                                dense flat
                                height="43" @input="calcolaCampoRiduzione"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <!-- *********** PREZZO DI ACQUISTO DOPO LO SCONTO *********** -->
                          <v-col cols="6">
                            <v-text-field
                                label="Acquisto"
                                prefix="€"
                                type="number"
                                v-model="editedItem.price_reduce"
                                :rules="[rules.required]" hide-details
                                class="input-style font-size-input text-light-input placeholder-light input-icon"
                                dense flat
                                height="43" @input="calcolaCampoPercentuale"></v-text-field>
                          </v-col>
                          <!-- *********** PREZZO ASPORTO *********** -->
                          <v-col cols="6">
                            <v-text-field label="Prezzo Asporto Consigliato" type="number" prefix="€"
                                          hint="Prezzo Consigliato" v-model="editedItem.price_sell"
                                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                                          dense flat
                                          height="43"></v-text-field>
                          </v-col>
                          <!-- *********** PREZZO AL BICCHIERE *********** -->
                          <v-col cols="6">
                            <v-text-field
                                label="Prezzo al Calice Consigliato"
                                type="number"
                                prefix="€"
                                variant="indigo"
                                v-model="editedItem.price_wine_glass" hint="Prezzo Consigliato"
                                class="input-style font-size-input text-light-input placeholder-light input-icon"
                                dense flat
                                height="43">
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>
                  <v-card-actions class="card-padding d-flex justify-end">
                    <v-btn @click="close" elevation="0" :ripple="false" height="43"
                           class="font-weight-normal text-capitalize btn-ls btn-outline-secondary bg-transparent py-3 px-6">
                      Cancella
                    </v-btn>
                    <v-btn :disabled="!valid" @click="save" elevation="0" :ripple="false" height="43"
                           class="font-weight-normal text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6">
                      Salva
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card class="card-shadow card-padding border-radius-xl">
                  <v-card-title class="pt-0 text-h5 text-typo justify-center">Vuoi eliminare questo Listino Item?
                  </v-card-title>
                  <v-card-actions class="pb-0">
                    <v-spacer></v-spacer>
                    <v-btn @click="closeDelete" elevation="0" :ripple="false" height="43"
                           class="font-weight-normal text-capitalize btn-ls bg-transparent btn-outline-secondary py-3 px-6">
                      Cancel
                    </v-btn>
                    <v-btn @click="deleteItemConfirm" elevation="0" :ripple="false" height="43"
                           class="font-weight-normal text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6">
                      OK
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                  max-width="800"
                  v-model="dialogInfo"
              >
                <v-container class="bg-white">
                  <v-row>
                    <v-col cols="6">
                      <v-card class="bg-white">
                        <v-card-text>
                          <span class="text-lg-h2 text-pink">
                            <b class="text-gray">ID:</b>{{ infoWine.id }}
                          </span><br>
                          <span class="text-lg-h6 text--accent-3">
                            <small class="text-lg-h6 text--accent-3 text-xxs text-muted">
                              vecchia etichetta: {{ infoWine.wine_id }}</small>
                          </span><br>
                          <span class="text-lg-h2 text-pink">
                            <b class="text-gray">Nome:</b> {{ infoWine.wine_name }}
                          </span><br>
                          <span class="text-lg-h2 text-pink">
                            <b class="text-gray">Tipo:</b> {{ infoWine.wine_type }}
                          </span><br>
                          <span class="text-lg-h2 text-pink">
                            <b class="text-gray">Annata:</b> {{ infoWine.wine_vintage }}
                          </span><br>
                          <span class="text-lg-h2 text-pink">
                            <b class="text-gray">Regione:</b> {{ infoWine.wine_country }}
                          </span><br>
                          <span class="text-lg-h2 text-pink">
                            <b class="text-gray">Produttore:</b> {{ infoWine.winemaker_name }}
                          </span><br>
                          <!-- Altri campi dati che desideri visualizzare -->
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col cols="6">
                      <v-card>
                        <v-card-text>
                          <img :src="infoWine.wine_image" class="d-block max-width"
                               alt="Anteprima immagine" width="350px"/>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:expanded-item="{headers}">
            <td v-if="expanded.length > 0" :colspan="headers.length" style="background:#f5f1f1">
              <v-card flat class="border-radius-xl" style="background:#f5f1f1">
                <v-card-text class="">
                  <v-row>
                    <v-col>
                      <v-card>
                        <v-card-title>Info Listino</v-card-title>
                        <v-list>
                          <v-list-item>
                            QRCODE: <span class="text-pink mr-3">{{ expanded[0].id }}</span>
                            BARCODE: <span class="text-pink">{{ expanded[0].wine_id }}</span>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                              <span v-on="on" class="text-pink dotted-underline"
                                    @click="copyToClipboard(expanded[0].price_list_item)">
                                    {{ expanded[0].price_list_item }}
                              </span>
                              </template>
                              <span>Clicca per copiare</span>
                            </v-tooltip>
                          </v-list-item>
                          <v-list-item>
                            Name: <span class="text-pink">{{ expanded[0].wine_name }}</span>
                          </v-list-item>
                          <v-list-item>
                            Vintage: <span class="text-pink">{{ expanded[0].wine_vintage }}</span>
                          </v-list-item>
                          <v-list-item>
                            Posizione: <span class="text-pink">{{ expanded[0].wine_vintage }}</span>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-col>
                    <v-col>
                      <v-card>
                        <v-card-title>Info Magazzino</v-card-title>
                        <v-list class="border-radius-xl">
                          <v-list-item>
                            Magazzino: <span class="text-pink">-no data-</span>
                          </v-list-item>
                          <v-list-item>
                            Rack: <span class="text-pink">-no data-</span>
                          </v-list-item>
                          <v-list-item>
                            Cella: <span class="text-pink">-no data-</span>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-col>
                    <v-col>
                      <v-card>
                        <v-card-title>Info Statistiche</v-card-title>
                        <v-list class="border-radius-xl">
                          <v-list-item>
                            Bottiglie vendute dall'inizio anno: <span class="text-pink">-no data-</span>
                          </v-list-item>
                          <v-list-item>
                            Quantità in magazzino: <span class="text-pink">-no data-</span>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>

              </v-card>
            </td>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <div class="d-flex align-center ms-0 mr-0">
              <span class="text-sm font-weight-normal text-body">
                <v-btn text color="indigo" @click="editWine(item)">{{ item.id }}</v-btn>

              </span>
            </div>
          </template>
          <template v-slot:[`item.wine_name`]="{ item }">
            <div class="d-flex align-center ms-0 mr-0">
              <span class="text-sm font-weight-900">
                <v-btn @click="openDialogInfo(item)" class="ma-2" outlined small color="pink">
                  {{ item.wine_name }}
                </v-btn>
              </span>
            </div>
          </template>
          <template v-slot:[`item.winemaker_name`]="{item}">
            <v-btn class="ma-2" outlined small color="gray" @click="openDialogWinemaker(item)">{{
                item.winemaker_name
              }}
            </v-btn>
          </template>
          <template v-slot:[`item.price_cost`]="{ item }">
            <div class="d-flex align-center ms-0 mr-0">
              <span class="text-sm font-weight-bold text-body">
                {{ item.price_cost }}€
              </span>
            </div>
          </template>
          <template v-slot:[`item.price_reduce`]="{ item }">
            <div class="d-flex align-center ms-0 mr-0">
              <span class="text-sm font-weight-bold text-body">
                {{ item.price_reduce }}€
              </span>
            </div>
          </template>
          <template v-slot:[`item.price_sell`]="{ item }">
            <div class="d-flex align-center ms-0 mr-0">
              <span class="text-sm font-weight-bold text-body">
                {{ item.price_sell }}€
              </span>
            </div>
          </template>
          <template v-slot:[`item.percentage_value`]="{ item }">
            <div class="d-flex align-center ms-0 mr-0">
              <span v-if="item.percentage_value > 0" class="text-sm font-weight-normal text-body">
                {{ parseInt(item.percentage_value) }} %
              </span>
            </div>
          </template>
          <template v-slot:[`item.price_wine_glass`]="{ item }">
            <div class="d-flex align-center ms-0 mr-0">
              <span class="text-sm font-weight-bold text-body">
                {{ item.price_wine_glass }}€
              </span>
            </div>
          </template>
          <template v-slot:[`item.price_web`]="{ item }">
            <div class="d-flex align-center ms-0 mr-0">
              <span class="text-sm font-weight-normal text-body">
                {{ item.price_web }}€
              </span>
            </div>
          </template>
          <template v-slot:[`item.is_direct`]="{ item }">
            <div class="d-flex ml-4">
              <span>
                <v-icon style="font-size:1.7em" v-if="item.is_direct"
                        color="red lighten-2">pedal_bike</v-icon>
                <v-icon style="font-size:1.7em" v-else color="green lighten-2">local_shipping</v-icon>
              </span>
            </div>
          </template>
          <template v-slot:[`item.quantity`]="{ item }">
            <div class="d-flex align-center ms-0 mr-0">
              <span class="text-sm font-weight-normal text-body">
                <v-chip small outlined v-if="item.quantity >= 5" color="green"
                        class="d-flex justify-content-center"><b>{{ item.quantity }}</b></v-chip>
                <v-chip small outlined v-else-if="item.quantity === 0" color="red"
                        class="d-flex justify-content-center"><b>{{ item.quantity }}</b></v-chip>
                <v-chip
                    small
                    outlined
                    v-else-if="item.quantity < 5 && item.quantity >= 1"
                    color="orange"
                    class="d-flex justify-content-center">
                  <b>{{ item.quantity }}</b></v-chip>
                <v-chip small outlined v-else-if="item.quantity === null" color="red"
                        class="d-flex justify-content-center">0</v-chip>
              </span>
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu style="background-color: white" class="fullWidth">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list-item style="background-color: white">
                <v-btn
                    @click="editItem(item)"
                    icon elevation="0"
                    :ripple="false"
                    height="28"
                    min-width="36"
                    width="36"
                    class="my-2 rounded-sm" color="#67748e">
                  <v-icon size="14" class="material-icons-round">edit</v-icon>
                  Edit
                </v-btn>
              </v-list-item>
              <v-list-item style="background-color: white; width: 100px; text-align: center">
                <v-btn
                    @click="deleteItem(item)"
                    icon elevation="0"
                    :ripple="false"
                    height="28"
                    min-width="36"
                    width="36"
                    class="my-2 rounded-sm" color="#67748e">
                  <v-icon size="14" class="material-icons-round">close</v-icon>
                  Del
                </v-btn>
              </v-list-item>
              <v-list-item style="background-color: white">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        @click="rememberTo(item)"
                        icon elevation="0"
                        :ripple="false"
                        height="28"
                        min-width="36"
                        width="36"
                        v-bind="attrs"
                        v-on="on"
                        class="btn-ls me-2 my-2 rounded-sm" color="#67748e">
                      <v-icon size="14" class="material-icons-round"
                              :class="{'text-green': item.remember_to, 'text-gray': !item.remember_to}">
                        mdi-check-decagram-outline
                      </v-icon>
                      List
                    </v-btn>
                  </template>
                  <span>Inserisci nella Lista</span>
                </v-tooltip>
              </v-list-item>
              <v-list-item style="background-color: white">
                <print-barcode-listini-items :value="item"></print-barcode-listini-items>
              </v-list-item>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
                v-model="page"
                @input="getPageNumber"
                prev-icon="fa fa-angle-left"
                next-icon="fa fa-angle-right"
                class="pagination"
                color="#D81B60"
                :length="pageCount"
                circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <quick-add-winemaker-dialog
        :visible="showEditWinemaker"
        :object-data="produttore"
        @close="closeDetailsProduttore"
        @closeDialog="closeDetailsProduttore"
    >
    </quick-add-winemaker-dialog>
    <v-dialog max-width="800px" transition="dialog-bottom-transition" v-model="showEditWine">
      <edit-vino :item="wineToEdit" @refresh='closeEditWine'></edit-vino>
    </v-dialog>
  </div>
</template>

<script>
import wineServices from "@/services/wine.services";
import warehouseService from "@/services/warehouse.service";
import winemakerService from "@/services/winemaker.service";
import listinoService from "@/services/listino.service";
import {eventBus} from "@/event-bus.js";
import typeAndtypegrapesService from "../../../services/typeAndtypegrapes.service";
import QuickAddWinemakerDialog from "@/components/Dialog/QuickAddWinemakerDialog.vue";
import EditVino from "@/views/Pages/Vini/EditVino.vue";
import PrintBarcodeListiniItems from "@/views/Pages/Listini/PrintBarcodeListiniItem.vue";

export default {
  props: [
    "listinocheck",
    "var_a",
    "var_b",
    "idPriceListItem",
    "variablePriceList",
    'namePriceList'
  ],
  components: {PrintBarcodeListiniItems, QuickAddWinemakerDialog, EditVino},
  data() {
    return {
      imageCards: [
        {
          image: require("@/assets/img/product-1-min.jpeg"),
          title: "Cozy 5 Stars Apartment",
          text: "The place is close to Barceloneta Beach and bus stop just 2 min by walk and near to 'Naviglio' where you can enjoy the main night life in Barcelona.",
          price: "899",
          location: "Barcelona, Spain",
        },
      ],
      // ___ Paginazione
      page: 1,
      pageCount: 1,
      itemsPerPage: 20,
      editedIndex: -1,
      // ___ Dialog
      dialog: false,
      dialogDelete: false,
      dialogInfo: false,
      // ___ Paginazione Giacenza
      giacenzaPageCount: 0,
      // ___ Listini
      idListino: null,
      showListiniItems: false,
      listiniItems: [],
      listini: [],
      nomeListino: "",
      magazzini: [],
      distributori: [],
      vini: [],
      // store: "",
      itemId: "",
      conteggioListiniItems: "",
      loading: false,
      items: [],
      distribuzione: ["Directly", "Large Distribution"],
      // ___ Validazione Form
      valid: true,
      rules: {
        required: (value) => !!value || "Campo Obbligatorio.",
      },
      // Disable Input
      disablePercentage: false,
      disableValue: false,
      // ___ Search
      search: "",
      filter: {
        minBottiglia: null,
        maxBottiglia: null,
        minCalice: null,
        maxCalice: null,
        ricerca: "",
        ricercavino: "",
        nomelistino: "",
        ricercadistro: "",
        type: "",
        winemaker_name: "",
        giacenza: {
          filterValue: 0,
          filterOperator: ">="
        },
        country: {},
        region: {}
      },
      operators: ['Nessuna Quantità', '<', '=', '>', ">=", "<="],
      // ___ Page loader
      progress: false,
      editedItem: {
        wine: "",
        distributor: "",
        warehouse_name: "",
        distributor_name: "",
        price_list: "",
        price_cost: "", // Listino
        percentage_value: "",
        price_sell: "",
        price_reduce: "", // Prezzo di acquisto dopo lo sconto
        price_wine_glass: "",
        price_web: "",
        quantity: "",
        sold_out: false,
        rememberTo: false,
        is_direct: false,
      },
      defaultItem: {
        wine: "",
        distributor: "",
        warehouse_name: "",
        distributor_name: "",
        price_list_item: "",
        price_list: "",
        price_cost: "",
        price_sell: "",
        price_reduce: "",
        price_wine_glass: "",
        price_web: "",
        quantity: "",
        sold_out: false,
        rememberTo: false,
        is_direct: false,
      },
      // __ Filtri
      openFiltri: false,
      showFiltri: false,
      minBottiglia: 0,
      maxBottiglia: 0,
      rangeBottiglia: [1, 10000],
      minCalice: 0,
      maxCalice: 0,
      rangeCalice: [1, 1000],
      giacenza: false,
      giacenze: false,
      filtered: false,
      all_items: false,
      tipi: [],
      produttori: [],
      country: [],
      province: [],
      region: [],
      // ___ Headers
      headers: [
        {
          text: "ID",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "id",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "IsDirect",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "is_direct",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Nome",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "wine_name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Annata",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "wine_vintage",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Produttore",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "winemaker_name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        // {
        //   text: "Tipo",
        //   align: "start",
        //   cellClass: "border-bottom",
        //   sortable: false,
        //   value: "wine_type",
        //   class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        // },
        {
          text: "Listino",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "price_cost",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Sconto",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "percentage_value",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Acquisto",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "price_reduce",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Asporto",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "price_sell",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Calice",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "price_wine_glass",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        // {
        //   text: "Esaurito",
        //   value: "soldout",
        //   sortable: true,
        //   cellClass: "border-bottom",
        //   class: "text-secondary font-weight-bolder opacity-7 ps-6",
        // },
        {
          text: "Quantità",
          align: "start",
          cellClass: "border-bottom",
          sortable: true,
          value: "quantity",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Actions",
          value: "actions",
          cellClass: "border-bottom",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
      // data to Dialog
      infoWine: [],
      // SnackBar
      showEditWinemaker: false,
      produttore: {},
      // Data Row Expanded
      expanded: [],
      singleExpand: true,
      // edit Wine
      showEditWine: false,
      wineToEdit: {},
    };
  },
  mounted() {
    this.getMagazzini();
    // this.getListini();
    this.getDistro();
    this.getPageNumber();
    this.getTipi(); // da fare
    this.getProduttoriItems()
    eventBus.$on("listinoId", (val) => {
      this.getNrTipiBottiglia(val);
    });
  },
  methods: {
    get_all() {
      this.progress = true;
      const params = {
        page: this.page,
        price_list: this.idListino
      }
      listinoService.getListiniItemsLight(params).then(resp => {
        this.listiniItems = resp.data.results;
        this.listiniItems.sort();
        this.conteggioListiniItems = resp.data.count;
        this.pageCount = resp.data.num_pages
        this.progress = false;
      }).catch().finally()
    },
    applicaFiltri() {
      this.page = 1
      this.giacenza = true
      this.getGiacenza()
    },
    resetFiltri() {
      this.filter = {
        minBottiglia: null,
        maxBottiglia: null,
        minCalice: null,
        maxCalice: null,
        ricerca: "",
        ricercavino: "",
        nomelistino: "",
        ricercadistro: "",
        type: "",
        winemaker_name: "",
        giacenza: {
          filterValue: 0,
          filterOperator: ">"
        },
        country: {},
        region: {}
      }
      this.giacenza = false
      this.get_all()
    },
    closeDetailsProduttore(value) {
      this.showEditWinemaker = value
    },
    mostraFiltri() {
      return (this.openFiltri = !this.openFiltri);
    },
    getPageNumber(val) {
      if (this.giacenza || this.giacenze) {
        this.page = val
        this.getGiacenza()
      } else if (this.filtered) {
        this.page = val;
        this.getListiniItems();
      } else {
        this.page = val
        this.get_all()
      }
    },
    getProduttoriItems() {
      const params = {
        select: "",
        page_size: 10000,
        price_list: this.idListino,
      };
      winemakerService.getProduttori(params).then(resp => {
        // listinoService.getListiniItems(params).then((resp) => {
        this.produttori = resp.data.results;
      });
    },
    removeItemFromFilter(item) {
      console.log(item)
      this.filter.type = ""
    },
    getListiniItems() {
      this.progress = true;
      const params = {
        page: this.page,
        price_list: this.idListino,
        by_type: this.filter.type,
        winemaker_name: this.filter.winemaker_name,
        search: this.filter.ricerca,
        range_price_bottle_min: this.filter?.minBottiglia,
        range_price_glass_min: this.filter?.minCalice,
        range_price_bottle_max: this.filter?.maxBottiglia,
        range_price_glass_max: this.filter?.maxCalice,
        winemaker_country: this.filter.country?.country,
        winemaker_region: this.filter.region?.region,
        operator: this.filter.giacenza.filterOperator,
        quantity: this.filter.giacenza.filterValue
      };
      listinoService.getListiniItems(params).then((resp) => {
        this.listiniItems = resp.data.results;
        this.listiniItems.sort();
        this.conteggioListiniItems = resp.data.count;
        this.pageCount = Math.ceil(resp.data.count / this.itemsPerPage);
        this.progress = false;
      });
    },
    getGiacenza() {
      this.progress = true;
      const params = {
        page: this.page,
        price_list: this.idListino,
        distributor: this.filter.ricercadistro,
        winemaker: "",
        distributor_name: "",
        winemaker_name: this.filter.winemaker_name,
        winemaker_distributor_name: "",
        distributor_is_none: "",
        by_id: "",
        by_wine_id: "",
        by_type: this.filter.type,
        range_price_bottle_min: this.filter.minBottiglia,
        range_price_bottle_max: this.filter.maxBottiglia,
        range_price_glass_min: this.filter.minCalice,
        range_price_glass_max: this.filter.maxCalice,
        operator: this.filter.giacenza.filterOperator,
        quantity: this.filter.giacenza.filterValue,
        winemaker_region: this.filter.region.id,
        winemaker_country: this.filter.country.country,
        by_remember_to: ""
      }
      listinoService.getGiacenza(params).then(resp => {
        this.progress = false
        this.listiniItems = resp.data.results
        this.pageCount = resp.data.num_pages
        this.conteggioListiniItems = resp.data.count
        console.log(this.listiniItems)
      }).catch(error => {
        console.log(error)
        this.progress = false
      })
    },
    getNrTipiBottiglia(val) {
      this.progress = true;
      const params = {
        price_list: val,
        giacenza: "true",
      };
      listinoService.getListiniItems(params).then((resp) => {
        const tipi_bottiglia = resp.data.count;
        eventBus.$emit("tipiBottiglia", tipi_bottiglia);
      });
    },
    searchListiniItems(page) {
      this.progress = true;
      if (this.giacenza) {
        const params = {
          page: page,
          price_list: this.idListino,
          search: this.filter.ricerca
        }
        listinoService.getGiacenza(params).then(resp => {
          this.listiniItems = resp.data.results;
          this.listiniItems.sort();
          this.conteggioListiniItems = resp.data.count;
          this.pageCount = resp.data.num_page
          this.progress = false;
        })
      } else {
        this.progress = true;
        const params = {
          page: page,
          price_list: this.idListino,
          search: this.filter.ricerca,
        };
        listinoService.getListiniItems(params).then((resp) => {
          this.listiniItems = resp.data.results;
          this.listiniItems.sort();
          this.conteggioListiniItems = resp.data.count;
          this.pageCount = Math.ceil(resp.data.count / this.itemsPerPage);
          this.progress = false;
        });
      }
    },
    getVini() {
      this.progress = true;
      const params = {
        page: this.page,
        search: this.filter.ricercavino,
      };
      wineServices.getVini(params).then((resp) => {
        this.vini = resp.data.results
        console.log(this.vini)
        this.vini.sort();
        this.progress = false;
      });
    },
    getDistro() {
      this.progress = true;
      const params = {
        search: this.filter.ricercadistro,
      };
      winemakerService.getDistributori(params).then((resp) => {
        this.distributori = resp.data.results;
        this.distributori.sort();
        this.progress = false;
      });
    },
    getMagazzini() {
      this.progress = true;
      const params = {
        store: this.$store.state.auth.account.store[0],
        page: this.page,
      };
      warehouseService.getMagazzini(params).then((resp) => {
        this.magazzini = resp.data.results;
        this.magazzini.sort();
        this.progress = false;
      });
    },
    getListini() {
      this.progress = true;
      const params = {
        page: null,
      };
      listinoService.getListini(params).then((resp) => {
        this.listini = resp.data.results;
        this.listini.sort();
        this.progress = false;
      });
    },
    getTipi() {
      typeAndtypegrapesService.getTipi().then((resp) => {
        this.tipi = resp.data.results;
      });
    },
    newListinoItem() {
      const payload = {
        page: this.page,
        price_list: this.idListino,
        price_cost: this.editedItem.price_cost,
        price_sell: parseFloat(this.editedItem.price_sell).toFixed(2),
        price_reduce: this.editedItem.price_reduce,
        percentage_value: this.editedItem.percentage_value,
        price_wine_glass: parseFloat(this.editedItem.price_wine_glass).toFixed(2),
        price_web: "0",
        wine: this.editedItem.wine,
        is_direct: this.editedItem.is_direct,
        distributor: this.editedItem.distributor,
      };
      listinoService.newListinoItem(payload).then(() => {
        this.$dialog.message.success("Listino aggiunti correttamente", {
              type: "success",
              rounded: true,
              position: "top-right",
              color: "green",
              outlined: false,
              timeout: 1000,
            }
        );
        this.getListiniItems(this.idListino);
      });
    },
    editItem(item) {
      this.editedIndex = this.listiniItems.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.itemId = item.id;
      this.dialog = true;
    },
    async editWine(item) {
      await wineServices.getVino(item.wine_id).then(response => {
        this.wineToEdit = response.data
      }).catch().finally(() => {
        this.showEditWine = true
      })
    },
    closeEditWine() {
      this.showEditWine = false
      this.getListiniItems()

    },
    deleteItem(item) {
      this.editedIndex = this.listiniItems.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.itemId = item.id;
      this.dialogDelete = true;
    },
    rememberTo(item) {
      const params = {
        remember_to: !item.remember_to
      }
      listinoService.editListinoItem(item.id, params).then(() => {
        this.snackbarRemember = true
        this.$dialog.message.success("Aggiunto/Rimosso", {
          rounded: true,
          position: "top-right",
          color: "green",
          outlined: false,
          timeout: 3000,
        })
        this.getListiniItems(this.idListino);
      });
    },
    openDialogWinemaker(item) {
      const findProduttore = this.produttori.find(produttore => produttore.name === item.winemaker_name)
      this.produttore = Object.assign(findProduttore)
      this.showEditWinemaker = true
    },
    openDialogInfo(item) {
      // const params = {
      //   by_id: item.wine_id,
      // }
      this.infoWine = item
      console.log(this.infoWine)
      this.dialogInfo = true;
      // console.log(item)
      // wineServices.getVini(params).then((resp) => {
      //   this.infoWine = resp.data.results;
      //
      // });
    },
    deleteItemConfirm() {
      const itemId = this.itemId;
      this.listiniItems.splice(this.editedIndex, 1);
      listinoService.deleteListinoItem(itemId);
      this.conteggioListiniItems = null;
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    modify() {
      const itemId = this.itemId;
      const edited = {
        price_list: this.editedItem.price_list,
        price_cost: parseFloat(this.editedItem.price_cost).toFixed(2),
        price_sell: parseFloat(this.editedItem.price_sell).toFixed(2),
        price_reduce: parseFloat(this.editedItem.price_reduce).toFixed(2),
        price_wine_glass: parseFloat(this.editedItem.price_wine_glass).toFixed(2),
        percentage_value: parseFloat(this.editedItem.percentage_value).toFixed(2),
        wine: this.editedItem.wine,
        distributor: this.editedItem.distributor,
        is_direct: this.editedItem.is_direct,
        remember_to: this.editedItem.rememberTo
      };
      listinoService.editListinoItem(itemId, edited).then(() => {
        this.getListiniItems(this.idListino);
      });
      this.dialog = false;
    },
    save() {
      this.$refs.form.validate();
      if (this.editedIndex > -1) {
        Object.assign(this.listiniItems[this.editedIndex], this.editedItem);
        this.modify();
      } else {
        this.listiniItems.push(this.editedItem);
        this.newListinoItem();
        this.dialog = false;
      }
    },
    calcolaCampoRiduzione() {
      this.editedItem.price_reduce =
          this.editedItem.price_cost -
          (this.editedItem.price_cost * this.editedItem.percentage_value) / 100;
    },
    calcolaCampoPercentuale() {
      this.editedItem.percentage_value = (
          (this.editedItem.price_cost * 10) / this.editedItem.price_reduce
      ).toFixed(2);
    },
    resetBottiglia() {
      this.minBottiglia = ""
      this.maxBottiglia = ""
    },
    resetCalice() {
      this.minCalice = ""
      this.maxCalice = ""
    },
    calcolaPrezziConsigliati() {
      this.editedItem.price_sell = Math.round(
          (parseFloat(this.editedItem.price_cost) + parseFloat(this.variablePriceList)) * 2 * this.var_a
      );
      this.editedItem.price_wine_glass = Math.round(
          (parseFloat(this.editedItem.price_cost) + parseFloat(this.variablePriceList)) / 2
      );
    },
    get_giacenza() {
      this.listiniItems = this.listiniItems.filter(listino => listino.soldout === false)
    },
  },
  watch: {
    // minBottiglia(val) {
    //   this.filter.minBottiglia = val
    //   this.getGiacenza()
    // },
    // maxBottiglia(val) {
    //   this.filter.maxBottiglia = val
    //   this.getGiacenza()
    // },
    // minCalice(val) {
    //   this.filter.minCalice = val
    //   this.getGiacenza()
    // },
    // maxCalice(val) {
    //   this.filter.maxCalice = val
    //   this.getGiacenza()
    // },
    // 'filter.type'(val) {
    //   if (val !== '') {
    //     this.getGiacenza()
    //   } else {
    //     console.log('Rimosso filtro')
    //   }
    // },
    // "filter.winemaker_name"(val) {
    //   console.log('winemaker_name', val)
    //   this.getGiacenza()
    // },
    // "filter.region"(val) {
    //   if (val === {}) {
    //     this.filter.country = {}
    //     this.getGiacenza();
    //   } else {
    //     this.getGiacenza()
    //   }
    //   console.log(val)
    // },
    // "filter.country"(val) {
    //   console.log(val)
    //   this.getGiacenza();
    // },
    idPriceListItem: {
      immediate: true,
      handler: function (value) {
        this.idListino = value
        this.showListiniItems = true
        this.get_all()
      },
    },
    "editedItem.price_cost"(val) {
      if (this.editedIndex === -1) {
        this.editedItem.price_sell = Math.round(
            (parseFloat(val) + parseFloat(this.variablePriceList)) * 2 * this.var_a
        );
        this.editedItem.price_wine_glass = Math.round(
            (parseFloat(val) + parseFloat(this.variablePriceList)) / 2
        );
      }
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    // ___ RICERCHE
    "filter.ricerca"(value) {
      if (this.giacenza) {
        if (value.length > 2) {
          this.progress = true;
          const params = {
            price_list_id: this.idListino,
            search: this.filter.ricerca
          }
          listinoService.getGiacenza(params).then(resp => {
            this.progress = false
            this.listiniItems = resp.data.results
            this.pageCount = resp.data.num_pages
            this.conteggioListiniItems = resp.data.count
            console.log(this.listiniItems)
          }).catch(error => {
            console.log(error)
            this.progress = false
          })

        }
        if (value.length === 0) {
          const page = 1;
          return this.getGiacenza(page);
        }
      } else {
        if (value.length > 2) {
          return this.searchListiniItems();
        }
        if (value.length === 0) {
          const page = 1;
          return this.searchListiniItems(page);
        }
      }
    },
    "filter.ricercavino"(value) {
      if (value) {
        if (value.length >= 3) {
          return this.getVini()
        }
      }
    },
    // giacenza(value) {
    //   if (value) {
    //     this.listiniItems = []
    //     this.filter.giacenza = 1
    //     console.log(this.filter, value)
    //     this.getGiacenza();
    //   } else {
    //     this.getListiniItems()
    //   }
    // },
    // "filter.giacenza.filterOperator" (val) {
    //   this.getGiacenza()
    // },
    // "filter.giacenza.filterValue" (val) {
    //   console.log(val)
    //   this.getGiacenza()
    // },
    // "filter.nomelistino"(value) {
    //   if (value?.length > 2) {
    //     return this.getListiniItems();
    //   }
    //   if (value?.length === 0) {
    //     return this.getListiniItems();
    //   }
    // },
    // "filter.ricercadistro"(value) {
    //   if (value?.length >= 2) {
    //     return this.getDistro();
    //   }
    //   if (value?.length === 0) {
    //     return this.getDistro();
    //   }
    // },
    "filter.tipo"(value) {
      console.log(value)
    },
    // ___ AGGIORNA/VISUALIZZA NOME VINO E DISTRO
    // "defaultItem.wine"(value) {
    //   if (value?.length !== 0) {
    //     return (this.editedItem.wine = value);
    //   }
    // },
    // "defaultItem.distributor"(value) {
    //   if (value?.length !== 0) {
    //     return (this.editedItem.distributor = value);
    //   }
    // },
    // ___ TRIGGERA STATISTICHE StatsListini.vue
    editedItem(newValue, oldValue) {
      if (newValue !== oldValue) {
        return eventBus.$emit("itemModificato", {});
      }
    },
  },
  // ___ PAGINAZIONE
  computed: {
    event() {
      return event;
    },
    formTitle() {
      return this.editedIndex === -1 ? "Aggiungi Voce Listino" : "Modifica Voce Listino";
    },
    pages() {
      return this.pagination.rowsPerPage
          ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
          : 0;
    },
    filteredRegions() {
      return this.produttori.filter((item) => item.region)
    },
    filteredCountries() {
      return this.produttori.filter((item) => item.country);
    },
    filteredWinemakers() {
      return this.produttori.filter((item) => item.winemaker_name);
    },
    orderedKeys() {
      const customOrder = ['label', 'name', 'vintage', 'type_name', 'magnum', 'gradation', 'version', 'vinification', 'winemaker_name', 'region', 'barcode', 'total_bottle'];
      const keysInOrder = customOrder.filter((key) => key in this.infoWine[0]);
      const keysNotInOrder = Object.keys(this.infoWine[0]).filter((key) => !keysInOrder.includes(key));
      return [...keysInOrder, ...keysNotInOrder];
    },
  },
};
</script>
<style>
tr.v-data-table__selected {
  background: #fcddeb !important;
}

.fill-height {
  height: 100%;
}

.dotted-underline {
  text-decoration: 2px underline;
  text-decoration-style: dotted;
  text-decoration-color: #7c7b7b;
  cursor: pointer;
}

.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}

.inset-effect-custom {
  box-shadow: inset 5px 5px 15px rgba(100, 100, 100, 0.75);
}

</style>
