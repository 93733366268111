<template>
  <v-row class="align-center d-flex h-100">
    <v-col lg="6" md="7" class="mx-auto">
      <v-card class="card-shadow border-radius-xl py-1">
        <div
            class="
            card-padding
            text-center
            mt-n7
            bg-gradient-success
            shadow-success
            border-radius-lg
            mx-4
          "
        >
          <h4 class="text-h4 text-white font-weight-bold">Gestione VinoVero</h4>
        </div>
        <div class="mt-2 position-relative text-center">
          <p
              class="
              text-sm
              font-weight-bold
              mb-2
              text-secondary text-border
              d-inline
              z-index-2
              bg-white
              px-3
            "
          >
            Inserisci Email e Password
          </p>
        </div>
        <div class="card-padding">
          <v-text-field
              label="Email"
              placeholder="Email"
              color="#e91e63"
              required
              class="font-size-input input-style"
              v-model="user.username"
          ></v-text-field>

          <v-text-field
              label="Password"
              placeholder="Password"
              color="#e91e63"
              required
              type="password"
              class="font-size-input input-style"
              v-model="user.password"
              @keyup.enter="signIn"
          ></v-text-field>
          <v-btn
              @click="signIn"
              elevation="0"
              :ripple="false"
              height="43"
              class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-default
              py-2
              px-6
              me-2
              mt-6
              mb-2
              w-100
            "
              color="#5e72e4"
              small
          >Sign In
          </v-btn
          >
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>

export default {
  name: "sign-in-basic",
  components: {},
  data() {
    return {
      checkbox: false,
      user: [
        'username',
        'password'
      ],
      alert: {
        show_alert: false,
        color_type: '',
        alert_message: ""
      }

    };
  },
  methods: {
    signIn() {
      if (this.user.username && this.user.password) {
        this.$store.dispatch("auth/login", this.user)
            .then(() => {
              this.$router.push('/')
            }).then(() => {
          this.$dialog.message.success('Login Corretto', {
            rounded: true,
            position: "top-right",
            color: "green accent-5",
            outlined: false,
            timeout: 1000,
          })
        })
            .catch(() => {
                  this.$dialog.notify.error('Username o Password Sbagliati', {
                        color: 'red darken-1',
                        rounded: true,
                        position: 'top-right',
                        outlined: false,
                        type: 'error',
                      },
                  )
                }
            )
      }
    }
  }
};
</script>
