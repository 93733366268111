<template>
  <v-dialog
      v-model="dialog"
      width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="red lighten-2"
          text
          icon
          tile
          x-small
          elevation="0"
          v-bind="attrs"
          v-on="on"
      >
        <v-icon small>mdi-delete</v-icon>
      </v-btn>
    </template>
    <v-card class="card-shadow card-padding border-radius-xl">
      <v-card-title
          class="pt-0 text-h5 text-typo justify-center"
          primary-title
      >
        Are you sure?
      </v-card-title>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            color="grey darken-1"
            text
            @click="closeDialog"
            class="font-weight-normal text-capitalize btn-ls bg-transparent btn-outline-secondary py-3 px-6"
        >
          Cancel
        </v-btn>
        <v-btn
            color="primary darken-1"
            text
            @click="deleteItem(item)"
            class="font-weight-normal text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "deleteButton",
  props: {
    item: Object,
  },
  data() {
    return {
      dialog: false,
      element: this.item
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false
    },
    deleteItem(element) {
      this.$emit('confirm-delete', element)
      this.dialog = false
    }
  }
}
</script>

<style scoped>

</style>