<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <!-- Trigger Button -->
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon elevation="0"
          :ripple="false"
          height="28"
          min-width="36"
          width="36"
          class="rounded-sm" color="#67748e"
          v-bind="attrs"
          v-on="on">
          <v-icon
              size="14"
              class="ml-10"
          >mdi-qrcode
          </v-icon>
          Code
        </v-btn>
      </template>

      <!-- Dialog Content -->
      <v-card class="bg-white">
        <v-card-title>Print QrCode OrderItem</v-card-title>
        <v-card-text>
          <div ref="printSection" class="print-section">
            <!-- QR Code -->
            <qrcode-vue :value="qrCodeValue" :size="size" level="L"></qrcode-vue>
            <!-- Additional Info -->
            <div>{{ value.price_list_item }} - {{ value.wine_name }} | {{ value.wine_vintage }}</div>
            <div>{{ value.winemaker_name }}</div>
            <div>Bottle: {{ value.price_list_bootle }}€</div>
            <div>Glass: {{ value.pricelist_wine_glass }}€</div>
          </div>
        </v-card-text>
        <v-card-actions class="align-content-end">
          <v-btn color="pink" class="text-white" @click="printContent">Print</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import QRCode from "qrcode"

export default {
  components: {
    QrcodeVue,
  },
  props: ['value'],
  data() {
    return {
      dialog: false,
      size: 100, // Dimensione del QR code
    };
  },
  computed: {
    qrCodeValue() {
      // Qui generi il valore che vuoi codificare nel QR code, per esempio l'ID
      return this.value ? this.value.price_list_item.toString() : '';
    },
  },
  methods: {
    async printContent() {
      // Genera prima l'immagine del QR code
      const qrImageUrl = await QRCode.toDataURL(this.qrCodeValue);

      // Costruisci l'HTML con l'immagine del QR code
      const contentHtml = `
      <div class="print-section" style="display: flex; align-items: center; justify-content: start;">
        <div style="flex: 0 1 auto; margin-right: 10px;"> <!-- Controlla la spaziatura qui -->
          <img src="${qrImageUrl}" alt="QR Code" style="max-width: 100%; height: auto;" />
        </div>
        <div style="flex: 1 1 auto; padding-left: 0;"> <!-- Riduci o elimina il padding-left -->
          <div><strong>${this.value.price_list_item}</div>
          <div><strong>${this.value.wine_name} | ${this.value.wine_vintage}</div>
          <div><strong>${this.value.winemaker_name}</div>
          <div><strong>${this.value.price_list_bootle}€</div>
          <div><strong>${this.value.pricelist_wine_glass}€</div>
        </div>
      </div>
    `;

      const printWindow = window.open('', '_blank');
      printWindow.document.write(`<html><head><title>Print</title><style>body { font-family: sans-serif; }</style></head><body>${contentHtml}</body></html>`);
      printWindow.document.close();
      printWindow.focus();
      printWindow.onload = () => {
        printWindow.print();
        printWindow.close();
      }
    },
  },
};
</script>

<style scoped>
.print-section {
  /* Stili per la sezione di stampa */
}
</style>
