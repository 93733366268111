<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-0">
        <v-col cols="6">
          <datatable-produttori></datatable-produttori>
        </v-col>
        <v-col cols="6">
          <datatable-vini></datatable-vini>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import DatatableProduttori from "../../Applications/Widgets/DatatableProduttori.vue";
import DatatableVini from "../../Applications/Widgets/DatatableViniProduttori.vue";

export default {
  components: {
    DatatableProduttori,
    DatatableVini
  },
};
</script>
