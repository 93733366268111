<template>
  <!-- LISTINI  -->
  <v-container v-if="visibile">
    <v-row>
      <v-col>
        <v-card
          v-if="datiStats.items_total"
          class="card-shadow border-radius-xl py-4"
        >
          <v-row no-gutters class="px-4">
            <v-col sm="12">
              <p
                class="text-sm mb-1 text-capitalize text-body font-weight-bold"
              >
                Righe d'Ordine
              </p>

              <h5 class="text-h5 text-typo font-weight-bolder mb-0">
                {{ datiStats.items_total }}
              </h5>
            </v-col>

            <v-progress-linear
              color="cyan lighten-1"
              :value="datiStats.items_total"
            >
            </v-progress-linear>
          </v-row>
        </v-card>
        <v-card
          v-else
          class="card-shadow border-radius-xl py-4"
        >
          <v-row no-gutters class="px-4">
            <v-col sm="12">
              <p class="text-sm mb-1 text-capitalize text-body font-weight-bold">
                Righe d'Ordine
              </p>
              <h5 class="text-h5 text-typo font-weight-bolder mb-0">
                0
              </h5>
            </v-col>

            <v-progress-linear
              color="cyan lighten-1">
            </v-progress-linear>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card
          v-if="datiStats.total_bottles_item"
          class="card-shadow border-radius-xl py-2"
        >
          <v-row no-gutters class="px-4">
            <v-col sm="6">
              <p class="text-sm mb-1 text-capitalize text-body font-weight-bold"
              > Bottiglie Ordinate:
              </p>
              <h5 class="text-h5 text-typo font-weight-bolder mb-0">
                {{ datiStats.total_bottles_item }}
              </h5>
              <v-progress-linear
              color="green lighten-1"
              :value="datiStats.total_bottles_item"
            >
            </v-progress-linear>
            </v-col>
            <v-col sm="6">
              <p class="text-sm mb-1 text-capitalize text-body font-weight-bold"
              > Bottiglie Stoccate:
              </p>
              <h5 class="text-h5 text-typo font-weight-bolder mb-0">
                {{ datiStats.total_bottles_item_stocked }}
              </h5>
              <v-progress-linear
              color="green lighten-1"
              :value="datiStats.total_bottles_item_stocked"
            >
            </v-progress-linear>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-else class="card-shadow border-radius-xl py-4"
        >
          <v-row no-gutters class="px-4">
            <v-col sm="6">
              <p class="text-sm mb-1 text-capitalize text-body font-weight-bold"
              >Bottiglie Ordinate:
              </p>
              <h5 class="text-h5 text-typo font-weight-bolder mb-0">0</h5>
              <v-progress-linear color="green lighten-1"></v-progress-linear>
            </v-col>

             <v-col sm="6">
              <p class="text-sm mb-1 text-capitalize text-body font-weight-bold"
              >Bottiglie Stoccate:
              </p>
              <h5 class="text-h5 text-typo font-weight-bolder mb-0">0</h5>
               <v-progress-linear color="green lighten-1"></v-progress-linear>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card
          v-if="datiStats.order_total_cost"
          class="card-shadow border-radius-xl py-4">
          <v-row no-gutters class="px-4">
            <v-col sm="12">
              <p class="text-sm mb-1 text-capitalize text-body font-weight-bold">
                Totale Listino / Costo
              </p>
              <h5 class="text-h5 text-typo font-weight-bolder mb-0">
                € {{ datiStats.order_total_cost  }} / € {{ datiStats.order_total_cost  }}
              </h5>
            </v-col>
            <v-progress-linear
              color="pink lighten-1"
              :value="datiStats.order_total_cost">
            </v-progress-linear>
          </v-row>
        </v-card>
        <v-card
          v-else
          class="card-shadow border-radius-xl py-4">
          <v-row no-gutters class="px-4">
            <v-col sm="12">
              <p class="text-sm mb-1 text-capitalize text-body font-weight-bold"
              >Totale Costo</p>
              <h5 class="text-h5 text-typo font-weight-bolder mb-0">
                0
              </h5>
            </v-col>

            <v-progress-linear
              color="pink lighten-1"
            ></v-progress-linear>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="card-shadow border-radius-xl py-2">
          <v-row no-gutters class="px-4">
            <v-col sm="6">
               <p class="text-sm mb-1 text-capitalize text-body font-weight-bold"
               >Stato Ordine:</p>
              <h5 class="text-typo font-weight-bolder mb-0">{{datiStats.status}}</h5>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { eventBus } from "@/event-bus.js";
// import ordineService from "@/services/ordine.service";

export default {
  name: "data-tables",
  components: {},
    props: {
    keyOrdine: {
      type: Number,
      required: false,
    },
    visibile: {
      type: Boolean,
      required: true,
      default: false
    },
    datiStats: {
      type: Object,
      required: true,
      default:null,
    },
  },
  data() {
    return {
      key: "",
      id: "",
    };
  },

  mounted() {
  },

  methods: {

  },
};
</script>
