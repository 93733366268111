<template>
  <div>
    <v-overlay :value="progress">
      <v-progress-circular indeterminate :size="70" :width="7" color="purple">
      </v-progress-circular>
    </v-overlay>
    <v-card class="card-shadow border-radius-xl" elevation="5">
      <div class="card-header-padding">
        <div class="font-weight-bold text-h4 text-typo mb-0">
          Elenco Distributori:
          <v-chip class="ma-2" color="green" outlined>
            Totale Distributori: {{ conteggioDistributori }}
          </v-chip>
        </div>
      </div>
      <v-card-text>
        <v-data-table
            @click:row="rowClick"
            item-key="id"
            v-model="selectedItem"
            single-select
            :headers="headers"
            :items="distributori"
            :search="search"
            fixed-header
            dense
            loading
            loading-text="Caricamento pagina in corso..."
            :page.sync="page"
            hide-default-footer
            @page-count="pageCount = $event"
            :items-per-page="itemsPerPage"
            mobile-breakpoint="0"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="12" md="5">
                  <v-text-field
                      hide-details
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      flat
                      filled
                      outlined
                      color="pink lighten-3"
                      solo
                      v-model="filter.ricerca"
                      placeholder="Ricerca Distributori"
                  >
                    <template slot="prepend-inner">
                      <v-icon color="#adb5bd" size="16" class="material-icons-round mt-n2"
                      >search
                      </v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="5">
                  <v-text-field
                      hide-details
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      flat
                      filled
                      outlined
                      color="pink lighten-3"
                      solo
                      v-model="search"
                      placeholder="Ricerca nella pagina"
                  >
                    <template slot="prepend-inner">
                      <v-icon color="#adb5bd" size="16" class="material-icons-round mt-n2"
                      >search
                      </v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-spacer></v-spacer>
              <add-distributore @callGetDistributori="getDistributori"></add-distributore>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card class="card-shadow card-padding border-radius-xl">
                  <v-card-title class="pt-0 text-h5 text-typo justify-center"
                  >Vuoi eliminare questo Distributore?
                  </v-card-title
                  >
                  <v-card-actions class="pb-0">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="closeDelete"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        class="font-weight-normal text-capitalize btn-ls bg-transparent btn-outline-secondary py-3 px-6"
                    >Cancel
                    </v-btn
                    >
                    <v-btn
                        @click="deleteItemConfirm"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        class="font-weight-normal text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
                    >OK
                    </v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div class="d-flex cursor-pointer align-center ms-2">
                          <span class="text-sm font-weight-bold text-pink">
                            {{ item.name }}
                          </span>
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex align-center">
              <add-edit-dialog-distributori
                  ref="childComponentRefDistro"
                  :item-riga="item.id"
                  @callGetDistributori="getDistributori"
              />
              <v-btn
                  @click="deleteItem(item)"
                  icon
                  elevation="0"
                  :ripple="false"
                  height="28"
                  min-width="36"
                  width="36"
                  class="btn-ls me-2 my-2 rounded-sm"
                  color="red"
              >
                <v-icon size="14" class="material-icons-round">close</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
                @input="getPageNumber"
                prev-icon="fa fa-angle-left"
                next-icon="fa fa-angle-right"
                class="pagination"
                color="#D81B60"
                v-model="page"
                :length="pageCount"
                circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import winemakerService from "@/services/winemaker.service";
import {eventBus} from "@/event-bus.js";
import AddEditDialogDistributori from "../../../components/Dialog/AddEditDialogDistributori.vue";
import AddDistributore from "@/views/Pages/Winemaker/AddDistributore.vue";

export default {
  name: "paginated-tables",
  components: {
    AddEditDialogDistributori,
    AddDistributore
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      selectedItem: [0],
      itemsPerPage: 20,
      dialogDelete: false,
      distributori: [],
      itemId: "",
      conteggioDistributori: "",
      loading: false,
      items: [],
      search: "",
      headers: [
        // {
        //   text: "ID",
        //   align: "start",
        //   cellClass: "border-bottom",
        //   sortable: false,
        //   value: "id",
        //   class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        // },
        {
          text: "Distributore",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },

        {
          text: "Indirizzo",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "address",
          class: "text-secondary font-weight-normal opacity-7 border-bottom ps-6",
        },

        {
          text: "Città",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "city",
          class: "text-secondary font-weight-normal opacity-7 border-bottom ps-6",
        },

        {
          text: "Actions",
          value: "actions",
          cellClass: "border-bottom",
          sortable: false,
          class: "text-secondary opacity-7 font-weight-normal opacity-7",
        },
      ],
      progress: false,
      openFiltri: false,
      filter: {
        ricerca: "",
      },
    };
  },
  mounted() {
    this.getPageNumber();
  },
  methods: {
    getPageNumber(val) {
      this.page = val;
      this.getDistributori();
    },
    rowClick(item, row) {
      this.$store.dispatch('settings/setDistributorId', item.id)
      winemakerService.retrieveWinemaker(item.id).then(resp => {
        let winemakers = resp.data
        this.$store.dispatch('settings/setWinemakers', winemakers)
        this.$emit('showDetailDistributore', winemakers)
        this.$emit('fornitoreID', item.id)
        this.$emit('distributoreName', item.name)
        // eventBus.$emit("distributoreId", item.id)
        // eventBus.$emit("fornitoreId", item.id)
        // eventBus.$emit("distroName", item.name)
      }).catch(e => {
        console.log(e)
      })
      row.select(true);

    },
    addButton() {
      this.$refs.childComponentRefDistro.openDialogDistro("add");
    },
    getDistributori() {
      this.progress = true;
      const params = {
        page: this.page,
        search: this.filter.ricerca,
      };
      winemakerService.getDistributori(params).then((resp) => {
        this.distributori = resp.data.results;
        this.distributori.sort();
        this.conteggioDistributori = resp.data.count;
        this.pageCount = Math.ceil(resp.data.count / this.itemsPerPage);
        this.progress = false;
      });
    },
    searchDistributori() {
      this.progress = true;
      const params = {
        page: this.page,
        search: this.filter.ricerca,
      };
      winemakerService.getDistributori(params).then((resp) => {
        this.distributori = resp.data.results;
        this.distributori.sort();
        this.conteggioDistributori = resp.data.count;
        this.pageCount = Math.ceil(resp.data.count / this.itemsPerPage);
        this.progress = false;
      });
    },
    editItem(item) {
      this.editedIndex = this.distributori.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.itemId = item.id;
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.distributori.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.itemId = item.id;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      const itemId = this.itemId;
      this.distributori.splice(this.editedIndex, 1);
      winemakerService.deleteDistributore(itemId).then(() => {
        this.$dialog.message.success("Distributore eliminato correttamente", {
          type: "success",
          rounded: true,
          position: "top-right",
          color: "green accent-5",
          outlined: false,
          timeout: 1000,
        });
        this.closeDelete();
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

    "filter.ricerca"(value) {
      if (value.length > 2) {
        return this.searchDistributori();
      }
      if (value.length === 0) {
        const page = 1;
        return this.searchDistributori(page);
      }
    },

    // TRIGGER DISTRIBUTORE ID -> DISTRIBUTORE ITEMS
    selectedItem(val) {
      eventBus.$emit("distributoreId", val[0].id);
    },
  },

  // --------------------- PAGINAZIONE
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Aggiungi Distributore" : "Modifica Distributore";
    },
    pages() {
      return this.pagination.rowsPerPage
          ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
          : 0;
    },
  },
};
</script>
<style>
tr.v-data-table__selected {
  background: #dbecff !important;
}
</style>