<template>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-0">
        <v-col cols="4">
          <datatable-search></datatable-search>
        </v-col>
        <v-col cols="8">
          <datatable-vini4></datatable-vini4>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import DatatableSearch from "../../Applications/Widgets/DatatableTipiUva.vue";
import DatatableVini4 from "../../Applications/Widgets/DatatableVini4.vue";

export default {
  name: "DataTables",
  components: {
    DatatableSearch,
    DatatableVini4
  },
};
</script>
