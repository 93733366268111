<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-0">
        <v-col cols="3">
          <datatable-search></datatable-search>
        </v-col>
        <v-col cols="9">
          <datatable-vini3></datatable-vini3>
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>
<script>
import DatatableSearch from "../../Applications/Widgets/DatatableTipiVino.vue";
import DatatableVini3 from "../../Applications/Widgets/DatatableVini3.vue";
export default {
  name: "datatables",
  components: {
    DatatableSearch,
    DatatableVini3
  },
};
</script>
