<template>
  <v-dialog
      v-model="dialog"
      max-width="800px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
          class="mr-3"
          outlined
          color="indigo"
          v-bind="attrs"
          v-on="on"
      >
        edit
      </v-icon>
    </template>
    <v-card>
      <v-card-title>
        Modifica Cassa del <span style="color: #cb0c9f; margin-left: 3px"> {{ objectCassa.data | formatDate }}</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-card
                class="mt-3"
                outlined
                style="background-color: #ebebeb">
              <v-card-title>Chiusura ore 15:00</v-card-title>
              <v-card-text>
                <v-text-field label="Cassiere" color="pink" type="text" v-model="objectCassa.responsabile_mattina">
                </v-text-field>
                <v-text-field prepend-inner-icon="€" label="Chiusura Ore 15" color="pink" type="text"
                              v-model="objectCassa.chiusura15">
                </v-text-field>
                <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="objectCassa.data"
                        label="Data"
                        outlined
                        readonly
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker locale="it" color="pink" v-model="objectCassa.data"
                                 @input="menu2 = false"></v-date-picker>
                </v-menu>

                <!--                  <v-menu-->
                <!--                      v-model="menu2"-->
                <!--                      :close-on-content-click="false"-->
                <!--                      transition="scale-transition"-->
                <!--                      offset-y-->
                <!--                      max-width="290px"-->
                <!--                      min-width="290px"-->
                <!--                  >-->
                <!--                    <template v-slot:activator="{ on, attrs }">-->
                <!--                      <v-text-field-->
                <!--                          v-model="computedDateFormatted"-->
                <!--                          label="Date"-->
                <!--                          hint="DD-MM-YYYY format"-->
                <!--                          persistent-hint-->
                <!--                          prepend-icon="event"-->
                <!--                          readonly-->
                <!--                          v-bind="attrs"-->
                <!--                          v-on="on"-->
                <!--                      ></v-text-field>-->
                <!--                    </template>-->
                <!--                    <v-date-picker color="pink" v-model="objectCassa.data" no-title @input="menu2 = false"></v-date-picker>-->
                <!--                  </v-menu>-->
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card class="pl-5 pr-5 mt-5 ml-5 mr-5" outlined style="background-color: #ebebeb">
              <v-card-title>Chiusura ore 17:00</v-card-title>
              <v-card-text>
                <v-text-field dense label="Cassiere" color="pink"
                              v-model="objectCassa.responsabile_sera"></v-text-field>
                <v-text-field prepend-inner-icon="€" dense label="Cash ore 17" color="pink"
                              v-model="objectCassa.cash17"></v-text-field>
                <v-text-field prepend-inner-icon="€" dense label="Carte ore 17" color="pink"
                              v-model="objectCassa.carte17"></v-text-field>
                <v-text-field prepend-inner-icon="€" dense label="Spese ore 17" color="pink"
                              v-model="objectCassa.spese17"></v-text-field>
                <v-text-field prepend-inner-icon="€" dense label="Chiusura ore 17" color="pink"
                              v-model="objectCassa.chiusura17"></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="" outlined style="background-color: #ebebeb">
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field prepend-inner-icon="€" dense label="Chiusura 10%" color="pink"
                                  v-model="objectCassa.chiusura10"></v-text-field>
                    <v-text-field prepend-inner-icon="€" dense label="Totale Cash" color="pink"
                                  v-model="objectCassa.cash"></v-text-field>

                  </v-col>
                  <v-col>
                    <v-text-field prepend-inner-icon="€" dense label="Chiusura 22%" color="pink"
                                  v-model="objectCassa.chiusura22"></v-text-field>
                    <v-text-field prepend-inner-icon="€" dense label="Totale Carte/Bancomat" color="pink"
                                  v-model="objectCassa.chiusurapos"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="" outlined style="background-color: #ebebeb">
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field prepend-inner-icon="€" dense label="Spese" color="pink"
                                  v-model="objectCassa.spesea"></v-text-field>
                    <v-text-field prepend-inner-icon="€" dense label="Spese in Attesa di Fatturazione" color="pink"
                                  v-model="objectCassa.speseb"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field prepend-inner-icon="€" dense label="Corrispettivi non riscossi" color="pink"
                                  v-model="objectCassa.non_riscossi"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class=""
                    outlined
                    style="background-color: #ebebeb">
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field prepend-inner-icon="%" dense label="% Cibo" color="pink"
                                  v-model="objectCassa.percentuale_cibo"></v-text-field>
                    <v-text-field prepend-inner-icon="%" dense label="% Bibite" color="pink"
                                  v-model="objectCassa.percentuale_bibite"></v-text-field>

                  </v-col>
                  <v-col>
                    <v-text-field prepend-inner-icon="%" dense label="% Vino" color="pink"
                                  v-model="objectCassa.percentuale_vino"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card
                class="mt-3"
                outlined
                style="background-color: #ebebeb">
              <v-card-title>Calcoli Incassi:</v-card-title>
              <v-text-field prepend-inner-icon="€" dense label="Incasso" color="pink"
                            v-model="objectCassa.incasso"></v-text-field>
              <v-text-field prepend-inner-icon="€" dense label="Totale A" color="pink"
                            v-model="objectCassa.totalea"></v-text-field>
              <v-text-field prepend-inner-icon="€" dense label="Totale B" color="pink"
                            v-model="objectCassa.totaleb"></v-text-field>
              <v-checkbox color="indigo" v-model="objectCassa.checked" label="Segna come Controllata"></v-checkbox>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            dark
            @click="dialog = false"
        >
          Chiudi
        </v-btn>
        <v-btn
            color="pink"
            dark
            @click="save"
        >
          Modifica
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: "EditCassa",
  props: {itemCassa: Object},
  data() {
    return {
      dialog: false,
      objectCassa: {},
      menu2: false,
    }
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.objectCassa.data)
    },
  },
  mounted() {
    this.objectCassa = Object.assign({}, this.itemCassa)
  },
  methods: {
    save() {
      this.$emit('saveCassa', this.objectCassa)
      this.dialog = false
    }
  }
}
</script>


<style scoped>
.v-input__icon--prepend .v-icon {
  color: pink;
}
</style>