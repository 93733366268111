<template>
  <v-container v-if="visibile">
    <v-row>
      <v-col>
        <v-card
          v-if="datiStats[key].total_bottles"
          class="card-shadow border-radius-xl py-4"
        >
          <v-row no-gutters class="px-4">
            <v-col sm="12">
              <p class="text-sm mb-1 text-capitalize text-body font-weight-bold">
                Totale Bottiglie
              </p>
              <h5 class="text-h5 text-typo font-weight-bolder mb-0">
                {{ datiStats[key].total_bottles }}
              </h5>
            </v-col>

            <v-progress-linear
              color="cyan lighten-1"
              :value="datiStats[key].total_bottles"
            >
            </v-progress-linear>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card v-if="totTipiBottiglia" class="card-shadow border-radius-xl py-4">
          <v-row no-gutters class="px-4">
            <v-col sm="12">
              <p class="text-sm mb-1 text-capitalize text-body font-weight-bold">
                Tipi Bottiglia
              </p>
              <h5 class="text-h5 text-typo font-weight-bolder mb-0">
                {{ totTipiBottiglia }}
              </h5>
            </v-col>

            <v-progress-linear color="orange lighten-1" :value="totTipiBottiglia">
            </v-progress-linear>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card
          v-if="datiStats[key].total_value_cost"
          class="card-shadow border-radius-xl py-4"
        >
          <v-row no-gutters class="px-4">
            <v-col sm="12">
              <p class="text-sm mb-1 text-capitalize text-body font-weight-bold">
                Totale Valore
              </p>
              <h5 class="text-h5 text-typo font-weight-bolder mb-0">
                € {{ datiStats[key].total_value_cost }}
              </h5>
            </v-col>

            <v-progress-linear color="pink lighten-1" :value="datiStats[key].total_value">
            </v-progress-linear>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import listinoService from "@/services/listino.service";
import { eventBus } from "@/event-bus.js";

export default {
  name: "data-tables",
  components: {},

  data() {
    return {
      totTipiBottiglia: 0,
      datiStats: "",
      key: "",
      id: "",
      visibile: false,
    };
  },

  mounted() {
    eventBus.$on("keyListino", (val) => {
      this.getStats();
      this.key = val;
    });
    eventBus.$on("delListino", () => {
      this.visibile = false;
    });
    eventBus.$on("itemModificato", () => {
      this.getStats();
    });
    eventBus.$on("tipiBottiglia", (val) => {
      this.getStats();
      this.totTipiBottiglia = val;
    });
  },

  methods: {
    getStats() {
      listinoService.getListini().then((resp) => {
        this.datiStats = resp.data.results;
        this.visibile = true;
      });
    },
  },
};
</script>
