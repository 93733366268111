<template>
  <v-card>
    <v-toolbar
        color="pink"
        dark
    >Associa Produttore
    </v-toolbar>
    <v-card-text>
      <v-autocomplete
          v-model="selectedWinemakers"
          :search-input.sync="filterWinemakers"
          :items="items"
          item-text="name"
          item-value="id"
          label="Produttori"
          deletable-chips
          multiple
          cache-items
          small-chips
          chips
          clearable
          flat>
      </v-autocomplete>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          color="primary"
          text
          @click="save"
      >
        Associa
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import winemakerService from "@/services/winemaker.service"

export default {
  name: "AssociaProduttore",
  props: ['wineMakers'],
  // props: ['id'],
  data() {
    return {
      dialog: false,
      items: [],
      selectedWinemakers: [],
      filterWinemakers: "",
      fornitoreID: ''
    }
  },
  mounted() {
    this.getWinemakersLight()
  },
  watch: {
    selectedWinemakers(value) {
      console.log('selectedWinemakers: ', value)
      this.items = value
    },
    filterWinemakers(value) {
      const params = {
        'search': value,
      }
      winemakerService.getProduttori(params).then(resp => {
        this.items = resp.data.results
      })

    },
    wineMakers: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.selectedWinemakers = newVal.map(item => ({
            id: item.id,
            name: item.name
          }));
        }
      }
    }
  },
  methods: {
    getWinemakersLight() {
      winemakerService.getWinemakersLight().then(resp => {
        this.items = resp.data.results
      }).catch().finally(() => {
        this.selectedWinemakers = this.wineMakers.map(item => (
            {id: item.id, name: item.name})
        )
      })
    },
    openDialog() {
      // Assegna gli ID dei produttori precedentemente selezionati a selectedWinemakers
      this.selectedWinemakers = this.wineMakers.map(item => ({
        id: item.id,
        name: item.name
      }));
      this.dialog = true;
    },
    save() {
      const params = {
        'winemaker': this.selectedWinemakers
      }
      const id = this.$store.state.settings.setting.distributorId
      winemakerService.editDistributore(id, params)
          .then(() => {
            this.$emit('refreshWinemakers')
            this.dialog = false
            this.$dialog.message.success("Distributore aggiornato correttamente", {
              type: "success",
              rounded: true,
              position: "top-right",
              color: "green accent-5",
              outlined: false,
              timeout: 1000,
            });
          })
          .catch(e => {
            console.log(e)
          }).finally(() => {
        this.$emit('refreshWinemakers')
        this.$emit('closeDialog')
      })
    }
  },
}
</script>

<style scoped>

</style>