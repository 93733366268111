<template>
  <div>
    <v-overlay :value="progress">
      <v-progress-circular
          indeterminate
          :size="70"
          :width="7"
          color="pink"
      >
      </v-progress-circular>
    </v-overlay>

    <v-card class="card-shadow border-radius-xl" elevation="5">
      <div class="card-header-padding">
        <div class="font-weight-bold text-h4 text-typo mb-0">
          Elenco Tipi
          <v-chip
              class="ma-2"
              color="green"
              outlined
          >
            Totale Tipi: {{ conteggioTipi }}
          </v-chip>
        </div>
      </div>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <!-- ******************* DIALOG *******************-->
                    <v-dialog v-model="dialog" max-width="800px">

                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            elevation="0"
                            :ripple="false"

                            height="43"
                            class="
                      font-weight-normal
                      text-capitalize
                      btn-primary
                      bg-gradient-primary
                      py-3
                      px-6
                      ms-3
                    "
                        >Aggiungi Tipo
                        </v-btn
                        >
                      </template>
                      <v-card class="card-shadow border-radius-xl">
                        <div class="card-header-padding card-border-bottom">
                    <span class="font-weight-bold text-h5 text-typo mb-0">{{
                        formTitle
                      }}</span>
                        </div>
                        <v-card-text class="card-padding">
                          <v-container class="px-0">
                            <v-row>

                              <!-- *********** NOME TIPO *********** -->
                              <v-col cols="12">
                                <!-- {{ editedItem }} -->
                                <v-text-field
                                    label="Nome Tipo"
                                    v-model="editedItem.name"
                                    hide-details
                                    class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                                    dense
                                    flat
                                    height="43"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions class="card-padding d-flex justify-end">
                          <v-btn
                              @click="close"
                              elevation="0"
                              :ripple="false"
                              height="43"
                              class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-outline-secondary
                        bg-transparent
                        py-3
                        px-6
                      "
                          >Cancella
                          </v-btn
                          >

                          <v-btn
                              @click="save"
                              elevation="0"
                              :ripple="false"
                              height="43"
                              class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-primary
                        bg-gradient-primary
                        py-3
                        px-6
                      "
                          >Salva
                          </v-btn
                          >

                        </v-card-actions>
                      </v-card>
                    </v-dialog>
            <v-card>
              <v-data-table
                  id="elencovini"
                  @click:row="rowClick"
                  item-key="id"
                  single-select
                  :headers="headers"
                  :items="tipi"
                  :search="search"
                  :show-row-border="false"
                  dense
                  loading
                  loading-text="Caricamento pagina in corso..."
                  :page.sync="page"
                  hide-default-footer
                  @page-count="pageCount = $event"
                  :items-per-page="itemsPerPage"
                  mobile-breakpoint="0"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                            hide-details
                            class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    "
                            flat
                            filled
                            outlined
                            color="pink lighten-3"
                            solo
                            height="43"
                            v-model="search"
                            placeholder="Ricerca nella pagina"
                        >
                          <template slot="prepend-inner">
                            <v-icon
                                color="#adb5bd"
                                size="16"
                                class="material-icons-round mt-n2"
                            >search
                            </v-icon>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card class="card-shadow card-padding border-radius-xl">
                        <v-card-title class="pt-0 text-h5 text-typo justify-center"
                        >Vuoi eliminare questo Tipo?
                        </v-card-title
                        >
                        <v-card-actions class="pb-0">
                          <v-spacer></v-spacer>
                          <v-btn
                              @click="closeDelete"
                              elevation="0"
                              :ripple="false"
                              height="43"
                              class="
                        font-weight-normal
                        text-capitalize
                        btn-ls
                        bg-transparent
                        btn-outline-secondary
                        py-3
                        px-6
                      "
                          >Cancel
                          </v-btn
                          >

                          <v-btn
                              @click="deleteItemConfirm"
                              elevation="0"
                              :ripple="false"
                              height="43"
                              class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-primary
                        bg-gradient-primary
                        py-3
                        px-6
                      "
                          >OK
                          </v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <!-- <div class="d-flex align-center ms-2"> -->
                  <div class="d-flex align-center ms-2">
              <span class="text-sm font-weight-bolder text-body">
                {{ item.name }}
              </span>
                  </div>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn
                      @click="editItem(item)"
                      icon
                      elevation="0"
                      :ripple="false"
                      height="28"
                      min-width="36"
                      width="36"
                      class="btn-ls me-2 my-2 rounded-sm"
                      color="#67748e"
                  >
                    <v-icon size="14" class="material-icons-round"
                    >edit
                    </v-icon
                    >
                  </v-btn>
                  <v-btn
                      @click="deleteItem(item)"
                      icon
                      elevation="0"
                      :ripple="false"
                      height="28"
                      min-width="36"
                      width="36"
                      class="btn-ls me-2 my-2 rounded-sm"
                      color="red"
                  >
                    <v-icon size="14" class="material-icons-round">close</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>

      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
                @input="loadPagina()"
                prev-icon="fa fa-angle-left"
                next-icon="fa fa-angle-right"
                class="pagination"
                color="#D81B60"
                v-model="page"
                :length="pageCount"
                circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import typeAndtypegrapesService from "@/services/typeAndtypegrapes.service";
import {eventBus} from '@/event-bus.js';


export default {
  name: "paginated-tables",
  data() {
    return {
      page: 1,
      pageCount: 0,

      itemsPerPage: 20,
      dialog: false,
      dialogDelete: false,
      // -------------------

      tipi: [],
      itemId: "",
      conteggioTipi: "",
      loading: false,
      items: [],
      // -------------------
      search: "",
      editedIndex: -1,
      editedItem: {
        name: "",

      },
      defaultItem: {
        name: "",

      },
      headers: [
        {
          text: "Nome",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "name",
          class:
              "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },

        {
          text: "Actions",
          value: "actions",
          align: "end",
          cellClass: "border-bottom",
          sortable: false,
          class:
              "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
      ],

      progress: false,

    };
  },

  mounted() {
    this.getTipi()
    this.loadPagina()

  },

  methods: {

    rowClick: function (item, row) {
      row.select(true);
      eventBus.$emit('tipoId', item.id)
      eventBus.$emit('tipoName', item.name)
    },


    loadPagina() {
      this.page + 1
      this.getTipi()

    },


    getTipi() {
      const page = this.page
      typeAndtypegrapesService.getTipiPagina(page)
          .then((resp) => {
            this.tipi = resp.data.results
            this.tipi.sort()
            this.conteggioTipi = resp.data.count
            this.pageCount = Math.ceil(resp.data.count / this.itemsPerPage)
          })
    },


    newTipo() {
      const payload = {
        name: this.editedItem.name,
      }
      typeAndtypegrapesService.newTipo(payload).then(() => {
        this.$dialog.notify.success('Tipo aggiunto con successo', {color: 'green'})
      }).catch(err => {
        console.log(err)
      }).finally(() => {
            this.getTipi()
          }
      )
    },


    editItem(item) {
      this.editedIndex = this.tipi.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // ------------------
      this.itemId = item.id
      // ------------------
      this.dialog = true
    },
    deleteItem(item) {
      this.editedIndex = this.tipi.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // ------------------
      this.itemId = item.id
      // -------------------
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      const itemId = this.itemId
      this.tipi.splice(this.editedIndex, 1);
      typeAndtypegrapesService.deleteTipo(itemId)
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    modify() {
      const itemId = this.itemId
      const edited = {
        name: this.editedItem.name,
      }
      typeAndtypegrapesService.editTipo(itemId, edited).then(() => {
            this.$dialog.message.success("Tipo modificato correttamente", {
              type: "success",
              rounded: true,
              position: "top-right",
              color: "green accent-5",
              outlined: false,
              timeout: 1000,
            });
            this.dialog = false;
          }
      ).catch(err => {
        this.$dialog.notify.error('Errore nella modifica del tipo', {color: 'red'})
        console.log(err)
      }).finally(() => {
            this.getTipi()
          }
      )

    },


    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.tipi[this.editedIndex], this.editedItem);
        this.modify()
        this.getTipi()
      } else {
        this.tipi.push(this.editedItem);
        this.newTipo()
        this.loadPagina()
        this.dialog = false;
      }
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

  },
// --------------------- PAGINAZIONE
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Aggiungi Tipo" : "Modifica Tipo";
    },
    pages() {
      return this.pagination.rowsPerPage
          ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
          : 0;
    },
  },
};
</script>

<style scoped>
.elencovini .v-table tbody tr:not(:last-child) {
  border-bottom: none;
}
</style>
