<template>
  <div>
    <v-overlay :value="progress">
      <v-progress-circular indeterminate :size="70" :width="7" color="purple">
      </v-progress-circular>
    </v-overlay>
    <v-card class="card-shadow border-radius-xl" elevation="5">
      <div class="card-header-padding">
        <div class="font-weight-bold text-h4 text-typo mb-0">
          Elenco Casse
          <v-chip class="ma-2" color="green" outlined>
            Totale Casse: {{ casse_totali }}
          </v-chip>
        </div>
      </div>
      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="casse"
            item-key="id"
            single-expand
            :expanded.sync="expanded"
            show-expand
            @item-expanded="onItemExpanded"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="12" class="align-end">
                  <add-cassa @addCassa="aggiungiCassa"></add-cassa>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="bg-gray pr-0 pl-1">
              <v-card>
                <v-overlay v-if="loading" :value="loading" absolute>
                  <v-progress-circular :size="70" :width="7"  indeterminate color="purple">
                  </v-progress-circular>
              </v-overlay>
                <v-card-title>
                  Il giorno: <b class="ml-2 mr-2 text-pink"> {{ item.data }}</b> ci sono stati un totale di <b
                    class="text-pink ml-2 mr-2">{{ bistrotCount }}</b> Bistrot.
                  <div class="ml-5" v-for="(conteggio, name) in conteggi" :key="name">
                    <span>{{ name }}:</span><span class="pink--text"> {{ conteggio }}</span>
                  </div>
                  <v-spacer></v-spacer>
                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                      color="pink"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                    dense
                    class="striped-table blue-grey--text darken-4 font-weight-bold rounded-0"
                    :search="search"
                    :items="bistrots"
                    :headers="headersBistrots">
                  <template v-slot:[`item.wine_name`]="{ item }">
                    <v-btn :class="item.warehouse_item_id ? 'text-pink':'font-weight-bold text-purple'" text
                           @click="editBistrot(item)">{{ item.wine_name }}
                    </v-btn>
                  </template>
                  <template v-slot:[`item.movement_date`]="{ item }">
                    {{ item.movement_date | formatDate }}
                  </template>
                </v-data-table>
              </v-card>
            </td>
          </template>
          <template v-slot:[`item.data`]="{ item }">
            <v-btn text><span>{{ item.data | formatDate }}</span></v-btn>
          </template>
          <template v-slot:[`item.totalea`]="{ item }">
            <v-chip color="gray" class="font-weight-bold text-purple" outlined small>{{ item.totalea }}€</v-chip>
          </template>
          <template v-slot:[`item.totaleb`]="{ item }">
            <v-chip color="gray" class="font-weight-bold text-purple" outlined small>{{ item.totaleb }}€</v-chip>
          </template>
          <template v-slot:[`item.incasso`]="{ item }">
            <v-chip color="gray" class="font-weight-bold text-indigo" outlined small>{{ item.incasso }}€</v-chip>
          </template>
          <template v-slot:[`item.total_price_cost`]="{ item }">
            <v-chip color="gray" class="font-weight-bold" outlined small>{{ item.total_price_cost }}€</v-chip>
          </template>
          <template v-slot:[`item.removed_quantity`]="{ item }">
            <v-chip color="gray" class="font-weight-bold text-pink" outlined small>{{ item.removed_quantity }}</v-chip>
          </template>
          <template v-slot:[`item.checked`]="{ item }">
            <div v-if="item.checked ===  false">
              <i style="color: red" class="fa fa-lock"></i>
            </div>
            <div v-else>
              <i style="color: green" class="fa fa-lock-open"></i>
            </div>
          </template>
          <template v-slot:[`item.email_sended`]="{ item }">
            <div v-if="item.email_sended ===  false">
              <i style="color: red" class="fas fa-envelope"></i>
            </div>
            <div v-else>
              <i style="color: green" class="far fa-envelope"></i>
            </div>
          </template>
          <template v-slot:[`item.actions`]="{item}">
            <edit-cassa :itemCassa="item" @saveCassa="saveEditCassa"></edit-cassa>
            <delete-button :item="item" @confirm-delete="deleteItemConfirm"></delete-button>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog
        max-width="600"
        title="modifica Bistrot"
        id="editBistrot"
        v-model="showEditBistrot">
      <edit-bistrot :bistrot="bistrot_item" @closeDialog="closeEdit"></edit-bistrot>
    </v-dialog>
  </div>
</template>

<script>
import CasseService from "@/services/casse.service";
import AddCassa from "@/views/Pages/Casse/AddCassa.vue";
import EditCassa from "@/views/Pages/Casse/EditCassa.vue";
import deleteButton from "@/views/Components/button/deleteButton.vue";
import warehouseService from "@/services/warehouse.service";
import EditBistrot from "@/views/Pages/Casse/EditBistrot.vue";
import {background} from "quill/ui/icons";

export default {
  name: "CassaView",
  computed: {
    background() {
      return background
    }
  },
  components: {
    EditCassa,
    EditBistrot,
    AddCassa,
    deleteButton
  },
  data() {
    return {
      progress: false,
      casse: [],
      headers: [
        // {value: 'store_name', text: 'STORE'},
        {value: 'data', text: 'DATA',},
        {value: 'totalea', text: 'TOT. A'},
        {value: 'totaleb', text: 'TOT. B'},
        {value: 'incasso', text: 'INCASSO'},
        {value: 'percentuale_bibite', text: '%Bibite'},
        {value: 'percentuale_cibo', text: '%Cibo'},
        {value: 'percentuale_vino', text: '%Vino'},
        {value: 'total_price_cost', text: 'Costo Totale'},
        {value: 'removed_quantity', text: 'Tot. Scaricate'},
        {value: 'bottle_sell', text: 'Bottiglie Vendute'},
        {value: 'cork_bottle', text: 'Tappo'},
        {value: 'broken_bottle', text: 'Rotte'},
        {value: 'checked', text: 'CHECKED'},
        {value: 'email_sended', text: 'Email'},
        {value: 'actions', text: 'ACTIONS'},
      ],
      headersBistrots: [
        {value: "warehouse_item_id", text: "ID Mag. Item"},
        {value: "wine_name", text: "Wine"},
        {value: "winemaker_name", text: "WM"},
        {value: "removed_quantity", text: "Q.ty"},
        {value: "rack_name", text: "Rack"},
        {value: "cell_name", text: "Cell"},
        {value: "movement_date", text: "Data"},
        {value: "price_list_item", text: "PLI"},
        {value: "download_name", text: "Tipo"},
      ],
      casse_totali: 0,
      cassaId: null,
      dialogDelete: false,
      expanded: [],
      bistrots: [],
      bistrotCount: 0,
      loading: false,
      search: "",
      conteggi: {},
      bistrot_item: {
        "warehouse_item_id": null,
        "wineId": null,
        "removed_quantity": null,
        "old_quantity": null,
        "rack_name": "",
        "cell_name": "",
        "movement_date": null,
        "store": null,
        "price_list_item": null,
        "download_type": null,
        "cell": null
      },
      showEditBistrot: false
    }
  },
  filters: {
    formatDate(value) {
      if (!value) return '';
      const date = new Date(value);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() restituisce mesi da 0 (gennaio) a 11 (dicembre)
      const year = date.getFullYear().toString().substring(2);
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');

      // Formatta la data nel nuovo formato
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    }
  },
  mounted() {
    this.initCasse()
  },
  methods: {
    aggiungiCassa(item) {
      CasseService.postCassa(item)
          .then(() => {
            this.$dialog.message.success("Cassa Aggiunta Correttamente", {
              rounded: true,
              position: "top-right",
              color: "green",
              outlined: false,
              timeout: 1000,
            })
          })
          .catch(error => {
            this.$dialog.notify.error(`Errore! ${error.data}`, {
              rounded: true,
              position: "top-right",
              color: "red accent-3",
              outlined: false,
              timeout: 1000,
            })
          })
          .finally(() => {
            this.initCasse()
          })
    },
    saveEditCassa(item) {
      const params = {
        store: this.$store.state.auth.user.store_id
      }
      CasseService.putCasse(item, params).then(() => {
        this.$dialog.message.success("Cassa Modificata Correttamente", {
          rounded: true,
          position: "top-right",
          color: "green",
          outlined: false,
          timeout: 1000,
        })
      }).catch(error => {
        this.$dialog.notify.error(`Errore! ${error.data}`, {
          rounded: true,
          position: "top-right",
          color: "red accent-3",
          outlined: false,
          timeout: 1000,
        })
      }).finally(() => {
            this.initCasse()
          }
      )
    },
    initCasse() {
      this.progress = true
      const params = {
        store: this.$store.state.auth.user.store_id
      }
      CasseService.getCasse(params).then(
          resp => {
            this.casse = resp.data.results
            this.casse_totali = resp.data.count
            this.progress = false
          }
      ).catch(() => {
        this.progress = false
      })
    },
    deleteItemConfirm(value) {
      const params = {
        store: this.$store.state.auth.user.store_id
      }
      CasseService.delete(value.id, params).then(() => {
        this.$dialog.notify.success('cassa eliminata', {
          type: 'success',
          rounded: true,
          position: "top-right",
          color: "green accent-5",
          outlined: false,
          timeout: 1000,
        })
      }).catch(() => {
        this.$dialog.notify.error('cassa non eliminata', {
          type: 'error',
          rounded: true,
          position: "top-right",
          color: "red accent-3",
          outlined: false,
          timeout: 1000,
        })
      }).finally(() => {
        this.initCasse()
      })
      this.dialogDelete = false
    },
    closeEdit() {
      this.showEditBistrot = false
      this.getItemsCassa(this.cassaId)
    },
    editBistrot(item) {
      this.bistrot_item = item
      this.showEditBistrot = true
      console.log('bistrot: ', item)
    },
    closeDelete() {
      this.dialogDelete = false
    },
    getItemsCassa(cassa) {
      if (cassa !== undefined) {
        const params = {
          store: this.$store.state.auth.user.store_id,
          data: cassa.data
        }
        console.log('PARAMS: ', params)
        warehouseService.getBistrots(params).then(resp => {
          this.bistrots = resp.data.results
          this.bistrotCount = resp.data.count
          this.returnDownloadTypeSet()
        }).catch().finally(() => this.loading = false)
      } else {
        console.log('no cassa')
      }
    },
    onItemExpanded(item) {
      if (item.value !== undefined) {
        this.loading = true
        this.cassaId = item.item
        this.getItemsCassa(item.item)
      } else {
        this.bistrots = []
      }
    },
    returnDownloadTypeSet() {
      let downloadNameCounts = {};
      this.bistrots.forEach(item => {
        let name = item.download_name;
        if (downloadNameCounts[name]) {
          downloadNameCounts[name]++;
        } else {
          downloadNameCounts[name] = 1;
        }
      });
      this.conteggi = downloadNameCounts
    }
  },
}
</script>

<style>
.striped-table tbody tr:nth-child(odd) {
  background-color: #e0e0e0; /* o qualsiasi altro colore */
}
</style>