<template>
  <v-dialog
      v-model="dialog"
      width="500"
      scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
          class="mr-3"
          outlined
          color="indigo"
          v-bind="attrs"
          v-on="on"
      >
        mdi-qrcode
      </v-icon>
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
          Print QrCode Listino Vino
      </v-card-title>

      <v-card-text class="mt-4">
        <div>Rack: {{ value.rack }}</div>
        <div id="printSection">
          <div class="container">
            <div class="main">
              <qr-code error-level="L" :size="size" :text="value.id_to_scan.toString()"></qr-code>
            </div>
            <div class="right-column">
              <b>
                <div class="box">
                  <small class="text-black">
                    {{ value.wine_name }}|{{ value.wine_vintage}}
                  </small><br>
                  <small class="text-black">
                    {{ value.winemaker }}
                  </small><br>
                  <small class="text-black text-bold">
                    id: {{ value.id_to_scan}}
                  </small>
                </div>
              </b>
              <div class="box">
                <b>
                  <small class="text-black">
                    Bottle: {{ value.wine_price_bottle }}€
                  </small>
                  <small class="text-black">
                    Glass: {{ value.wine_price_glass }}€
                  </small>
                </b>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            text
            @click=printQrCode
        >
          Print
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {toString} from "vue-qr-print"
import warehouseService from "@/services/warehouse.service";

export default {
  name: "PrintBarcodeWineCellRack",
  components: {},
  props: ['value', 'warehouse', 'rackName'],
  data() {
    return {
      dialog: false,
      size: 90,
      wineId: this.value.id,
      isStartPrint: false,
      rack: {},
      type: null,
      charactersPerLine: 10,
      options: {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=no',
          'scrollbars=yes'
        ],
        iframe: false,
        styles: [
          './htmlToPrint.css',
        ]
      }
    }
  },
  mounted() {
  },
  computed: {
    getValueId() {
      return toString(this.value)
    }
  },
  watch: {},
  methods: {
    getInfoRack() {
      if (typeof (this.value) === 'object') {
        warehouseService.getRack(this.value.id)
            .then(resp => {
              this.rack = resp.data
            })
      } else {
        warehouseService.getRack(this.value)

            .then(resp => {
              this.rack = resp.data
            })
      }
    },
    printQrCode() {
      // language=HTML
      this.$htmlToPaper(`printSection`, this.options)
      this.dialog = false
    },
    onEndPrint() {
      this.isStartPrint = false;
    },
  }
}
</script>

<style scoped>
.container {
  display: flex; /* Utilizziamo flexbox per posizionare i div */
}

.main {
  flex: 1; /* Il div principale occupa tutto lo spazio rimanente */
  padding: 10px;
}

.right-column {
  flex-basis: 80%; /* Il div di destra occupa il 30% dello spazio */
  display: flex;
  flex-direction: column; /* Posiziona i div figli in colonna */
}

.box {
  padding: 10px;
}

@media print {
  body {
    margin: 0;
    font-family: Arial, sans-serif;
    color: green;
  }
}
</style>