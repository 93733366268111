var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showCard)?_c('div',[_c('v-overlay',{attrs:{"value":_vm.progress}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":70,"width":7,"color":"purple"}})],1),_c('v-card',{staticClass:"card-shadow border-radius-xl",attrs:{"elevation":"5"}},[_c('div',{staticClass:"card-header-padding"},[_c('div',{staticClass:"font-weight-normal text-h4 text-typo mb-0"},[_vm._v(" Produttori Associati a "),_c('span',{staticClass:"font-weight-bold text-pink"},[_vm._v(_vm._s(_vm.distributoreNome))]),_vm._v(": "),_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","outlined":""}},[_vm._v(" Totale Produttori: "+_vm._s(_vm.grebWinemakers.length)+" ")])],1)]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.winemakers},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"\n                    font-weight-normal\n                    text-capitalize\n                    btn-primary\n                    bg-gradient-primary\n                    py-3\n                    px-6\n                    ms-3\n                  ",attrs:{"elevation":"0","ripple":false,"height":"43"},on:{"click":_vm.openDialog}},'v-btn',attrs,false),on),[_vm._v("Aggiungi Produttore ")])]}}],null,false,2271936975),model:{value:(_vm.addWinemaker),callback:function ($$v) {_vm.addWinemaker=$$v},expression:"addWinemaker"}},[_c('associa-produttore',{attrs:{"wineMakers":_vm.grebWinemakers},on:{"closeDialog":function($event){_vm.addWinemaker = false},"refreshWinemakers":_vm.rWineMakers},model:{value:(_vm.addWinemaker),callback:function ($$v) {_vm.addWinemaker=$$v},expression:"addWinemaker"}})],1)],1)]},proxy:true},{key:"item.how_many_wines",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","text-color":"white","color":"purple","link":""},on:{"click":function($event){return _vm.retrieveWine(item)}}},[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(item.how_many_wines))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"btn-ls me-2 my-2 rounded-sm",attrs:{"icon":"","elevation":"0","ripple":false,"height":"28","min-width":"36","width":"36","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{staticClass:"material-icons-round",attrs:{"size":"14"}},[_vm._v("close")])],1)]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.showWines),callback:function ($$v) {_vm.showWines=$$v},expression:"showWines"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 pink text-white lighten-2"},[_vm._v(" Vini del Produttore: "+_vm._s(_vm.winemaker)+" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"text-white",attrs:{"icon":""},on:{"click":function($event){_vm.showWines = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","items":_vm.wines,"headers":_vm.wineHeaders},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"dotted-underline",on:{"click":function($event){return _vm.copyToClipboard(item.name)}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"btn-ls me-2 my-2 rounded-sm",attrs:{"icon":"","elevation":"0","ripple":false,"height":"28","min-width":"36","width":"36","color":"#67748e"},on:{"click":function($event){return _vm.sendToPriceList(item)}}},[_c('v-icon',{staticClass:"material-icons-round",attrs:{"size":"14"}},[_vm._v("currency_exchange")])],1)]}}],null,true)})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }